import React from 'react';
import {ReactComponent as SvgHome} from '../common/svg/Home.svg';
import {ReactComponent as SvgBell} from '../common/svg/bell.svg';
import {ReactComponent as SvgClock} from '../common/svg/clock.svg';
import {ReactComponent as SvgLock} from '../common/svg/lock.svg';
import {ReactComponent as SvgPaper} from '../common/svg/paper-write.svg';
import {ReactComponent as SvgDB} from '../common/svg/database-2.svg';
import {ReactComponent as SvgAuto} from '../common/svg/navigation-menu-2.svg';
import {ReactComponent as SvgPicture} from '../common/svg/picture-stack-human.svg';
import {ReactComponent as SvgGraph} from '../common/svg/bar-chart.1.svg';
import {ReactComponent as SvgInfo} from '../common/svg/information-circle.svg';
import {ReactComponent as SvgSearch} from '../common/svg/search.svg';
import {ReactComponent as SvgAlerts} from '../common/svg/bell.svg';
import {ReactComponent as SvgSettings} from '../common/svg/cog.svg';
import {ReactComponent as SvgPlus} from '../common/svg/plus-sign.svg';

const buttons = [
  {
    name: 'Home',
    svg: <SvgHome />,
    link: '/home',
    eventKey: 'home',
    type: 'side'
  },
  {
    name: 'Cloud Management',
    svg: <SvgDB />,
    link: '/resources',
    eventKey: 'resources',
    type: 'side'
  },
    {
    name: 'Recommendations',
    svg: <SvgBell />,
    link: '/recommendations',
    eventKey: 'recommendations',
    type: 'side',
    depth: 1
  },
    {
    name: 'Automation Logs',
    svg: <SvgPaper />,
    link: '/logs',
    eventKey: 'logs',
    type: 'side',
    depth: 1
  },
    {
    name: 'Pending Automation',
    svg: <SvgClock />,
    link: '/pending',
    eventKey: 'pending',
    type: 'side',
    depth: 1
  },
  {
    name: 'Security',
    svg: <SvgLock />,
    link: '/security',
    eventKey: 'security',
    type: 'side'
  },
  {
    name: 'Automation Pipelines',
    svg: <SvgAuto />,
    link: '/automation',
    eventKey: 'automation',
    type: 'side'
  },
   {
    name: 'Cost Analysis & Budget',
    svg: <SvgGraph />,
    link: '/analysis',
    eventKey: 'analysis',
    type: 'side'
  },
   {
    name: 'divider1',
    divider: true,
    type: 'side'
  },
    {
    name: 'Manage Team',
    svg: <SvgPicture />,
    link: '/team',
    eventKey: 'team',
    type: 'side'
  },
    {
    name: "What's New",
    svg: <SvgPlus />,
    link: 'https://www.stackboss.com/updates',
    eventKey: 'whatsnew',
    type: 'side',
    disabled: false,
    external: true
  },
   {
    name: 'Help',
    svg: <SvgInfo />,
    link: 'https://www.stackboss.com/docs',
    eventKey: 'help',
    type: 'side',
    disabled: false,
    external: true
  },
  {
    name: 'Search',
    svg: <SvgSearch />,
    eventKey: 'search',
    type: 'top',
    disabled: false,
  },
  {
    name: 'Alerts',
    svg: <SvgAlerts />,
    link: '/alerts',
    eventKey: 'alerts',
    type: 'top',
    disabled: true
  },
    {
    name: 'Settings',
    svg: <SvgSettings />,
    link: '/settings',
    eventKey: 'settings',
    type: 'top',
    disabled: true
  },
      {
    name: 'Profile',
    svg: <SvgSettings />,
    link: '/profile',
    eventKey: 'profile',
    type: 'none'
  },
]

export default buttons;