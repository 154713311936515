import React from 'react';
import { ReactComponent as SvgLoader } from '../common/svg/stackboss-loader.svg';

const Loading = function() {
  return (
      <div className="App-header">
        <h1><span className="ic-Spin-cycle--classic" style={{paddingTop: '50px'}}><SvgLoader/></span><span><b>Loading</b></span></h1>
      </div>
  )
  }

  export default Loading;