import React from 'react';
import { Accordion, Alert } from 'react-bootstrap';
import StdButton from '../common/stdButton';

const HowToGetIds = function() {

  const [toggle, setToggle] = React.useState(true)

  return (
    <div style={{width:'100%', maxWidth: '100%'}}>
<Accordion style={{margin: '0 1em 1em 1em'}} >

      <Accordion.Toggle  as={Alert} className="green-alert" variant="info" eventKey="howto" onClick={() => setToggle(!toggle)}>
        <div className="flex-inline" >
          <div className="justify-left" style={{width: '85%'}}>
            <b>Enter the tenant and application IDs and service principal secret below to add your tenants. Click here for more info on creating and locating the IDs and secrets for the form below.</b>
          </div>
          <div style={{fontSize: '1.5rem', margin: 'auto .5em auto auto'}}>
            <b><Toggle className={toggle ? 'toggle-down' : 'toggle-up'}/></b>
          </div>
        </div>

      </Accordion.Toggle>

      <Accordion.Collapse  eventKey="howto" >

          <div style={{ padding: '1em', textAlign: 'left', backgroundColor: 'lightgrey', color: 'black', fontSize: '1rem'}}>
          <p>
            A service principal in your Azure tenant provides StackBoss with the access needed to make intelligent decisions and cloud automation. You can use our PowerShell script to create this service principal, or follow Microsoft’s guide for creation. In either case, you’ll need to provide:
          </p>
          <ol>
            <li>Your Azure tenant ID</li>
            <li>The service principal application ID</li>
            <li>The service principal secret</li>
          </ol>
          <h3>Option A: PowerShell Script (recommended)</h3>
          <p>
            Run this PowerShell script on your machine. It requires the Az module. Use Install-Module Az if it is not already installed. The 3 values needed are displayed at the end. We recommend copying/pasting them into the corresponding fields in StackBoss.
          </p>
            <ul>
              <li>The script is available <a href='https://github.com/stackboss-inc/addSBServicePrincipal' rel="noopener noreferrer" target='_blank'>here</a>.</li>
            </ul>

            <h3>Option B: Manual Setup (not recommended)</h3>
            <p>If setting the service principal up manually, you’ll need to:</p>
            <ol>
              <li><a href='https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#register-an-application-with-azure-ad-and-create-a-service-principal' rel="noopener noreferrer" target='_blank'>Register an application with Azure AD and create a service principal</a></li>
              <li><a href='https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#register-an-application-with-azure-ad-and-create-a-service-principal' rel="noopener noreferrer" target='_blank'>Assign a role to the application</a></li>
              <li><a href='https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#register-an-application-with-azure-ad-and-create-a-service-principal' rel="noopener noreferrer" target='_blank'>Get tenant and app ID values for signing in</a></li>
              <li><a href='https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#register-an-application-with-azure-ad-and-create-a-service-principal' rel="noopener noreferrer" target='_blank'>Create a new application secret</a></li>
            </ol>
            <p>From this process, you’ll need to provide:</p>
            <ol>
              <li>Your Azure tenant ID</li>
              <li>The service principal application ID</li>
              <li>The service principal secret</li>
            </ol>
            <p>… and copy/paste them into the corresponding fields in StackBoss.</p>
          </div>
      </Accordion.Collapse>

    </Accordion>
    </div>

  )
}

const Toggle = ({className}) => {
  return (
              <svg className={className} height={20} width={20} viewBox="0 0 24 24">
                <g id="resourceArrow" stroke="#26C1DB" strokeWidth="2" fill="#26C1DB" fillRule="evenodd" >
                  <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/>
                </g>
              </svg>
  );
};

export default HowToGetIds