import React, { useState, useEffect } from 'react';
import { Container, Table, Alert, Spinner, Row, Col } from 'react-bootstrap';
import {useFetch, applyRecNow, applyRecLater} from '../common/utils';
import useMediaQuery from '../common/useMediaQuery';
import Loading from '../common/loadingLogo';
import StdButton from '../common/stdButton';
import {useLogout} from '../../auth/jwtLogout';

export default function Recommendations(props) {
  const { query, view} = props
  let searchParams = '';
  if (query) {
    if (query[0] === '?') {
      searchParams = query;
    } else {
      searchParams = '?resourceId=' + query;
    }
  }
  const { response, error, isLoading } = useFetch('api/v1/log/recommendation' + searchParams);

  const maxWidth991 = useMediaQuery("(max-width: 991px)"); //for modal view
  const maxWidth865 = useMediaQuery("(max-width: 865px)"); //for normal view


  const styles = {
    container: {},
    table: {},
  }

  if (view === 'modal') {
    styles.container.padding = '3px';
    styles.table.marginLeft = '4px';
    styles.table.marginRight = '4px'
  }

  React.useEffect(() => {
    if (view !== 'modal') window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (isLoading) return (
    <Loading />
  )

  if (error) return (
    <Alert variant='danger'>Error: {error.statusText} </Alert>
  )

  if((view !== 'modal' && maxWidth865) || (view === 'modal' && maxWidth991) ) return (
        <div className="your-profile-bg">
      <Container fluid >
          <Row>
            <Col>
              <div className='ruler-div' />
            {!!response.Data.length && response.Data.map(rec => (
              <RecommendationsRow rec={rec} mobile key={rec.Id} />
            ))}
            </Col>
          </Row>
      </Container>
    </div>
  )

  return (
    <Container className="fade-in fontsize-p85 page-main-container" fluid  style={styles.container}>
      <div className='your-profile-bg' style={{color: 'white', ...styles.table}}>
        <Table responsive='sm' variant='dark' striped hover>
          <thead style={{textAlign: 'left'}}>
            <tr>
              <th>Resource</th>
              <th>When</th>
              <th>Prediction Method</th>
              <th>Recommendation</th>
              <th style={{textAlign: 'right'}}>Annual Cost Savings</th>
              <th style={{textAlign: 'center'}}>Apply Recommendation</th>
            </tr>
          </thead>
          <tbody>
            {!response.Data.length && (
              <tr>
                <td colSpan='6' >
                  <h4 >No recommendations right now, check back later.</h4>
                </td>
              </tr>
            )}

            {!!response.Data.length && response.Data.map(rec => (
              <RecommendationsRow rec={rec} key={rec.Id} />
            ))}
          </tbody>
        </Table>
      </div>
    </Container>

  )
}

const RecommendationsRow = function({ rec, mobile }) {

  const [isLoadingNow, setIsLoadingNow] = useState(false);
  const [isAppliedNow, setIsAppliedNow] = useState(false);
  const [nowButtonText, setNowButtonText] = useState('APPLY NOW')

  const [isLoadingLater, setIsLoadingLater] = useState(false);
  const [isAppliedLater, setIsAppliedLater] = useState(false);
  const [laterButtonText, setLaterButtonText] = useState(<>APPLY AT<br/>MAINTENANCE WINDOW</>)
  const [alertMsg, setAlertMsg] = useState('')

  const logout = useLogout();

  const disableButtons = isLoadingNow || isLoadingLater || isAppliedNow || isAppliedLater;

  useEffect(() => {
    if (isLoadingNow) {
      setNowButtonText(<div className='half-size'><Spinner animation='border' variant='light'/></div>)
    } else if (isAppliedNow)  {
      setNowButtonText('APPLIED')
    } else {
      setNowButtonText('APPLY NOW')
    }

  },[isLoadingNow, isAppliedNow])

  useEffect(() => {
    if (isLoadingLater) {
      setLaterButtonText(<Spinner animation='border' variant='light'/>)
    } else if (isAppliedLater) {
      setLaterButtonText(<>APPLIED AT<br/>MAINTENANCE WINDOW</>)
    } else {
      setLaterButtonText(<>APPLY AT<br/>MAINTENANCE WINDOW</>)
    }
  }, [isLoadingLater, isAppliedLater])

  const applyNow = (rec) => {
    setIsLoadingNow(true)
    setAlertMsg('')
    applyRecNow(rec.Id)
    .then(() => {
      setIsAppliedNow(true)
    })
    .catch(err => {
      if(err.status === 401) {
        logout();
      }
      setAlertMsg(err.statusText)
      console.log('applyNow error', err, err.status, err.statusText);
    })
    .finally(() => {
      setIsLoadingNow(false)

    })
  }

  const applyLater = () => {
    setIsLoadingLater(true)
    setAlertMsg('')
    applyRecLater(rec.Id)
    .then(() => {
      setIsAppliedLater(true)
    })
    .catch(err => {
      if(err.status === 401) {
        logout();
      }
      console.log(err)
      setAlertMsg(err.statusText)
    })
    .finally(() => {
      setIsLoadingLater(false)
    })
  }
  const savingsString = rec.AnnualCostSavings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (mobile) return (
    <div className="your-profile-bg even-child-right" >
      <Table  size="sm"  hover borderless>
        <tbody>
          <tr >
            <td>RESOURCE:</td>
            <td>{rec.FriendlyName}</td>
          </tr>
          <tr>
            <td>WHEN:</td>
            <td>{rec.When}</td>
          </tr>
          <tr>
            <td>PREDICTION METHOD:</td>
            <td>{rec.PredictionMethod}</td>
          </tr>
          <tr>
            <td>ANNUAL COST SAVINGS</td>
            <td >{savingsString}</td>
          </tr>

           <tr>
            <td>
              <Alert variant='danger' show={!!alertMsg.length} onClose={() => {setAlertMsg('')}} dismissible>
                {alertMsg}
              </Alert>
            </td>
            <td style={{textAlign: 'center'}}>
              <StdButton
                onClick={() => {applyNow(rec)}}
                disabled={disableButtons}
                bgColor="#D53232"
                height="2em" fontSize=".9em"
              >{nowButtonText}
              </StdButton>
              <StdButton
                onClick={() => {applyLater(rec)}}
                disabled={disableButtons}
                height="default" fontSize=".9em"
              >{laterButtonText}
              </StdButton>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
  return (
    <tr key={rec.ResourceId}>
      <td className="break-word" style={{color:'white'}} title={rec.ResourceId}>{rec.FriendlyName}</td>
      <td>{rec.When}</td>
      <td>{rec.PredictionMethod}</td>
      <td style={{color:'white'}} title={rec.Memo}>{rec.Recommendation}</td>
      <td style={{color:'white', width: 'auto', textAlign: 'right'}} title={rec.Memo}>${savingsString}</td>
      <td style={{textAlign: 'center'}}>
        <StdButton
          onClick={() => {applyNow(rec)}}
          disabled={disableButtons}
          bgColor="#D53232"
          width='100%'
          height="2em" fontSize=".9em"
        >{nowButtonText}
        </StdButton>
        <StdButton
          onClick={() => {applyLater(rec)}}
          disabled={disableButtons}
          width='100%'
          height="default" fontSize=".9em"
        >{laterButtonText}
        </StdButton>
        <Alert variant='danger' show={!!alertMsg.length} onClose={() => {setAlertMsg('')}} dismissible>
          {alertMsg}
        </Alert>
      </td>
    </tr>
  )

}