exports.countries = [
  {
    "desc": "Afghanistan",
    "code": "AFG"
  },
  {
    "desc": "Albania",
    "code": "ALB"
  },
  {
    "desc": "Algeria",
    "code": "DZA"
  },
  {
    "desc": "American Samoa",
    "code": "ASM"
  },
  {
    "desc": "Andorra",
    "code": "AND"
  },
  {
    "desc": "Angola",
    "code": "AGO"
  },
  {
    "desc": "Anguilla",
    "code": "AIA"
  },
  {
    "desc": "Antarctica",
    "code": "ATA"
  },
  {
    "desc": "Antigua and Barbuda",
    "code": "ATG"
  },
  {
    "desc": "Argentina",
    "code": "ARG"
  },
  {
    "desc": "Armenia",
    "code": "ARM"
  },
  {
    "desc": "Aruba",
    "code": "ABW"
  },
  {
    "desc": "Australia",
    "code": "AUS"
  },
  {
    "desc": "Austria",
    "code": "AUT"
  },
  {
    "desc": "Azerbaijan",
    "code": "AZE"
  },
  {
    "desc": "Bahamas",
    "code": "BHS"
  },
  {
    "desc": "Bahrain",
    "code": "BHR"
  },
  {
    "desc": "Bangladesh",
    "code": "BGD"
  },
  {
    "desc": "Barbados",
    "code": "BRB"
  },
  {
    "desc": "Belarus",
    "code": "BLR"
  },
  {
    "desc": "Belgium",
    "code": "BEL"
  },
  {
    "desc": "Belize",
    "code": "BLZ"
  },
  {
    "desc": "Benin",
    "code": "BEN"
  },
  {
    "desc": "Bermuda",
    "code": "BMU"
  },
  {
    "desc": "Bhutan",
    "code": "BTN"
  },
  {
    "desc": "Bolivia",
    "code": "BOL"
  },
  {
    "desc": "Bosnia and Herzegovina",
    "code": "BIH"
  },
  {
    "desc": "Botswana",
    "code": "BWA"
  },
  {
    "desc": "Brazil",
    "code": "BRA"
  },
  {
    "desc": "British Indian Ocean Territory",
    "code": "IOT"
  },
  {
    "desc": "British Virgin Islands",
    "code": "VGB"
  },
  {
    "desc": "Brunei",
    "code": "BRN"
  },
  {
    "desc": "Bulgaria",
    "code": "BGR"
  },
  {
    "desc": "Burkina Faso",
    "code": "BFA"
  },
  {
    "desc": "Burundi",
    "code": "BDI"
  },
  {
    "desc": "Cambodia",
    "code": "KHM"
  },
  {
    "desc": "Cameroon",
    "code": "CMR"
  },
  {
    "desc": "Canada",
    "code": "CAN"
  },
  {
    "desc": "Cape Verde",
    "code": "CPV"
  },
  {
    "desc": "Cayman Islands",
    "code": "CYM"
  },
  {
    "desc": "Central African Republic",
    "code": "CAF"
  },
  {
    "desc": "Chad",
    "code": "TCD"
  },
  {
    "desc": "Chile",
    "code": "CHL"
  },
  {
    "desc": "China",
    "code": "CHN"
  },
  {
    "desc": "Christmas Island",
    "code": "CXR"
  },
  {
    "desc": "Cocos Islands",
    "code": "CCK"
  },
  {
    "desc": "Colombia",
    "code": "COL"
  },
  {
    "desc": "Comoros",
    "code": "COM"
  },
  {
    "desc": "Cook Islands",
    "code": "COK"
  },
  {
    "desc": "Costa Rica",
    "code": "CRI"
  },
  {
    "desc": "Croatia",
    "code": "HRV"
  },
  {
    "desc": "Cuba",
    "code": "CUB"
  },
  {
    "desc": "Curacao",
    "code": "CUW"
  },
  {
    "desc": "Cyprus",
    "code": "CYP"
  },
  {
    "desc": "Czech Republic",
    "code": "CZE"
  },
  {
    "desc": "Democratic Republic of the Congo",
    "code": "COD"
  },
  {
    "desc": "Denmark",
    "code": "DNK"
  },
  {
    "desc": "Djibouti",
    "code": "DJI"
  },
  {
    "desc": "Dominica",
    "code": "DMA"
  },
  {
    "desc": "Dominican Republic",
    "code": "DOM"
  },
  {
    "desc": "East Timor",
    "code": "TLS"
  },
  {
    "desc": "Ecuador",
    "code": "ECU"
  },
  {
    "desc": "Egypt",
    "code": "EGY"
  },
  {
    "desc": "El Salvador",
    "code": "SLV"
  },
  {
    "desc": "Equatorial Guinea",
    "code": "GNQ"
  },
  {
    "desc": "Eritrea",
    "code": "ERI"
  },
  {
    "desc": "Estonia",
    "code": "EST"
  },
  {
    "desc": "Ethiopia",
    "code": "ETH"
  },
  {
    "desc": "Falkland Islands",
    "code": "FLK"
  },
  {
    "desc": "Faroe Islands",
    "code": "FRO"
  },
  {
    "desc": "Fiji",
    "code": "FJI"
  },
  {
    "desc": "Finland",
    "code": "FIN"
  },
  {
    "desc": "France",
    "code": "FRA"
  },
  {
    "desc": "French Polynesia",
    "code": "PYF"
  },
  {
    "desc": "Gabon",
    "code": "GAB"
  },
  {
    "desc": "Gambia",
    "code": "GMB"
  },
  {
    "desc": "Georgia",
    "code": "GEO"
  },
  {
    "desc": "Germany",
    "code": "DEU"
  },
  {
    "desc": "Ghana",
    "code": "GHA"
  },
  {
    "desc": "Gibraltar",
    "code": "GIB"
  },
  {
    "desc": "Greece",
    "code": "GRC"
  },
  {
    "desc": "Greenland",
    "code": "GRL"
  },
  {
    "desc": "Grenada",
    "code": "GRD"
  },
  {
    "desc": "Guam",
    "code": "GUM"
  },
  {
    "desc": "Guatemala",
    "code": "GTM"
  },
  {
    "desc": "Guernsey",
    "code": "GGY"
  },
  {
    "desc": "Guinea",
    "code": "GIN"
  },
  {
    "desc": "Guinea-Bissau",
    "code": "GNB"
  },
  {
    "desc": "Guyana",
    "code": "GUY"
  },
  {
    "desc": "Haiti",
    "code": "HTI"
  },
  {
    "desc": "Honduras",
    "code": "HND"
  },
  {
    "desc": "Hong Kong",
    "code": "HKG"
  },
  {
    "desc": "Hungary",
    "code": "HUN"
  },
  {
    "desc": "Iceland",
    "code": "ISL"
  },
  {
    "desc": "India",
    "code": "IND"
  },
  {
    "desc": "Indonesia",
    "code": "IDN"
  },
  {
    "desc": "Iran",
    "code": "IRN"
  },
  {
    "desc": "Iraq",
    "code": "IRQ"
  },
  {
    "desc": "Ireland",
    "code": "IRL"
  },
  {
    "desc": "Isle of Man",
    "code": "IMN"
  },
  {
    "desc": "Israel",
    "code": "ISR"
  },
  {
    "desc": "Italy",
    "code": "ITA"
  },
  {
    "desc": "Ivory Coast",
    "code": "CIV"
  },
  {
    "desc": "Jamaica",
    "code": "JAM"
  },
  {
    "desc": "Japan",
    "code": "JPN"
  },
  {
    "desc": "Jersey",
    "code": "JEY"
  },
  {
    "desc": "Jordan",
    "code": "JOR"
  },
  {
    "desc": "Kazakhstan",
    "code": "KAZ"
  },
  {
    "desc": "Kenya",
    "code": "KEN"
  },
  {
    "desc": "Kiribati",
    "code": "KIR"
  },
  {
    "desc": "Kosovo",
    "code": "XKX"
  },
  {
    "desc": "Kuwait",
    "code": "KWT"
  },
  {
    "desc": "Kyrgyzstan",
    "code": "KGZ"
  },
  {
    "desc": "Laos",
    "code": "LAO"
  },
  {
    "desc": "Latvia",
    "code": "LVA"
  },
  {
    "desc": "Lebanon",
    "code": "LBN"
  },
  {
    "desc": "Lesotho",
    "code": "LSO"
  },
  {
    "desc": "Liberia",
    "code": "LBR"
  },
  {
    "desc": "Libya",
    "code": "LBY"
  },
  {
    "desc": "Liechtenstein",
    "code": "LIE"
  },
  {
    "desc": "Lithuania",
    "code": "LTU"
  },
  {
    "desc": "Luxembourg",
    "code": "LUX"
  },
  {
    "desc": "Macau",
    "code": "MAC"
  },
  {
    "desc": "Macedonia",
    "code": "MKD"
  },
  {
    "desc": "Madagascar",
    "code": "MDG"
  },
  {
    "desc": "Malawi",
    "code": "MWI"
  },
  {
    "desc": "Malaysia",
    "code": "MYS"
  },
  {
    "desc": "Maldives",
    "code": "MDV"
  },
  {
    "desc": "Mali",
    "code": "MLI"
  },
  {
    "desc": "Malta",
    "code": "MLT"
  },
  {
    "desc": "Marshall Islands",
    "code": "MHL"
  },
  {
    "desc": "Mauritania",
    "code": "MRT"
  },
  {
    "desc": "Mauritius",
    "code": "MUS"
  },
  {
    "desc": "Mayotte",
    "code": "MYT"
  },
  {
    "desc": "Mexico",
    "code": "MEX"
  },
  {
    "desc": "Micronesia",
    "code": "FSM"
  },
  {
    "desc": "Moldova",
    "code": "MDA"
  },
  {
    "desc": "Monaco",
    "code": "MCO"
  },
  {
    "desc": "Mongolia",
    "code": "MNG"
  },
  {
    "desc": "Montenegro",
    "code": "MNE"
  },
  {
    "desc": "Montserrat",
    "code": "MSR"
  },
  {
    "desc": "Morocco",
    "code": "MAR"
  },
  {
    "desc": "Mozambique",
    "code": "MOZ"
  },
  {
    "desc": "Myanmar",
    "code": "MMR"
  },
  {
    "desc": "Namibia",
    "code": "NAM"
  },
  {
    "desc": "Nauru",
    "code": "NRU"
  },
  {
    "desc": "Nepal",
    "code": "NPL"
  },
  {
    "desc": "Netherlands",
    "code": "NLD"
  },
  {
    "desc": "Netherlands Antilles",
    "code": "ANT"
  },
  {
    "desc": "New Caledonia",
    "code": "NCL"
  },
  {
    "desc": "New Zealand",
    "code": "NZL"
  },
  {
    "desc": "Nicaragua",
    "code": "NIC"
  },
  {
    "desc": "Niger",
    "code": "NER"
  },
  {
    "desc": "Nigeria",
    "code": "NGA"
  },
  {
    "desc": "Niue",
    "code": "NIU"
  },
  {
    "desc": "North Korea",
    "code": "PRK"
  },
  {
    "desc": "Northern Mariana Islands",
    "code": "MNP"
  },
  {
    "desc": "Norway",
    "code": "NOR"
  },
  {
    "desc": "Oman",
    "code": "OMN"
  },
  {
    "desc": "Pakistan",
    "code": "PAK"
  },
  {
    "desc": "Palau",
    "code": "PLW"
  },
  {
    "desc": "Palestine",
    "code": "PSE"
  },
  {
    "desc": "Panama",
    "code": "PAN"
  },
  {
    "desc": "Papua New Guinea",
    "code": "PNG"
  },
  {
    "desc": "Paraguay",
    "code": "PRY"
  },
  {
    "desc": "Peru",
    "code": "PER"
  },
  {
    "desc": "Philippines",
    "code": "PHL"
  },
  {
    "desc": "Pitcairn",
    "code": "PCN"
  },
  {
    "desc": "Poland",
    "code": "POL"
  },
  {
    "desc": "Portugal",
    "code": "PRT"
  },
  {
    "desc": "Puerto Rico",
    "code": "PRI"
  },
  {
    "desc": "Qatar",
    "code": "QAT"
  },
  {
    "desc": "Republic of the Congo",
    "code": "COG"
  },
  {
    "desc": "Reunion",
    "code": "REU"
  },
  {
    "desc": "Romania",
    "code": "ROU"
  },
  {
    "desc": "Russia",
    "code": "RUS"
  },
  {
    "desc": "Rwanda",
    "code": "RWA"
  },
  {
    "desc": "Saint Barthelemy",
    "code": "BLM"
  },
  {
    "desc": "Saint Helena",
    "code": "SHN"
  },
  {
    "desc": "Saint Kitts and Nevis",
    "code": "KNA"
  },
  {
    "desc": "Saint Lucia",
    "code": "LCA"
  },
  {
    "desc": "Saint Martin",
    "code": "MAF"
  },
  {
    "desc": "Saint Pierre and Miquelon",
    "code": "SPM"
  },
  {
    "desc": "Saint Vincent and the Grenadines",
    "code": "VCT"
  },
  {
    "desc": "Samoa",
    "code": "WSM"
  },
  {
    "desc": "San Marino",
    "code": "SMR"
  },
  {
    "desc": "Sao Tome and Principe",
    "code": "STP"
  },
  {
    "desc": "Saudi Arabia",
    "code": "SAU"
  },
  {
    "desc": "Senegal",
    "code": "SEN"
  },
  {
    "desc": "Serbia",
    "code": "SRB"
  },
  {
    "desc": "Seychelles",
    "code": "SYC"
  },
  {
    "desc": "Sierra Leone",
    "code": "SLE"
  },
  {
    "desc": "Singapore",
    "code": "SGP"
  },
  {
    "desc": "Sint Maarten",
    "code": "SXM"
  },
  {
    "desc": "Slovakia",
    "code": "SVK"
  },
  {
    "desc": "Slovenia",
    "code": "SVN"
  },
  {
    "desc": "Solomon Islands",
    "code": "SLB"
  },
  {
    "desc": "Somalia",
    "code": "SOM"
  },
  {
    "desc": "South Africa",
    "code": "ZAF"
  },
  {
    "desc": "South Korea",
    "code": "KOR"
  },
  {
    "desc": "South Sudan",
    "code": "SSD"
  },
  {
    "desc": "Spain",
    "code": "ESP"
  },
  {
    "desc": "Sri Lanka",
    "code": "LKA"
  },
  {
    "desc": "Sudan",
    "code": "SDN"
  },
  {
    "desc": "Suriname",
    "code": "SUR"
  },
  {
    "desc": "Svalbard and Jan Mayen",
    "code": "SJM"
  },
  {
    "desc": "Swaziland",
    "code": "SWZ"
  },
  {
    "desc": "Sweden",
    "code": "SWE"
  },
  {
    "desc": "Switzerland",
    "code": "CHE"
  },
  {
    "desc": "Syria",
    "code": "SYR"
  },
  {
    "desc": "Taiwan",
    "code": "TWN"
  },
  {
    "desc": "Tajikistan",
    "code": "TJK"
  },
  {
    "desc": "Tanzania",
    "code": "TZA"
  },
  {
    "desc": "Thailand",
    "code": "THA"
  },
  {
    "desc": "Togo",
    "code": "TGO"
  },
  {
    "desc": "Tokelau",
    "code": "TKL"
  },
  {
    "desc": "Tonga",
    "code": "TON"
  },
  {
    "desc": "Trinidad and Tobago",
    "code": "TTO"
  },
  {
    "desc": "Tunisia",
    "code": "TUN"
  },
  {
    "desc": "Turkey",
    "code": "TUR"
  },
  {
    "desc": "Turkmenistan",
    "code": "TKM"
  },
  {
    "desc": "Turks and Caicos Islands",
    "code": "TCA"
  },
  {
    "desc": "Tuvalu",
    "code": "TUV"
  },
  {
    "desc": "U.S. Virgin Islands",
    "code": "VIR"
  },
  {
    "desc": "Uganda",
    "code": "UGA"
  },
  {
    "desc": "Ukraine",
    "code": "UKR"
  },
  {
    "desc": "United Arab Emirates",
    "code": "ARE"
  },
  {
    "desc": "United Kingdom",
    "code": "GBR"
  },
  {
    "desc": "United States",
    "code": "USA"
  },
  {
    "desc": "Uruguay",
    "code": "URY"
  },
  {
    "desc": "Uzbekistan",
    "code": "UZB"
  },
  {
    "desc": "Vanuatu",
    "code": "VUT"
  },
  {
    "desc": "Vatican",
    "code": "VAT"
  },
  {
    "desc": "Venezuela",
    "code": "VEN"
  },
  {
    "desc": "Vietnam",
    "code": "VNM"
  },
  {
    "desc": "Wallis and Futuna",
    "code": "WLF"
  },
  {
    "desc": "Western Sahara",
    "code": "ESH"
  },
  {
    "desc": "Yemen",
    "code": "YEM"
  },
  {
    "desc": "Zambia",
    "code": "ZMB"
  },
  {
    "desc": "Zimbabwe",
    "code": "ZWE"
  }
]

exports.states = [
  {
    "desc": "Alabama",
    "code": "AL"
  },
  {
    "desc": "Alaska",
    "code": "AK"
  },
  {
    "desc": "Arizona",
    "code": "AZ"
  },
  {
    "desc": "Arkansas",
    "code": "AR"
  },
  {
    "desc": "California",
    "code": "CA"
  },
  {
    "desc": "Colorado",
    "code": "CO"
  },
  {
    "desc": "Connecticut",
    "code": "CT"
  },
  {
    "desc": "Delaware",
    "code": "DE"
  },
  {
    "desc": "Dist. Columbia",
    "code": "DC"
  },
  {
    "desc": "Florida",
    "code": "FL"
  },
  {
    "desc": "Georgia",
    "code": "GA"
  },
  {
    "desc": "Hawaii",
    "code": "HI"
  },
  {
    "desc": "Idaho",
    "code": "ID"
  },
  {
    "desc": "Illinois",
    "code": "IL"
  },
  {
    "desc": "Indiana",
    "code": "IN"
  },
  {
    "desc": "Iowa",
    "code": "IA"
  },
  {
    "desc": "Kansas",
    "code": "KS"
  },
  {
    "desc": "Kentucky",
    "code": "KY"
  },
  {
    "desc": "Louisiana",
    "code": "LA"
  },
  {
    "desc": "Maine",
    "code": "ME"
  },
  {
    "desc": "Maryland",
    "code": "MD"
  },
  {
    "desc": "Massachusetts",
    "code": "MA"
  },
  {
    "desc": "Michigan",
    "code": "MI"
  },
  {
    "desc": "Minnesota",
    "code": "MN"
  },
  {
    "desc": "Mississippi",
    "code": "MS"
  },
  {
    "desc": "Missouri",
    "code": "MO"
  },
  {
    "desc": "Montana",
    "code": "MT"
  },
  {
    "desc": "Nebraska",
    "code": "NE"
  },
  {
    "desc": "Nevada",
    "code": "NV"
  },
  {
    "desc": "New Hampshire",
    "code": "NH"
  },
  {
    "desc": "New Jersey",
    "code": "NJ"
  },
  {
    "desc": "New Mexico",
    "code": "NM"
  },
  {
    "desc": "New York",
    "code": "NY"
  },
  {
    "desc": "North Carolina",
    "code": "NC"
  },
  {
    "desc": "North Dakota",
    "code": "ND"
  },
  {
    "desc": "Ohio",
    "code": "OH"
  },
  {
    "desc": "Oklahoma",
    "code": "OK"
  },
  {
    "desc": "Oregon",
    "code": "OR"
  },
  {
    "desc": "Pennsylvania",
    "code": "PA"
  },
  {
    "desc": "Rhode Island",
    "code": "RI"
  },
  {
    "desc": "South Carolina",
    "code": "SC"
  },
  {
    "desc": "South Dakota",
    "code": "SD"
  },
  {
    "desc": "Tennessee",
    "code": "TN"
  },
  {
    "desc": "Texas",
    "code": "TX"
  },
  {
    "desc": "Utah",
    "code": "UT"
  },
  {
    "desc": "Vermont",
    "code": "VT"
  },
  {
    "desc": "Virginia",
    "code": "VA"
  },
  {
    "desc": "Washington",
    "code": "WA"
  },
  {
    "desc": "West Virginia",
    "code": "WV"
  },
  {
    "desc": "Wisconsin",
    "code": "WI"
  },
  {
    "desc": "Wyoming",
    "code": "WY"
  },
  {
    "desc": "Alberta",
    "code": "AB"
  },
  {
    "desc": "British Columbia",
    "code": "BC"
  },
  {
    "desc": "Labrador",
    "code": "LB"
  },
  {
    "desc": "Manitoba",
    "code": "MB"
  },
  {
    "desc": "New Brunswick",
    "code": "NB"
  },
  {
    "desc": "Newfoundland",
    "code": "NF"
  },
  {
    "desc": "North West Terr.",
    "code": "NW"
  },
  {
    "desc": "Nova Scotia",
    "code": "NS"
  },
  {
    "desc": "Nunavut",
    "code": "NU"
  },
  {
    "desc": "Ontario",
    "code": "ON"
  },
  {
    "desc": "Prince Edward Is.",
    "code": "PE"
  },
  {
    "desc": "Quebec",
    "code": "QC"
  },
  {
    "desc": "Saskatchewen",
    "code": "SK"
  },
  {
    "desc": "Yukon",
    "code": "YU"
  }
]

exports.companySize = [
  {
    "desc": "1",
    "code": "A"
  },
  {
    "desc": "2-5",
    "code": "B"
  },
  {
    "desc": "6-10",
    "code": "C"
  },
  {
    "desc": "11-25",
    "code": "D"
  },
  {
    "desc": "25-50",
    "code": "E"
  },
  {
    "desc": "51-100",
    "code": "F"
  },
  {
    "desc": "101-500",
    "code": "G"
  },
  {
    "desc": "501-100",
    "code": "H"
  },
  {
    "desc": "1001+",
    "code": "I"
  }
]

exports.companySize = [
  {
    "desc": "1",
    "code": "A"
  },
  {
    "desc": "2-5",
    "code": "B"
  },
  {
    "desc": "6-10",
    "code": "C"
  },
  {
    "desc": "11-25",
    "code": "D"
  },
  {
    "desc": "25-50",
    "code": "E"
  },
  {
    "desc": "51-100",
    "code": "F"
  },
  {
    "desc": "101-500",
    "code": "G"
  },
  {
    "desc": "501-1000",
    "code": "H"
  },
  {
    "desc": "> 1000",
    "code": "I"
  }
]

exports.revenue = [
  {
    "desc": "< $100,000",
    "code": "A"
  },
  {
    "desc": "$100,000 - $999,999",
    "code": "B"
  },
  {
    "desc": "$1,000,000 - $4,999999",
    "code": "C"
  },
  {
    "desc": "> $5,000,000",
    "code": "D"
  },
]

exports.industry = [
  {
    "desc": "Accommodation",
    "code": "NAICS721"
  },
  {
    "desc": "Accommodation and Food Services",
    "code": "NAICS72"
  },
  {
    "desc": "Administrative and Support and Waste Management and Remediation Services",
    "code": "NAICS56"
  },
  {
    "desc": "Administrative and Support Services",
    "code": "NAICS561"
  },
  {
    "desc": "Agriculture, Forestry, Fishing and Hunting",
    "code": "NAICS11"
  },
  {
    "desc": "Air Transportation",
    "code": "NAICS481"
  },
  {
    "desc": "Ambulatory Health Care Services",
    "code": "NAICS621"
  },
  {
    "desc": "Amusement, Gambling, and Recreation Industries",
    "code": "NAICS713"
  },
  {
    "desc": "Animal Production",
    "code": "NAICS112"
  },
  {
    "desc": "Apparel Manufacturing",
    "code": "NAICS315"
  },
  {
    "desc": "Arts, Entertainment, and Recreation",
    "code": "NAICS71"
  },
  {
    "desc": "Beverage and Tobacco Product Manufacturing",
    "code": "NAICS312"
  },
  {
    "desc": "Broadcasting (except Internet)",
    "code": "NAICS515"
  },
  {
    "desc": "Building Material and Garden Equipment and Supplies Dealers",
    "code": "NAICS444"
  },
  {
    "desc": "Chemical Manufacturing",
    "code": "NAICS325"
  },
  {
    "desc": "Clothing and Clothing Accessories Stores",
    "code": "NAICS448"
  },
  {
    "desc": "Computer and Electronic Product Manufacturing",
    "code": "NAICS334"
  },
  {
    "desc": "Construction",
    "code": "NAICS23"
  },
  {
    "desc": "Construction of Buildings",
    "code": "NAICS236"
  },
  {
    "desc": "Couriers and Messengers",
    "code": "NAICS492"
  },
  {
    "desc": "Credit Intermediation and Related Activities",
    "code": "NAICS522"
  },
  {
    "desc": "Crop Production",
    "code": "NAICS111"
  },
  {
    "desc": "Data Processing, Hosting, and Related Services",
    "code": "NAICS518"
  },
  {
    "desc": "Education and Health Services",
    "code": "NN001"
  },
  {
    "desc": "Educational Services",
    "code": "NAICS61"
  },
  {
    "desc": "Electrical Equipment, Appliance, and Component Manufacturing",
    "code": "NAICS335"
  },
  {
    "desc": "Electronics and Appliance Stores",
    "code": "NAICS443"
  },
  {
    "desc": "Fabricated Metal Product Manufacturing",
    "code": "NAICS332"
  },
  {
    "desc": "Finance and Insurance",
    "code": "NAICS52"
  },
  {
    "desc": "Financial Activities",
    "code": "NN002"
  },
  {
    "desc": "Fishing, Hunting and Trapping",
    "code": "NAICS114"
  },
  {
    "desc": "Food and Beverage Stores",
    "code": "NAICS445"
  },
  {
    "desc": "Food Manufacturing",
    "code": "NAICS311"
  },
  {
    "desc": "Food Services and Drinking Places",
    "code": "NAICS722"
  },
  {
    "desc": "Forestry and Logging",
    "code": "NAICS113"
  },
  {
    "desc": "Funds, Trusts, and Other Financial Vehicles",
    "code": "NAICS525"
  },
  {
    "desc": "Furniture and Home Furnishings Stores",
    "code": "NAICS442"
  },
  {
    "desc": "Furniture and Related Product Manufacturing",
    "code": "NAICS337"
  },
  {
    "desc": "Gasoline Stations",
    "code": "NAICS447"
  },
  {
    "desc": "General Merchandise Stores",
    "code": "NAICS452"
  },
  {
    "desc": "Goods-Producing Industries",
    "code": "NN003"
  },
  {
    "desc": "Health and Personal Care Stores",
    "code": "NAICS446"
  },
  {
    "desc": "Health Care and Social Assistance",
    "code": "NAICS62"
  },
  {
    "desc": "Heavy and Civil Engineering Construction",
    "code": "NAICS237"
  },
  {
    "desc": "Hospitals",
    "code": "NAICS622"
  },
  {
    "desc": "Information",
    "code": "NAICS51"
  },
  {
    "desc": "Insurance Carriers and Related Activities",
    "code": "NAICS524"
  },
  {
    "desc": "Internet Publishing and Broadcasting",
    "code": "NAICS516"
  },
  {
    "desc": "Leather and Allied Product Manufacturing",
    "code": "NAICS316"
  },
  {
    "desc": "Leisure and Hospitality",
    "code": "NN004"
  },
  {
    "desc": "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
    "code": "NAICS533"
  },
  {
    "desc": "Machinery Manufacturing",
    "code": "NAICS333"
  },
  {
    "desc": "Management of Companies and Enterprises",
    "code": "NAICS55"
  },
  {
    "desc": "Manufacturing",
    "code": "NAICS31-33"
  },
  {
    "desc": "Merchant Wholesalers, Durable Goods",
    "code": "NAICS423"
  },
  {
    "desc": "Merchant Wholesalers, Nondurable Goods",
    "code": "NAICS424"
  },
  {
    "desc": "Mining (except Oil and Gas)",
    "code": "NAICS212"
  },
  {
    "desc": "Mining, Quarrying, and Oil and Gas Extraction",
    "code": "NAICS21"
  },
  {
    "desc": "Miscellaneous Manufacturing",
    "code": "NAICS339"
  },
  {
    "desc": "Miscellaneous Store Retailers",
    "code": "NAICS453"
  },
  {
    "desc": "Monetary Authorities - Central Bank",
    "code": "NAICS521"
  },
  {
    "desc": "Motion Picture and Sound Recording Industries",
    "code": "NAICS512"
  },
  {
    "desc": "Motor Vehicle and Parts Dealers",
    "code": "NAICS441"
  },
  {
    "desc": "Museums, Historical Sites, and Similar Institutions",
    "code": "NAICS712"
  },
  {
    "desc": "Natural Resources and Mining",
    "code": "NN005"
  },
  {
    "desc": "Nonmetallic Mineral Product Manufacturing",
    "code": "NAICS327"
  },
  {
    "desc": "Nonstore Retailers",
    "code": "NAICS454"
  },
  {
    "desc": "Nursing and Residential Care Facilities",
    "code": "NAICS623"
  },
  {
    "desc": "Oil and Gas Extraction",
    "code": "NAICS211"
  },
  {
    "desc": "Other Information Services",
    "code": "NAICS519"
  },
  {
    "desc": "Other Services (except Public Administration)",
    "code": "NAICS81"
  },
  {
    "desc": "Paper Manufacturing",
    "code": "NAICS322"
  },
  {
    "desc": "Performing Arts, Spectator Sports, and Related Industries",
    "code": "NAICS711"
  },
  {
    "desc": "Personal and Laundry Services",
    "code": "NAICS812"
  },
  {
    "desc": "Petroleum and Coal Products Manufacturing",
    "code": "NAICS324"
  },
  {
    "desc": "Pipeline Transportation",
    "code": "NAICS486"
  },
  {
    "desc": "Plastics and Rubber Products Manufacturing",
    "code": "NAICS326"
  },
  {
    "desc": "Postal Service",
    "code": "NAICS491"
  },
  {
    "desc": "Primary Metal Manufacturing",
    "code": "NAICS331"
  },
  {
    "desc": "Printing and Related Support Activities",
    "code": "NAICS323"
  },
  {
    "desc": "Private Households",
    "code": "NAICS814"
  },
  {
    "desc": "Professional and Business Services",
    "code": "NN006"
  },
  {
    "desc": "Professional, Scientific, and Technical Services",
    "code": "NAICS54"
  },
  {
    "desc": "Publishing Industries (except Internet)",
    "code": "NAICS511"
  },
  {
    "desc": "Rail Transportation",
    "code": "NAICS482"
  },
  {
    "desc": "Real Estate",
    "code": "NAICS531"
  },
  {
    "desc": "Real Estate and Rental and Leasing",
    "code": "NAICS53"
  },
  {
    "desc": "Religious, Grantmaking, Civic, Professional, and Similar Organizations",
    "code": "NAICS813"
  },
  {
    "desc": "Rental and Leasing Services",
    "code": "NAICS532"
  },
  {
    "desc": "Repair and Maintenance",
    "code": "NAICS811"
  },
  {
    "desc": "Retail Trade",
    "code": "NAICS44-45"
  },
  {
    "desc": "Scenic and Sightseeing Transportation",
    "code": "NAICS487"
  },
  {
    "desc": "Securities, Commodity Contracts, and Other Financial Investments and Related Activities",
    "code": "NAICS523"
  },
  {
    "desc": "Service-Providing Industries",
    "code": "NN007"
  },
  {
    "desc": "Social Assistance",
    "code": "NAICS624"
  },
  {
    "desc": "Specialty Trade Contractors",
    "code": "NAICS238"
  },
  {
    "desc": "Sporting Goods, Hobby, Book, and Music Stores",
    "code": "NAICS451"
  },
  {
    "desc": "Support Activities for Agriculture and Forestry",
    "code": "NAICS115"
  },
  {
    "desc": "Support Activities for Mining",
    "code": "NAICS213"
  },
  {
    "desc": "Support Activities for Transportation",
    "code": "NAICS488"
  },
  {
    "desc": "Telecommunications",
    "code": "NAICS517"
  },
  {
    "desc": "Textile Mills",
    "code": "NAICS313"
  },
  {
    "desc": "Textile Product Mills",
    "code": "NAICS314"
  },
  {
    "desc": "Trade, Transportation, and Utilities",
    "code": "NN008"
  },
  {
    "desc": "Transit and Ground Passenger Transportation",
    "code": "NAICS485"
  },
  {
    "desc": "Transportation and Warehousing",
    "code": "NAICS48-49"
  },
  {
    "desc": "Transportation Equipment Manufacturing",
    "code": "NAICS336"
  },
  {
    "desc": "Truck Transportation",
    "code": "NAICS484"
  },
  {
    "desc": "Utilities",
    "code": "NAICS22"
  },
  {
    "desc": "Warehousing and Storage",
    "code": "NAICS493"
  },
  {
    "desc": "Waste Management and Remediation Services",
    "code": "NAICS562"
  },
  {
    "desc": "Water Transportation",
    "code": "NAICS483"
  },
  {
    "desc": "Wholesale Electronic Markets and Agents and Brokers",
    "code": "NAICS425"
  },
  {
    "desc": "Wholesale Trade",
    "code": "NAICS42"
  },
  {
    "desc": "Wood Product Manufacturing",
    "code": "NAICS321"
  }
]