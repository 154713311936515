
import React from 'react';
import { useLogout } from '../../auth/jwtLogout';


export const getResources = async function(query) {
  return fetch('/api/v1/resource/' + query, {headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }})
  .then(response => {
    if (response.ok) {
      return response.json()
    }
    let err =  new Error('Error fetching resources')
    err.status = response.status
    err.statusText = response.statusText
    throw err;
  })
    .then(data => data.Data)
}

export const getMetrics = async function(query, minutes = 60) {
  return fetch(`/api/v1/metricdata?metricId=${query}&minutes=${minutes}`, {headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Accept-Encoding': 'gzip, deflate'
  }})
  .then(response => {
    if (response.ok) {
      return response.json()
    }
    let err =  new Error('Error fetching resources')
    err.status = response.status
    err.statusText = response.statusText
    throw err;
  })
    .then(data => data.Data)
}

export const getMetricsList = async function(query, minutes = 60) {
  return fetch(`/api/v1/metric${query}`, {headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Accept-Encoding': 'gzip, deflate'
  }})
  .then(response => {
    if (response.ok) {
      return response.json()
    }
    let err =  new Error('Error fetching resources')
    err.status = response.status
    err.statusText = response.statusText
    throw err;
  })
    .then(data => data.Data)
}

export const getProfile = async function(query) {
  return fetch('/api/v1/account/details' + (query ? query : ''), {headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }})
  .then(response => {
    if (response.ok) {
      return response.json()
    }
    let err =  new Error('Error fetching profile')
    err.status = response.status
    err.statusText = response.statusText
    throw err;
  })
    .then(data => data)
}

export const editProfile = async function(body) {
  return fetch('/api/v1/account/details', {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
      },
    body: body
  })
  .then(response => {
    if (response.ok) {
      return response.json()
    }
    let err =  new Error('Error updating profile')
    err.status = response.status
    err.statusText = response.statusText
    throw err;
  })
    .then(data => data)
}

export const updateAutomation = function(query) {
  return fetch('/api/v1/resource/' + query, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
      },
  })
  .then(response => {
    if (response.ok) {
      return response.json()
    }
    let err =  new Error('Error updating profile')
    err.status = response.status
    err.statusText = response.statusText
    throw err;
  })
    .then(data => data)
}

export const deleteTeammate = async function(email) {
  return fetch('/api/v1/team', {
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
      },
    body: JSON.stringify({emailAddress: email})
  })
  .then(response => {
    if (response.ok) {
      return response.text()
    }
    let err =  new Error('Error removing teammate')
    err.status = response.status
    err.statusText = response.statusText
    throw err;
  })
}

export const useFetch = (url, options) => {
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const signOut = useLogout();
  const fetchData = () => {
      setIsLoading(true);

      fetch(url, {headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }, ...options})
      .then(res => {
       if (res.ok) {
         return res.json()
       }
        let err =  new Error('Error with API call: ' + url)
        err.status = res.status;
        err.statusText = res.statusText;
        throw err;
      })
      .then(data => {
        setResponse(data);
        setIsLoading(false)
      })
       .catch((err) => {
        setError(err);
        setIsLoading(false)
        console.error(err.status, err.statusText, err)
        if(err.status === 401) {
          signOut();
        };
      })
    }

  React.useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { response, error, isLoading, fetchData };
};

export function useScrollToTop() {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },[])
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export function getDashStats() {
      return fetch('/api/v1/dashboard', {headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
            }})
      .then(response => {
        if (response.ok) {
                return response.json()
            }
            let err =  new Error('Error fetching dashboard')
            err.status = response.status
            err.statusText = response.statusText
            throw err;
            })
}

export function applyRecNow (resourceId) {
  return fetch('api/v1/log/recommendation/apply/now?id=' + resourceId, {headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }})
  .then(response => {
  if (response.ok) {
          return response.json()
      }
      let err =  new Error('Error applying recommendation')
      err.status = response.status
      err.statusText = response.statusText
      throw err;
      })
}

export function applyRecLater (resourceId) {
  return fetch('api/v1/log/recommendation/apply/maintenancewindow?id=' + resourceId, {headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }})
  .then(response => {
  if (response.ok) {
          return response.json()
      }
      let err =  new Error('Error applying recommendation')
      err.status = response.status
      err.statusText = response.statusText
      throw err;
      })
}
