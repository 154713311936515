import React, {useEffect} from 'react';

const PwPopover = function(props) {
  const {password1, setMeetsConditions} = props;
  const hasCapital = (/.*[A-Z].*/).test(password1);
  const hasLowerCase =(/.*[a-z].*/).test(password1);
  const hasNum = (/.*[0-9].*/).test(password1);
  const hasSpecialChar = (/.*[^A-Za-z0-9].*/).test(password1);
  const has3 = (hasCapital + hasLowerCase + hasNum + hasSpecialChar) > 2
  const pwLengthCheck = password1.length > 9 && password1.length < 37
  const pwLeadingTrailing = password1[0] !== ' ' && password1[0] !== ' '

  useEffect(() => {

  setMeetsConditions(
    (hasLowerCase + hasCapital + hasSpecialChar + hasNum) > 2
    && password1.length > 9
    && password1[0] !== ' '
    && password1[password1.length -1] !== ' '
  )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [password1])

  return (

      <ul className="pwList">
        <li className={pwLengthCheck ? 'green-check' : 'red-x'}>Have a length of 10-36 characters;</li>
        <li className={pwLeadingTrailing ? 'green-check' : 'red-x'}>Not begin or end with a space;</li>
        <li className={has3 ? 'green-check' : 'red-x'}>Have at least 3 of the following:</li>
        <ul className="pwList">
          <li className={hasCapital ? 'green-check' : 'grey-check'}>Contain an upper case letter;</li>
          <li className={hasLowerCase ? 'green-check' : 'grey-check'}>Contain a lower case letter;</li>
          <li className={hasNum ? 'green-check' : 'grey-check'}>Contain a number;</li>
          <li className={hasSpecialChar ? 'green-check' : 'grey-check'}>Contain a special character (!@#$%^&* _-+=?/;:.,).</li>
        </ul>
      </ul>

  )
}
export default PwPopover;