import React, {useState} from 'react';
import { Form, Row, Col, Alert, Spinner } from 'react-bootstrap';
import StdButton from '../common/stdButton';
import HowToGetIds from './howToGetIds';

var AddTenant = function(props) {

  const [name, setName] = useState('');
  const [type, setType] = useState(undefined);
  const [tenantId, setTenantId] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [secret, setSecret] = useState('');

  const [err, setErr] = useState('');
  const [isLoading, setIsLoading] =useState(false);

  const resetTenant = function() {
    setName('');
    setType('');
    setApplicationId('');
    setTenantId('');
    setSecret('');
  }

  const saveTenant = function(e) {
    e.preventDefault();
    setErr('');
    setIsLoading(true)
    fetch(`/api/v1/tenant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Connection: 'keep-alive',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        type: type,
        tenantId: tenantId,
        friendlyName: name,
        applicationId: applicationId,
        servicePrincipalSecret: secret
    })
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        throw response;
      })
      .then(data => {
        setIsLoading(false);
        resetTenant();
        props.refresh();
      })
      .catch(err => {
        console.log('fetch error', err.status, err.statusText);
        if (err.status === 400) setErr('The tenant cannot be authenticated, please check the your IDs and secret and try again.');
        if (err.status === 409) setErr('The tenant has already been added to your account.');
        setIsLoading(false);
      })
  }


  return (
    <React.Fragment>
      <div className="your-profile-bg maxw100" style={{fontSize: '.85rem', margin: '1em 0'}}>
        <h6 className="justify-left pad-p5"><b>Add Tenant</b></h6>
        <div className='ruler-div' />
        <br/>
        <div style={{maxWidth: '100%'}}>
          <HowToGetIds />
        </div>
        <Form className="fade-in side-margin-1em maxw100" onSubmit={saveTenant} >

                {err.length > 0 && <Alert variant="danger">{err}</Alert>}
            <Row xs={1} lg={3} xl={15} >
              <Form.Group  as={Col} xl="2" controlId="formBasicEmail" >
                <Form.Label className="justify-left bold">Name/Alias</Form.Label>
                <Form.Control className="dark-input" size="sm" placeholder="label for tenant"  autoComplete="off" required value={name} onChange={(e)=> setName(e.target.value)}/>
              </Form.Group>
                <Form.Group as={Col} xl="2" controlId="selectService"  style={{width: '100%'}}>
                  <Form.Label className="justify-left bold">Service</Form.Label>
                    <Form.Control className="dark-input" size='sm' as="select" value={type} required
                    >
                    <option value=''>--Select--</option>
                    <option  >AWS</option>
                    <option >Azure</option>
                    <option >GCP</option>
                  </Form.Control>
                </Form.Group>

              <Form.Group  as={Col} xl="3" controlId="formBasicTenant">
                <Form.Label className="justify-left bold">Tenant ID</Form.Label>
                <Form.Control className="dark-input" size="sm" placeholder="tenantID"  autoComplete="off" required value={tenantId} onChange={(e)=> setTenantId(e.target.value)}/>
              </Form.Group>
              <Form.Group  as={Col} xl="3" controlId="formBasicApp">
                <Form.Label className="justify-left bold">Application ID</Form.Label>
                <Form.Control className="dark-input" size="sm" placeholder="applicationID"  autoComplete="off" required value={applicationId} onChange={(e)=> setApplicationId(e.target.value)}/>
              </Form.Group>
              <Form.Group  as={Col} xl="2" controlId="formBasicPassword">
                <Form.Label className="justify-left bold"> Secret</Form.Label>
                <Form.Control className="dark-input" size="sm" value={secret} placeholder="Service Principal" type="new-password" required onChange={(e)=> setSecret(e.target.value)}/>
              </Form.Group>
            </Row>
            <Row>
              <Col className="justify-right bold" >
                <StdButton width="6em" type="submit" disabled={isLoading} >{isLoading ? <Spinner animation='border' size="sm"/> : 'Submit' }</StdButton>
              </Col>
            </Row>

        </Form>
      </div>
    </React.Fragment>
  );
}

export default AddTenant;