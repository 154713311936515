import React, {useState} from 'react';
import { FormControl, Alert, Spinner, Table } from 'react-bootstrap';
import StdButton from '../common/stdButton';
import DeleteModal from './deleteModal';
import useMediaQuery from '../common/useMediaQuery';

var Tenant = function({tenant, refresh}) {

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editTenant, setEditTenant] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState(tenant.FriendlyName || '')
  const [err, setErr] = useState('')

  const minWidth860  = useMediaQuery("(min-width: 860px)");

  const renameTenant = function(e) {
    e.preventDefault();
    setErr('');
    setIsLoading(true)
    fetch(`/api/v1/tenant/${tenant.Id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Connection: 'keep-alive',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({friendlyName: name})

    })
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        throw response;
      })
      .then(data => {
        setEditTenant(false);
        setIsLoading(false);
        refresh();
      })
      .catch(err => {
        console.log('fetch error', err.status, err.statusText);
        switch(err.status) {
          case 404:
            setErr(`You don't have authorization to delete this tenant.`);
            break;
          case 500:
            setErr(`There was a server error, please try again.`);
            break;
          default:
            setErr('There was a server error, please try again.');
        }
        setIsLoading(false);
      })

  }
  const openModal = function(e) {
    e.preventDefault()
    setDeleteModalOpen(true)
  }

  const cancelRename = function() {
    setErr('');
    setEditTenant(false);
  }

  if (minWidth860) return (
    <React.Fragment>
        <DeleteModal showModal={deleteModalOpen} setModal={setDeleteModalOpen} tenant={tenant} refresh={refresh} />
        <tr style={{textAlign: 'left'}}>

          {editTenant ? (
              <td style={{padding: '1em .1em'}}><FormControl size="sm" placeholder="name"  value={name} onChange={(e)=> setName(e.target.value)}/> </td>
            ) : (
              <td style={{padding: '1.4em .1em'}}>{tenant.FriendlyName}</td>
          )}
          <td style={{padding: '1.4em .1em'}}>{tenant.Type}</td>
          <td style={{padding: '1.4em .1em'}}>{tenant.TenantId}</td>
          <td style={{padding: '1.4em .1em'}}>{tenant.ApplicationId}</td>
          <td style={{overflow: 'auto', textAlign: 'center'}}>
            <StdButton
              bgColor={tenant.Status === 'OK' ? 'rgb(59,76,69)' : '#ff4d04'}
              fontColor={tenant.Status === 'OK' ? '#5AC891' : 'white'}
            >
              {tenant.Status}
            </StdButton>

            </td>
          {editTenant ? (
              <td style={{overflow: 'auto', textAlign: 'right'}}>
                <StdButton onClick={renameTenant} disabled={isLoading || name === tenant.FriendlyName}>{isLoading ? <Spinner size="sm" animation="border"/> : 'Save'}</StdButton>
                <StdButton disabled={isLoading} onClick={cancelRename}>Cancel</StdButton>
              </td>
            ) : (
              <td style={{overflow: 'auto', textAlign: 'right'}}>
              <StdButton bgColor='rgba(255,255,255,0.15)' width='7em' onClick={() => setEditTenant(true)}>Rename</StdButton>
              <StdButton bgColor='#FF4B4B' width='7em' onClick={openModal}>Delete</StdButton>
            </td>
          )}

        </tr>
        {err !== '' && <tr><td><Alert variant="danger">{err}</Alert></td></tr>}


    </React.Fragment>
  );

  return (
    <div className="your-profile-bg even-child-right">
      <DeleteModal showModal={deleteModalOpen} setModal={setDeleteModalOpen} tenant={tenant} refresh={refresh} />
      <Table  size="sm"  hover borderless>
        <tbody>
          <tr >
            <td>NAME:</td>
            {editTenant ? (
                <td ><FormControl size="sm" placeholder="name"  value={name} onChange={(e)=> setName(e.target.value)}/> </td>
              ) : (
                <td >{tenant.FriendlyName}</td>
            )}
          </tr>
          <tr>
            <td>SERVICE:</td>
            <td>{tenant.Type}</td>
          </tr>
          <tr>
            <td>TENANT ID:</td>
            <td>{tenant.TenantId}</td>
          </tr>
          <tr>
            <td>APPLICATION ID:</td>
            <td >{tenant.ApplicationId}</td>
          </tr>

           <tr>
            <td style={{textAlign: 'center'}}>
              STATUS:<br/>
              <StdButton
                bgColor={tenant.Status === 'OK' ? 'rgb(59,76,69)' : '#ff4d04'}
                fontColor={tenant.Status === 'OK' ? '#5AC891' : 'white'}
              >
                {tenant.Status}
              </StdButton>

              </td>
            {editTenant ? (
                <td style={{verticalAlign: 'bottom'}}>
                  <StdButton onClick={renameTenant} width='7em' disabled={isLoading || name === tenant.FriendlyName}>{isLoading ? <Spinner size="sm" animation="border"/> : 'Save'}</StdButton>
                  <StdButton disabled={isLoading} width='7em' bgColor='darkgrey' onClick={cancelRename}>Cancel</StdButton>
                </td>
              ) : (
                <td style={{verticalAlign: 'bottom'}}>
                <StdButton bgColor='rgba(255,255,255,0.15)' width='7em' onClick={() => setEditTenant(true)}>Rename</StdButton>
                <StdButton bgColor='#FF4B4B' width='7em' onClick={openModal}>Delete</StdButton>
              </td>
            )}

          </tr>
          {err !== '' && <tr><td><Alert variant="danger">{err}</Alert></td></tr>}
          </tbody>
      </Table>
    </div>
  );
}

export default Tenant;