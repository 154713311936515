import React from 'react';
import { Table, Container, Row, Col } from 'react-bootstrap';
import Tenant from './tenant';
import useMediaQuery from '../common/useMediaQuery';

var ManageTenants = function({ tenants, refresh }) {
  const minWidth860  = useMediaQuery("(min-width: 860px)");

  if (minWidth860) return (

      <div className="your-profile-bg  maxw100"  style={{margin: '1em 0'}}>
        <h6 className='justify-left bold pad-p5'>Manage Your Tenants</h6>
        <div className='ruler-div' />
        <Container fluid >
            <Table responsive borderless hover className='mb0 after-2nd-white'>
              <thead>
                <tr style={{color: 'white', textAlign: 'left', fontSize: '.85rem'}}>
                  <th>NAME </th>
                  <th>SERVICE</th>
                  <th>TENANT ID</th>
                  <th>APPLICATION ID</th>
                  <th style={{overflow: 'auto', textAlign: 'center'}}>STATUS</th>
                  <th/>

                </tr>
              </thead>
              <tbody>
              {tenants && tenants.map(record => (<Tenant key={record.TenantId} tenant={record} refresh={refresh} />))}
              </tbody>
            </Table>
        </Container>
      </div>

  );

  return (
    <div className="your-profile-bg maxw100" style={{fontSize: '.85rem', margin: '1em 0'}}>
      <h6 className='justify-left bold pad-p5'>Manage Your Tenants</h6>
      <div className='ruler-div' />
      <Container fluid >
          <Row>
            <Col>
              {tenants && tenants.map(record => (<Tenant key={record.TenantId} tenant={record} refresh={refresh} />))}
            </Col>
          </Row>
      </Container>
    </div>
  )
}

export default ManageTenants;