import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Home from './components/Home';
import Sandbox from './components/Sandbox';
import TopAndSide from './components/NavBar/topAndSide';
import NoMatch from './components/NoMatch';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Home} />
          <Route path="/signup" component={Home} />
          <Route path="/profile">
            <TopAndSide/>
          </Route>
          <Route path="/recover" component={Home} />
          <Route path="/ResetPassword" component={Home} />
          <Route path="/verify" component={Home} />
          <Route path="/sandbox" component={Sandbox} />
          <Route path="/sidebar" component={TopAndSide} />
          <Route path="/security" component={TopAndSide} />
          <Route path="/recommendations" component={TopAndSide} />
          <Route path="/pending" component={TopAndSide} />
          <Route path="/logs" component={TopAndSide} />
          <Route path="/resources" component={TopAndSide} />
          <Route path="/home" component={TopAndSide} />
          <Route path="/automation" component={TopAndSide} />
          <Route path="/team" component={TopAndSide} />
          <Route path="/analysis" component={TopAndSide} />
          <Route exact path="/" component={Home} />
          <Route path="*">
              <NoMatch />
          </Route>
        </Switch>

      </BrowserRouter>
    </div>
  );
}

export default App;
