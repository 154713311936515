import React, {useState, useEffect} from 'react';
import { Container, Table, Alert } from 'react-bootstrap';
import ChangePassword from './changePassword';
import {getProfile, useScrollToTop} from '../common/utils';
import EditProfile from './editProfile';
import StdButton from '../common/stdButton'
import logout from '../../auth/jwtLogout.js';

var Profile = function(props) {

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();
  const [country, setCountry] = useState();
  const [companySize, setCompanySize] = useState();
  const [annualRevenue, setAnnualRevenue] = useState();
  const [industry, setIndustry] = useState();

  useScrollToTop();

  const updateProfile = React.useCallback(() => {
    getProfile()
    .then(data => {
      setFirstName(data.FirstName);
      setLastName(data.LastName);
      setEmail(data.Email)
      setAddress1(data.Address1);
      setAddress2(data.Address2);
      setCity(data.City);
      setState(data.State);
      setZipcode(data.Zipcode);
      setCountry(data.Country);
      setCompanySize(data.CompanySize);
      setAnnualRevenue(data.AnnualRevenue);
      setIndustry(data.Industry)
    })
    .catch(error => {
      console.log(error);
      if(error.status === 401) {
        logout();
      }
    })
  },[])

  useEffect(() => {
    updateProfile();
  },[updateProfile])



  return (
    <Container fluid className='page-main-container'>
      <div className="your-profile-bg even-child-right">

        {(!showEditForm && !showPasswordForm)&&
        <div className='fade-in' >
          <div className="justify-left pad-p5">
            <h6><b>Your Profile</b></h6>
          </div>

          <Table size="sm"  hover borderless>
            <tbody>
              <tr>
                <td>First Name:</td>
                <td>{firstName}</td>
              </tr>
              <tr>
                <td>Last Name: </td>
                <td>{lastName}</td>
              </tr>
              <tr>
                <td>E-mail: </td>
                <td>{email}</td>
              </tr>
              <tr>
                <td>Address 1: </td>
                <td>{address1}</td>
              </tr>
              <tr>
                <td>Address 2: </td>
                <td>{address2}</td>
              </tr>
              <tr>
                <td>City: </td>
                <td>{city}</td>
              </tr>
              <tr>
                <td>State: </td>
                <td>{state}</td>
              </tr>
              <tr>
                <td>Zip Code: </td>
                <td>{zipcode}</td>
              </tr>
              <tr>
                <td>Country: </td>
                <td>{country}</td>
              </tr>
              <tr>
                <td>CompanySize: </td>
                <td>{companySize}</td>
              </tr>
              <tr>
                <td>Annual Revenue: </td>
                <td>{annualRevenue}</td>
              </tr>
              <tr>
                <td>Industry: </td>
                <td>{industry}</td>
              </tr>
            </tbody>
          </Table>
          <div className="width-100 pad-p5 justify-right">
            <StdButton
              width="12em"
              disabled={showEditForm}
              onClick={() => setShowEditForm(true)}
              bgColor="rgba(255,255,255,0.15)"
            >
              Edit Profile
            </StdButton>
            <StdButton
              width="12em"
              onClick={() => setShowPasswordForm(true)}
            >
              Change Password
            </StdButton>
          </div>

                  </div>}

          {showEditForm && <EditProfile
        setShowForm={setShowEditForm} setAlert={setAlertMessage}
        updateProfile={updateProfile} logout={logout}
        firstName={firstName} setFirstName={setFirstName}
        lastName={lastName} setLastName={setLastName}
        address1={address1} setAddress1={setAddress1}
        address2={address2} setAddress2={setAddress2}
        city={city} setCity={setCity}
        state={state} setState={setState}
        zipcode={zipcode} setZipcode={setZipcode}
        country={country} setCountry={setCountry}
        companySize={companySize} setCompanySize={setCompanySize}
        annualRevenue={annualRevenue} setAnnualRevenue={setAnnualRevenue}
        industry={industry} setIndustry={setIndustry}
        /> }

        {showPasswordForm && <ChangePassword setShowForm={setShowPasswordForm} logout={logout} /> }
      </div>
      <Alert show={alertMessage.length}
        variant="success"
        onClose={() => setAlertMessage('')}
        style={{margin:'5px'}}
        dismissible>
        <p>
          {alertMessage}
        </p>
      </Alert>

    </Container>
  );
}

export default Profile;