import React, { useState } from 'react';
import { Table, Container} from 'react-bootstrap';
import StdButton from '../common/stdButton';
import ChartPanel from './Chart/chartPanel';
import AutomationModal from './automationModal';
import LogModal from './logModal';
import { automationState, automationColors } from '../common/enums';
import useMediaQuery from '../common/useMediaQuery';

const ResourceTable = ({resources, query, logQuery, updateResources, isSearchResult }) => {
  const minWidth868  = useMediaQuery("(min-width: 868px)");
  const resourceSet = new Set();
  const resourceTable = resources.map(resource => {
              if (resourceSet.has(resource.ResourceId)) return null;
              resourceSet.add(resource.ResourceId);
              return (
               <ResourceRow key={resource.ResourceId} resource={resource} query={query} logQuery={logQuery} updateResources={updateResources} isSearchResult={isSearchResult} />
              )}
            )

  if (minWidth868) return (
    <Container fluid className='fade-in'>
      <Table responsive="sm" hover borderless className='resource-table'>
        <thead style={{color:'white', fontSize: '.75em', padding: '5px', border: '0px', backgroundColor: '#232528', lineHeight: '20px'}}>
          <tr>
            <th>AUTOMATION</th>
            <th>RESOURCE NAME</th>
            <th>PREDICTION METHOD</th>
            <th>RESOURCE CATEGORY</th>
            <th>SUBCATEGORY</th>
            <th>LOGS</th>
          </tr>
        </thead>
        <tbody>
          {resourceTable}
        </tbody>
      </Table>
    </Container>
  )
  return (
    <div className='fade-in'>
    {resourceTable}
    </div>
  )
}

const ResourceRow = function ({ resource, query, logQuery, updateResources, isSearchResult }) {
  const { ResourceName, ResourceCategory, SubCategory, ResourceId, Automated, PredictionMethod, PredictionMethodFriendlyShortText } = resource
  const minWidth868  = useMediaQuery("(min-width: 868px)");
  const [showCharts, setShowCharts] = useState(false);
  const [showAutoModal, setShowAutoModal] = useState(false);
   const [showLogModal, setShowLogModal] = useState(false);
  const controlCharts = function() {
    setShowCharts(!showCharts)
  }

  const chartData = {
     name: ResourceName,
     query: `${query}/${ResourceName}`,
     metricQuery: ResourceId
       }

  const openAutoModal = () => {
    setShowAutoModal(true)
  }

    const closeAutoModal = () => {
    setShowAutoModal(false)
  }

    const openLogModal = () => {
    setShowLogModal(true)
  }

    const closeLogModal = () => {
    setShowLogModal(false)
  }

  let automationQuery = `${query}/${ResourceCategory}/${SubCategory}/${ResourceName}`
  if (isSearchResult) {
    automationQuery = `${resource.TenantId}/${resource.ResourceId}`
      .replace('/subscriptions/', '')
      .replace('/resourceGroups/','/')
      .replace('/providers/','/')
  }
  const newLogQuery = `${logQuery}/providers/${ResourceCategory}/${SubCategory}/${ResourceName}`

  if (minWidth868) return (
     <React.Fragment>
        <AutomationModal resource={resource} show={showAutoModal} updateResources={updateResources}
          closeModal={closeAutoModal} query={automationQuery} />
        <LogModal resource={resource} show={showLogModal}
          closeModal={closeLogModal} query={isSearchResult ? resource.ResourceId : newLogQuery} />
        <tr style={{cursor: 'pointer'}}>
          <td ><StdButton onClick={openAutoModal} bgColor="rgba(255,255,255,0.15)" fontColor={automationColors[Automated]} width='6rem' height="1.8em" >{automationState[Automated]}</StdButton>
          </td>
          <td onClick={controlCharts} title={`click here to ${showCharts ? 'hide' : 'view'} metrics`} className='break-word' >{ResourceName}</td>
          <td onClick={controlCharts} title={PredictionMethodFriendlyShortText}  >{PredictionMethod}</td>
          <td onClick={controlCharts} title={`click here to ${showCharts ? 'hide' : 'view'} metrics`}  className='break-word' >{ResourceCategory}</td>
          <td onClick={controlCharts} title={`click here to ${showCharts ? 'hide' : 'view'} metrics`}  className='break-word' style={{color: 'white'}}>{SubCategory}</td>
          <td ><StdButton onClick={openLogModal} bgColor="#26C1DB" width='6rem' height="1.8em" >LOGS</StdButton>
          </td>
        </tr>
        <tr style={{margin: '0px', padding: '0px'}}>
          <td colSpan={6} style={{padding: '0px', margin: '0px', height:'0px'}}>
            <div style={{maxHeight: showCharts ? '550px' : '0', height: '550px', transition: 'max-height .4s ease', overflow: 'hidden' }}>
              { showCharts && <ChartPanel resource={chartData} closePanel={controlCharts}/> }
            </div>

          </td>
        </tr>
      </React.Fragment>
  )

  return (
    <Table className="your-profile-bg even-child-right" size="sm"  hover borderless style={{margin: 0, backgroundColor: 'rgba(50,50,50,.75'}}>
      <tbody>
        <tr >
          <td>RESOURCE NAME:</td>
          <td className='break-word'>{ResourceName}</td>
        </tr>
        <tr>
          <td>PREDICTION METHOD</td>
          <td title={PredictionMethodFriendlyShortText} >{PredictionMethod}</td>
        </tr>
        <tr>
          <td>RESOURCE CATEGORY</td>
          <td>{ResourceCategory}</td>
        </tr>
        <tr>
          <td>SUBCATEGORY</td>
          <td>{SubCategory}</td>
        </tr>
        <tr>
          <td colSpan='2'>
            <div style={{display:'flex', justifyContent:'space-between'}}>
            <StdButton onClick={openAutoModal} bgColor="rgba(255,255,255,0.15)" fontColor={automationColors[Automated]} width='6rem' height="1.8em" >{automationState[Automated]}</StdButton>
            <StdButton onClick={openLogModal} bgColor="#26C1DB" width='6rem' height="1.8em" >LOGS</StdButton>
            <StdButton onClick={controlCharts} bgColor="#5AC891" fontColor='white' width='6rem' height="1.8em" >METRICS</StdButton>
            </div>
            <LogModal resource={resource} show={showLogModal} closeModal={closeLogModal} query={isSearchResult ? resource.ResourceId : newLogQuery} />
              <AutomationModal resource={resource} show={showAutoModal} updateResources={updateResources}
            closeModal={closeAutoModal} query={automationQuery} />

          </td>
        </tr>
        <tr style={{margin: '0px', padding: '0px'}}>
          <td colSpan={2}  style={{padding: '0px', margin: '0px', height:'0px'}}>
            <div style={{maxHeight: showCharts ? '550px' : '0', height: '550px', transition: 'max-height .4s ease', overflow: 'hidden' }}>
            { showCharts && <ChartPanel resource={chartData} closePanel={controlCharts}/> }
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}


export default ResourceTable;