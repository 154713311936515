import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { StartButton } from './start';
import { Form, Spinner, Alert, Popover, OverlayTrigger} from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

var SignUp = function() {

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(<div/>);
  const [showPopover, setShowPopover] = useState(false);
  const [success, setSuccess] = useState(false);
  const [recaptchaOk, setRecaptchaOk] = useState(false);
  const recaptchaRef = React.createRef();

  const testResponses = function(emaddr) {
    switch (emaddr) {
      case 'sbtest@test.com':
        setSuccess(true);
        break;
      case 'sbtest409@test.com':
        setAlert(<Alert409 />);
        break;
      case 'sbtest425@test.com':
        setAlert(<Alert425 />);
        break;
      case 'sbtest403@test.com':
        setAlert(<Alert403 />);
        break;
      default:
        setAlert(<AlertMisc/>);
    }
    setIsLoading(false);
  }

  const popover = (
  <Popover id="popover-basic">
    <Popover.Content>
      Please check this box before you submit the form.
    </Popover.Content>
  </Popover>
);

  const submitHandler = function(e) {
    e.preventDefault();

    if (!recaptchaOk) {
      setShowPopover(true);
      return;
    }
    setAlert(<div/>);
    setIsLoading(true);

    if (email.substring(0,6) === 'sbtest') {
      testResponses(email);
      return;
    }

    fetch(`/api/v1/emailaddress`, { //url taken from jira stub
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json',
        'Connection': 'keep-alive'
      },
      body: JSON.stringify({emailaddress: email, ReCaptchaResponse: recaptchaRef.current.getValue()})
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        throw response;
      })
      .then(data => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch(err => {
        console.log('fetch error', err.status, err.statusText);
        if (err.status === 409) {
          setAlert(<Alert409 />);
        } else if (err.status === 425) {
          setAlert(<Alert425 />);
        } else if (err.status === 403) {
          setAlert(<Alert403 />);
        } else {
          setAlert(<AlertMisc />);
        }
        setIsLoading(false);
      })
  }

  if (success) return (
    <div className='fade-in max350'>
      <h1>Thank you!</h1>
      <br/>
      <h2>You should receive a confirmation email shortly.</h2>
      <br/>
      <h2>Please use the link in the email to finish signing up for an account.</h2>
    </div>
  )

  return (
    <div  className='max350 fade-in'>
      <div className='signup-header'>Sign up now to get started with StackBoss!</div>
      <Form onSubmit={submitHandler} style={{margin: '2em 0 0 0'}}>
        <Form.Group controlId='formBasicEmail' style={{width: '85%', margin: 'auto'}}>
          <Form.Label className='justify-left'>Email Address</Form.Label>
          <Form.Control
            className='dark-input'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type='email'
            placeholder='Enter your work email here'
            required
            autoComplete='username' />
        </Form.Group>
        <p className='agree-text'>
          By signing up, you are agreeing to our <Link to='/' style={{color: 'white', textDecoration: 'underline' }}>
            Terms of Service
          </Link> and <Link to='/' style={{color: 'white', textDecoration: 'underline'}}>
            Privacy Policy
          </Link>.
        </p>
        {alert}
        <div style={{textAlign: "center"}}>
          <OverlayTrigger show={showPopover} placement="top" overlay={popover}>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LeG55gaAAAAAAAaSdpAfwH0ikBptZhNoH3lFQA3"
                // localhost test key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                // server key: 6LeG55gaAAAAAAAaSdpAfwH0ikBptZhNoH3lFQA3
                onChange={() => {setRecaptchaOk(true); setShowPopover(false)}}
                onExpired={() => {setRecaptchaOk(false)}}
                style={{display: "inline-block"}}
              />
          </OverlayTrigger>

        </div>
        <StartButton
          disabled={isLoading }
          type="submit" > {isLoading ? <Spinner animation='border' /> : 'Submit'}
        </StartButton>
      </Form>
      <br/>

      {/* Link needs to be updated once TOS and PP are ready. */}
    </div>
  );
}

function Alert409() {
  return (
    <Alert variant='danger' >
      That email is already in use. <Link to='/login'>CLICK HERE</Link> to log in. If you need to recover your password, <Link to='/recover'>CLICK HERE</Link>.
      {/* Link needs to be updated once password reset form is complete. */}
    </Alert>
  )
}

function AlertMisc() {
  return (
    <Alert variant='danger' >
      We encountered an error, please try again later.
    </Alert>
  )
}

function Alert425() {
  return (
    <Alert variant='danger' >
      We sent a confirmation email to that address a few minutes ago. If you don't receive an email in the next five minutes, please try again.
    </Alert>
  )
}

function Alert403() {
  return (
    <Alert variant='danger' >
      The confirmation email for this address has been requested more than the allowed number of times. <Link to='/'>CLICK HERE</Link> to contact customer support if you need assistance.
      {/* Link needs to be updated once customer support form is complete. */}
    </Alert>
  )
}

export default SignUp;