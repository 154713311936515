import React from 'react';
import { Container, Table, Alert } from 'react-bootstrap';
import {useFetch, useScrollToTop} from '../common/utils';
import Loading from '../common/loadingLogo'

function Pending({query}) {
  useScrollToTop();
  const { response, error, isLoading } = useFetch('api/v1/log/pendingautomation' + (query ? '&resourceId=' + query : ''));

  if (isLoading) return (
        <Loading />

  )

  if (error) return (
    <Alert variant='danger'>Error: {error.statusText} </Alert>
  )

  return (
    <Container className="fade-in fontsize-p85 page-main-container" fluid>
      <div className='your-profile-bg' style={{color: 'white'}}>
        <Table responsive='sm' variant='dark'>
          <colgroup>
              <col span="1" style={{width: '60%'}}/>
              <col span="1" style={{width: "20%"}}/>
              <col span="1" style={{width: "20%"}}/>
          </colgroup>

          <thead style={{textAlign: 'left'}}>
            <tr>
              <th style={{maxWidth:'50%'}}>Resource</th>
              <th>When</th>
              <th>New Size</th>
            </tr>
          </thead>
          <tbody>
            {!response.Data.length && (
              <tr>
                <td colSpan='3' >
                  <h4 >No pending automation right now, check back later.</h4>
                </td>
              </tr>
            )}

            {!!response.Data.length && response.Data.map(rec => (
              <tr key={rec.Id}>
                <td className='break-word' style={{maxWidth:'50%', color: 'white'}} title={rec.ResourceId} >{rec.FriendlyName}</td>
                <td>{rec.ExecutionTime}:00 UTC</td>
                <td style={{color:'white'}}>{rec.NewSize}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>

  )
}

export default Pending;