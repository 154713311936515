import React, { useState } from 'react';
import { Container, Form, Alert, Spinner, Table, Row, Col } from 'react-bootstrap';
import { useLogout } from '../../auth/jwtLogout';
import { useFetch, deleteTeammate, useScrollToTop } from '../common/utils';
import StdButton from '../common/stdButton';

 const ManageUsers = (props) => {

  const [email, setEmail] = useState('');
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const logout = useLogout();
  let team = useFetch('/api/v1/team')
  const [teamList, setTeamList] = useState(null);
  const [removeErr, setRemoveErr] = useState(null)

  useScrollToTop();

  React.useEffect(() => {
    setTeamList(team.response)
  },[team.response])

  const updateEmail = function(e) {
    setEmail(e.target.value);
    setErr(null);
    setIsSuccess(false);
  }

  const removeUser = function (email) {
    deleteTeammate(email)
    .then(resp => {
      console.log('teammate deleted')
      team.fetchData();
    })
    .catch(err => {
      if (err.status === 401) logout();
      console.log(err, err.status, err.statusText)
      setRemoveErr(err.statusText);
    })
  }

  const submitHandler = function(e) {
    e.preventDefault();
    setErr(null)
    setIsSuccess(false);
    setIsLoading(true);

    fetch('api/v1/colleague?emailAddress=' + email, {headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
            }})
      .then(resp => {
        if (resp.ok) {
          return resp.json()
        }
        const err = new Error('Error adding colleague email address.')
          err.status = resp.status;
          err.statusText = resp.statusText;
          throw err;
      })
      .then(data => {
        setIsLoading(false);
        setIsSuccess(true);
        team.fetchData();
      })
      .catch(err => {
        console.log('fetch error', err, err.status, err.statusText);
        if(err.status === 401) {
          logout();
        }
        setErr(err.status);
        setIsLoading(false);
      })
  }

  return (
    <Container className="fade-in fontsize-p85 page-main-container" fluid>
      <div className='your-profile-bg'>

        <div className="justify-left pad-p5">
          <h6><b>Invite Colleagues</b></h6>
        </div>

        <Form onSubmit={submitHandler} style={{margin: '1em'}}>
          <Row>
            <Col xs={8}>
              <Form.Group controlId='formBasicFName'>
                  <Form.Label className='justify-left'><b>Colleague Email</b></Form.Label>
                  <Form.Control onChange={updateEmail}
                    className='dark-input'
                    autoComplete='off'
                    name='email'
                    value={email}
                    type='email'
                    placeholder='Email Address'
                    required />
                </Form.Group>
            </Col>
            <Col style={{display: 'grid', placeItems: 'end', paddingBottom: '.5em'}}>
              <StdButton className='login-submit-button
                font-weight-bold'
                width="10em"
                height = '2.5rem'
                fontSize="1rem"
                disabled={isLoading}
                type="submit" > {isLoading ? <Spinner animation='border' /> : 'INVITE'}
              </StdButton>
            </Col>
          </Row>


        </Form>
        {(err && err !== 409) && <Alert  style={{margin: '1em 2em', fontWeight: '500'}} variant="danger">There was an error submitting your form. Please Try again later.</Alert>}
        {(err && err === 409) && <Alert style={{margin: '1em 2em', fontWeight: '500'}} variant="danger">{email} either already has a StackBoss account or has already been invited.</Alert>}
        {isSuccess && <Alert  style={{margin: '1em 2em', fontWeight: '500'}} variant="success">Thank you, your colleague will be invited to sign up for StackBoss.</Alert>}
      </div>
      <div className='your-profile-bg pad-p5'>
        <div className="justify-left">
          <h6><b>Your Team</b></h6>
        </div >
        {removeErr && <Alert dismissable onClose={() => setRemoveErr(null)} style={{margin: '1em 2em', fontWeight: '500'}} variant="danger">{removeErr}</Alert>}
        <div className='width100'>
          <Table hover responsive style={{fontSize: '1.05rem'}}>
            <thead style={{textAlign: 'left',  color:'white', fontSize: '.75em', border: '0px', backgroundColor: '#232528', lineHeight: '40px'}}>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Status</th>
                <th>Remove From Team</th>
              </tr>
            </thead>
            <tbody>


            {teamList && teamList.map(member => (
              <tr key={member.EmailAddress} >
                <td className="break-word" style={{verticalAlign: 'middle'}}>
                  {member.EmailAddress}
                </td>
                <td style={{verticalAlign: 'middle', color: 'white'}}>
                  {member.FirstName} {member.LastName}
                </td>
                <td  style={{verticalAlign: 'middle'}}>
                  {member.Status}
                </td>
                <td>
                  <StdButton className='login-submit-button
                    font-weight-bold'
                    fontSize=".8rem"
                    bgColor="#3C4045"
                    height="2rem"
                    disabled={isLoading}
                    onClick={() => {removeUser(member.EmailAddress)}}
                    type="button" > REMOVE

                  </StdButton>
                </td>
              </tr>
            ))}
           </tbody>
          </Table>
        </div>

        <div>
          {}
        </div>
      </div>
    </Container>
  )
}

export default ManageUsers;