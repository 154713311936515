import React, {useState, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { getResources, useScrollToTop } from '../common/utils';
import {SubscriptionAccordion} from './resourceAccordions'
import {useLogout} from '../../auth/jwtLogout';
import DashCard from './DashCard';
import DashChart from './Chart/DashChart';
import { Loading } from '../NavBar/topAndSide'
import {getDashStats} from '../common/utils'

var Dashboard = function(props) {
  useScrollToTop();
  const logout= useLogout();
  const [dashStats, setDashStats] = useState({});
  const [tenants, setTenants] = useState({});
  const [loading, setLoading] = useState(true)

  const updateStats = () => {
        getDashStats()
            .then(data => {
            setDashStats(data);

        })
        .catch(err => {
            console.error(err)
            if (err.status === 401) {
              logout()
            }
        })
  }

  useEffect(() => {

    getDashStats()
            .then(data => {
            setDashStats(data);
        })
      .then(() => getResources(''))
      .then((data) => {
        setTenants(data)
        setLoading(false);
      })
      .catch(err => {
            console.error(err)
            if (err.status === 401) {
              logout()
            }
        })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    if (loading) return <Loading />

  return (
    <Container fluid className='page-main-container fade-in'>
      <DashCards dashStats={dashStats} updateStats={updateStats} />
      <DashChartContainer dashStats={dashStats} />
      {tenants && tenants.map((tenant) => (
        <div key={tenant.Id} >
          <div className='ruler-div' style={{position: 'relative', top: '37px', zIndex: '0'}} />
          <div className='bold tenant-title-box justify-center' >
            <div>
              {tenant.FriendlyName}
            </div>
          </div>
          <Subscriptions tenant={tenant} setTenants={setTenants} />
        </div>

      ))}
    </Container>
  );
}

const Subscriptions = function ({tenant, setTenants}) {
  const [subscriptions, setSubscriptions] = useState([])
  const logout = useLogout();

  const updateSubscriptions = () => {
        getResources(tenant.TenantId)
      .then(resp => {
        setSubscriptions(resp)
      })
      .then(() => getResources(''))
      .then(resp => {
        setTenants(resp)
      })
      .catch((err => {
        console.error(err)
        if (err.status === 401) {
         logout();
        }
      }))
  }

  useEffect(() => {
    updateSubscriptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant.TenantId])


  return (
    <div className='your-profile-bg' style={{overflowWrap: 'break-word'}} >
         <SubscriptionAccordion resources={subscriptions} tenant={tenant} updateResources={updateSubscriptions}/>
    </div>
  )
}

const DashCards = function({dashStats,   updateStats}) {
  const {
    TenantCount,
    TenantsHealthy,
    ResourceCount,
    ResourcesUnderAutomation,
    UserCount,
    LastDataAcquired,
    AutomationsPerformed,
    DataPointsProcessed,
    IdentifiedSavings,
    RecommendationsWaiting
  } = dashStats;
  const [lastDataText, setLastDataText] = useState('More than an hour');

  const healthyPct = Math.round(TenantsHealthy / TenantCount * 100);

  const parseTimestamp = (timestampStr) => {
    return new Date(new Date(timestampStr).getTime() + (new Date(timestampStr).getTimezoneOffset() * 60 * 1000));
  };

  useEffect(() => {
      const setTime = function(startDate) {
      const minutes = Math.floor((parseTimestamp(Date.now()) - (new Date(startDate)).getTime() ) / 60 / 1000);
      if (!minutes){
        setLastDataText('Less Than a Minute')
      } else if (minutes >= 60) {
        setLastDataText('More Than an Hour')
      } else {
        setLastDataText(`${minutes} Minute${minutes > 1 ? 's' : ''}`)
      }
  }
    setTime(LastDataAcquired)
  }, [LastDataAcquired]);

  return (
    <div className='flex-spread' >
    {TenantsHealthy  !== undefined ? (
      <DashCard
        title='Tenant Health'
        main={`${healthyPct}% Healthy`}
        subtext = {`${TenantsHealthy} of ${TenantCount} tenants are currently healthy`}
        subStyle = {'dashcard-subtext' + (healthyPct < 80 ? ' dc-red' : '')}
      />
    ) :  null}

    { ResourcesUnderAutomation !== undefined ? (
      <DashCard
        title='Automation Status'
        main={`${ResourcesUnderAutomation} Resources Automated`}
        subtext = {`out of ${ResourceCount} resources`}
        subStyle = {'dashcard-subtext'}
      />
    ) :  null}

    { UserCount  !== undefined ? (
      <DashCard
        title='User Count'
        main={`${UserCount} Users`}
        subtext ='from your organization on StackBoss'
        subStyle = {'dashcard-subtext dc-red'}
      />
        ) : <DashCard main=' ' />}

    { LastDataAcquired  !== undefined ? (
      <DashCard
        title='Latest Data Acquired'
        main={lastDataText}
        subtext ='since Latest Data Acquired'
        subStyle = {'dashcard-subtext'}
      />
        ) : null}

    { AutomationsPerformed  !== undefined ? (
      <DashCard
        title='Automations Performed'
        main={AutomationsPerformed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        subtext ='Automations Performed'
        subStyle = {'dashcard-subtext'}
      />
        ) : null}

    { DataPointsProcessed  !== undefined ? (
      <DashCard
        title='Data Points Processed'
        main={DataPointsProcessed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        subtext ='Data Points Processed'
        subStyle = {'dashcard-subtext'}
      />
        ) : null}

    { !!IdentifiedSavings ? (
      <DashCard
        title='Identified Savings'
        main={'$' + IdentifiedSavings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        subtext = {`Identified Savings in ${RecommendationsWaiting} Recommendations`}
        subStyle = {'dashcard-subtext'}
      />
        ) : null }
    </div>
  )}



  const DashChartContainer = function ({dashStats}) {
    const {
      NewResources,
      NewMetrics,
    } = dashStats;
    return (
      <div className='your-profile-bg dashchart'>
        <div className='dashcard-title'>{`New Resources & Metrics`} </div>
        <DashChart metrics={NewMetrics} resources={NewResources} />
      </div>
    )
  }

export default Dashboard;