import React from 'react';
import { Container, Table, Alert } from 'react-bootstrap';
import {useFetch} from '../common/utils';
import Loading from '../common/loadingLogo'

const AutomationLogs = ({query, view }) => {
  let searchParams = '';
  if (query) {
    if (query[0] === '?') {
      searchParams = query;
    } else {
      searchParams = '?resourceId=' + query;
    }
  }

  const { response, error, isLoading } = useFetch('api/v1/log/automation' + searchParams);

  const styles = {
    container: {},
    table: {
      color: 'white'
    },
  }

  if (view === 'modal') {
    styles.container.padding = '3px';
    styles.table.marginLeft = '4px';
    styles.table.marginRight = '4px'
  }

  React.useEffect(() => {
    if (view !== 'modal') window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },[view])

  if (isLoading) return (
        <Loading />
  )

  if (error) return (
    <Alert variant='danger'>Error: {error.statusText} </Alert>
  )

  return (
    <Container className="fade-in fontsize-p85 page-main-container" fluid style={styles.container}>
      <div className='your-profile-bg' style={styles.table}>
        <Table responsive='sm' variant='dark'>
          <thead style={{textAlign: 'left'}}>
            <tr>
              <th>Resource</th>
              <th>When</th>
              <th>Prediction Method</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!response.Data.length && (
              <tr>
                <td colSpan='4' >
                  <h4 >No automation logs right now, check back later.</h4>
                </td>
              </tr>
            )}

            {!!response.Data.length && response.Data.map(rec => (
              <tr key={rec.ResourceId}>
                <td className='break-word' style={{color:'white'}} title={rec.ResourceId}>{rec.FriendlyName}</td>
                <td>{rec.When}</td>
                <td>{rec.PredictionMethod}</td>
                <td  style={{color:'white'}}>{rec.Action}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>

  )
}

export default AutomationLogs;