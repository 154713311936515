import React, { useState } from 'react';
import { Modal, Container, Col, Row, Form, Spinner, Alert } from 'react-bootstrap';
import StdButton from '../common/stdButton';
import { updateAutomation } from '../common/utils';

export default function AutomationModal({resource, show, closeModal, query, updateResources, updateChildren}) {

  //const {ResourceName, AzureResource} = resource;
  const [automation, setAutomation] = useState(resource.Automated);
  const [time, setTime] = useState(resource.MaintenanceWindow);
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState('')

  const handleShow= () => {
    setAutomation(resource.Automated);
    setTime(resource.MaintenanceWindow);
    setFinish('')
  }

  const submitHandler = () => {
    setLoading(true);
    setFinish('')
    let putQuery = query + '?';
    if (automation < 2) putQuery += 'automated=' + automation;
    if (automation < 2 && time) putQuery += '&'
    if (time) putQuery += 'maintenanceWindow=' + time;
    updateAutomation(putQuery)
      .then(resp => {
        setLoading(false);
        setFinish('success');
      })
      .then(async () => {
        if (automation === 2) return null;
        if(updateResources) updateResources();
      })
      .then(() => {
        if(updateChildren) updateChildren()
        setTimeout(() => {closeModal(); setFinish('')}, 2500)
      })
      .catch(err => {
        setLoading(false)
        setFinish('error')
        console.error(err)
      })
  }

  const handleClose = () => {
    setFinish('');
    setTime(resource.MaintenanceWindow);
    setAutomation(resource.Automated);
    closeModal();
  }

  if (!resource || !Object.entries(resource).length) return null;

  return (
    <Modal
      show={show}
      onHide={closeModal}
      onShow={handleShow}
      className='dark-modal'
      centered
      >
      <Modal.Header closeButton className="dark-modal-header">
        Automation settings for {resource.SubscriptionName || resource.ResourceName || resource.textName}
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row xs={6} xl={20}>
            <Col xs={6}>
              <div><b>SET AUTOMATION STATE</b></div>
                <Form.Check
                  type="radio"
                  id="Automate"
                  label="Automate"
                  name="AutomationState"
                  checked={automation === 1}
                  onChange={() => {setAutomation(1)}}
                />
                <Form.Check
                  type="radio"
                  id="Reccomend"
                  label="Recommend Only"
                  name="AutomationState"
                  checked={automation === 0}
                  onChange={() => {setAutomation(0)}}
                />
                <Form.Check
                  type="radio"
                  id="NoChange"
                  label="No Change"
                  name="AutomationState"
                  checked={automation === 2}
                  onChange={() => {setAutomation(2)}}
                />
            </Col >
            <Col xs={6}>
              <div className='ml-auto mb-auto' style={{maxWidth: '14rem'}}>
                <div><b>DAILY MAINTANENCE TIME</b></div>
                <Form.Control
                  className="timepicker"
                  as="select"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={null}>-- No Change --</option>
                  <option value={0}>00:00</option>
                  <option value={1}>01:00</option>
                  <option value={2}>02:00</option>
                  <option value={3}>03:00</option>
                  <option value={4}>04:00</option>
                  <option value={5}>05:00</option>
                  <option value={6}>06:00</option>
                  <option value={7}>07:00</option>
                  <option value={8}>08:00</option>
                  <option value={9}>09:00</option>
                  <option value={10}>10:00</option>
                  <option value={11}>11:00</option>
                  <option value={12}>12:00</option>
                  <option value={13}>13:00</option>
                  <option value={14}>14:00</option>
                  <option value={15}>15:00</option>
                  <option value={16}>16:00</option>
                  <option value={17}>17:00</option>
                  <option value={18}>18:00</option>
                  <option value={19}>19:00</option>
                  <option value={20}>20:00</option>
                  <option value={21}>21:00</option>
                  <option value={22}>22:00</option>
                  <option value={23}>23:00</option>
                </Form.Control>
                <div style={{fontSize: '.8rem'}}>The maintenance window is one hour starting at the time noted in UTC.</div>
              </div>
              <br/>
              <Row>
                <div className='ml-auto'>
                  <StdButton width='7em'
                    bgColor='rgba(255,255,255,0.15)'
                    onClick={submitHandler}
                    disabled={(automation === 2 && !time) || loading}
                  >{loading ? <Spinner /> : 'UPDATE'}                  </StdButton> &nbsp;&nbsp;
                  <StdButton  width='7em' onClick={handleClose}>CANCEL</StdButton>
                </div>
                {finish === 'error' && <Alert variant='danger'>There was an error in updating automation settings</Alert> }
                {finish === 'success' && <Alert variant='success'>Automation settings have been updated</Alert> }
              </Row>
            </Col>

          </Row>

          <br/>

        </Container>
      </Modal.Body>
    </Modal>
  )
}