import React from 'react';
import { VictoryContainer, VictoryLine, VictoryAxis, VictoryLabel} from 'victory';

const Chart = function({resource, metric, metricData}) {

  const styles = getStyles();
  let tickValues
  let maxDomain;
  let xDomain;
  let dataSetOne;

    if (metricData && metricData.length) {
      dataSetOne = getDataSetOne(metricData);
      //const dataSetTwo = getDataSetTwo();
      tickValues = getTickValues(metricData);
      maxDomain = metricData.reduce((a,b) => Math.max(a, b[1]), 0) * 1.2 || 1
      xDomain = [new Date(metricData[0][0]), new Date(metricData[metricData.length -1][0])]
    }

    return (
      <VictoryContainer className="fade-in">
        <svg style={styles.parent} viewBox="0 0 450 400">

          {/* Create stylistic elements */}
          <rect x="420" y="10" width="20" height="20" fill="#458ca8"/>

          {/* Define labels */}
          {/* <VictoryLabel x={25} y={24} style={styles.title}
            text={resource.ResourceName}
          /> */}
          <VictoryLabel x={430} y={20} style={styles.labelNumber}
            text="SB"
          />
          {/* <VictoryLabel x={25} y={55} style={styles.labelOne}
            text={metric.Metric}
          /> */}

          <g transform={"translate(0, 0)"}>
            {/* Add shared independent axis */}
            <VictoryAxis
              scale="time"
              standalone={false}
              style={styles.axisYears}
              tickValues={tickValues}
              tickFormat={
                (x) => {
                  if (x.getMinutes() !== 0) {
                    return x.toLocaleString('en-US', {timeStyle: 'short', hour12: 'false'});
                  } else {
                    if (x.getHours() > 12) return x.getHours() - 12 + 'PM';
                    if (x.getHours() === 12) return '12PM';
                    if (x.getHours() > 0) return x.getHours() + 'AM'
                    return '12AM'
                  }
                  // if (x.getFullYear() % 5 === 0) {
                  //   return x.getFullYear().toString().slice(2);
                  // }
                }
              }
            />

            {/*
              Add the dependent axis for the first data set.
              Note that all components plotted against this axis will have the same y domain
            */}
            <VictoryAxis dependentAxis
              domain={[0, maxDomain]}
              offsetX={50}
              orientation="left"
              standalone={false}
              style={styles.axisOne}
              tickFormat={
                (y) => {
                  if (y >= 1000000) {
                    return Math.round(y/1000000).toLocaleString() + 'M'
                  }
                  return y.toLocaleString() + (metric.Metric.indexOf('Percentage') > -1 ? '%' : '')
                }
              }
            />

            {/* Red annotation line */}
            {/* <VictoryLine
              data={[
                {x: 0, y: 0},
                {x: 0, y: 1200000}
              ]}
              domain={{
                x: xDomain,
                y: [0, 400000]
              }}
              scale={{x: "time", y: "linear"}}
              standalone={false}
              style={styles.lineThree}
            /> */}

            {/* dataset one */}
            <VictoryLine
              data={dataSetOne}
              domain={{
                x: xDomain,
                y: [0, maxDomain]
              }}
              interpolation="monotoneX"
              scale={{x: "time", y: "linear"}}
              standalone={false}
              style={styles.lineOne}
            />

            {/*
              Add the dependent axis for the second data set.
              Note that all components plotted against this axis will have the same y domain
            */}

          </g>
        </svg>
      </VictoryContainer>
    );
  }

  function getDataSetOne(data) {
    return data.map(a => {
      return {x: new Date(a[0]), y: Number(a[1])}
    })
  }

  // function getDataSetTwo() {
  //   return sampleData.map(a => {
  //     return {x: new Date(a[0]), y: a[2]}
  //   })
  // }

  function getTickValues(data) {
    const ticks = [new Date(data[0][0])]
    const lastTick = new Date(data[data.length - 1][0])
    const interval = Math.floor((lastTick.getTime() - ticks[0].getTime()) / 5)
    for (let i = 0; i < 4; i++) {
      let newTick = new Date();
      newTick.setTime(ticks[ticks.length - 1].getTime() + interval)
      ticks.push(newTick)
    }
    ticks.push(lastTick)
    return ticks;
  }

  function getStyles() {
    const BLUE_COLOR = "#00a3de";
    const RED_COLOR = "#7c270b";

    return {
      parent: {
        background: "rgba(54,56,60)",
        boxSizing: "border-box",
        display: "inline",
        padding: '0 20px',
        fontFamily: "'Fira Sans', sans-serif"
      },
      title: {
        textAnchor: "start",
        verticalAnchor: "end",
        fill: "white",
        fontFamily: "inherit",
        fontSize: "18px",
        fontWeight: "bold"
      },
      labelNumber: {
        textAnchor: "middle",
        fill: "#ffffff",
        fontFamily: "inherit",
        fontSize: "14px"
      },

      // INDEPENDENT AXIS
      axisYears: {
        axis: { stroke: "white", strokeWidth: 1},
        ticks: {
          size: ({ tick }) => {
            const tickSize =
              tick.getMinutes() % 9 === 0 ? 5 : 5;
            return tickSize;
          },
          stroke: "white",
          strokeWidth: 1
        },
        tickLabels: {
          fill: "white",
          fontFamily: "inherit",
          fontSize: 12,
        }
      },

      // DATA SET ONE
      axisOne: {
        grid: {
          stroke: ({ tick }) =>
            tick === -10 ? "transparent" : "#8B91A0",
          strokeWidth: 1
        },
        axis: { stroke: BLUE_COLOR, strokeWidth: 0 },
        ticks: { strokeWidth: 0 },
        tickLabels: {
          fill: "#8B91A0",
          fontFamily: "inherit",
          fontSize: 10
        }
      },
      labelOne: {
        fill: "#8B91A0",
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic"
      },
      lineOne: {
        data: { stroke: BLUE_COLOR, strokeWidth: 5 }
      },
      axisOneCustomLabel: {
        fill: BLUE_COLOR,
        fontFamily: "inherit",
        fontWeight: 300,
        fontSize: 21
      },

      // DATA SET TWO
      axisTwo: {
        axis: { stroke: RED_COLOR, strokeWidth: 0 },
        tickLabels: {
          fill: RED_COLOR,
          fontFamily: "inherit",
          fontSize: 10
        }
      },
      labelTwo: {
        textAnchor: "end",
        fill: RED_COLOR,
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic"
      },
      lineTwo: {
        data: { stroke: RED_COLOR, strokeWidth: 3 }
      },

      // VERTICAL LINE
      lineThree: {
        data: { stroke: "#e95f46", strokeWidth: 2 }
      }
    };

}

export default Chart;