import React, {useState} from 'react';
import { useHistory} from 'react-router-dom';
import StdButton from '../common/stdButton'
import { Form, OverlayTrigger, Popover, Alert, Spinner } from 'react-bootstrap';
import PwPopover from '../common/pwPopover';
import logout from '../../auth/jwtLogout';

var ChangePassword = function({setShowForm}) {

  const [oldPassword, setOldPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [logoutAll, setLogoutAll] = useState('false')
  const [meetsConditions, setMeetsConditions] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const setValues = {
    oldPassword: setOldPassword,
    password1: setPassword1,
    password2: setPassword2,
  }

  const handleChange = function(e) {
    const {name, value} = e.target;
    setValues[name](value)
 }

  const submitHandler = function(e) {
    e.preventDefault();
    setIsErr(false)
    setIsLoading(true);
    fetch(`/api/v1/customerprofile/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Connection: 'keep-alive',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        OldPassword: oldPassword,
        Password: password1,
    })
    })
      .then(response => {
        if (response.ok) return response.json()
        let err = new Error('Update Password Failed')
        err.status = response.status;
        err.statusText= response.statusText;
        throw err;
      })
      .then(data => {
        if (logoutAll) {
          fetch(`api.stackboss.com/api/v1/token/all`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Connection: 'keep-alive',
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
          })
        }
      })
      .then(response => {
        if (response.ok) return response.json()
        let err = new Error('Logout All Failed')
        err.status = response.status;
        err.statusText= response.statusText;
        throw err;
      })
      .then(data => {
        setIsLoading(false);
        logout()
        history.push('/login?pucomplete');
      })
      .catch(err => {
        console.log('fetch error', err, err.status, err.statusText);
        setIsErr(true);
        setIsLoading(false);
        if (err.status === 401) {
          logout()
          history.push('/login?expired');
        }
      })
  }

  return (
    <div className="fade-in center">
      <div className="justify-left pad-p5">
      <h6><b>Change Password</b></h6>
      </div>
    <Form onSubmit={submitHandler}  >
    <input
      type='text'
      hidden
      readOnly
      name='username'
      autoComplete='username'
      value={localStorage.getItem('email')}
      default={localStorage.getItem('email')}
    />
    <Form.Group className='margin-auto width-25em pad-p5'  controlId='formBasicOldPassword'>
      <Form.Label className='justify-left'><b>Current Password</b></Form.Label>
      <Form.Control onChange={handleChange}
        className='dark-input'
        autoComplete='new-password'
        name='oldPassword'
        value={oldPassword}
        type='password'
        required />
    </Form.Group>

      <OverlayTrigger trigger="focus" delay={{ show: 250, hide: 400 }} placement="top" overlay={
            <Popover id="password-popover">
              <Popover.Title>Your password should:</Popover.Title>
              <Popover.Content>
                <PwPopover password1={password1} setMeetsConditions={setMeetsConditions} />
            </Popover.Content>
          </Popover>
      } >
        <Form.Group  className='margin-auto width-25em pad-p5'  controlId='formBasicPassword'>
          <Form.Label className='justify-left'><b>New Password</b></Form.Label>

          <Form.Control onChange={handleChange}
            className='dark-input'
            autoComplete='new-password'
            name='password1'
            value={password1}
            type='password'
            placeholder='Password'
            required />
        </Form.Group>
      </OverlayTrigger>

    <Form.Group className='margin-auto width-25em pad-p5' controlId='formBasicPassword2'>
      <Form.Label className='justify-left'><b>Confirm New Password</b></Form.Label>
      <Form.Control onChange={handleChange}
        className='dark-input'
        autoComplete='new-password'
        name='password2'
        value={password2}
        type='password'
        placeholder='Confirm Password'
        required />
      {(password2.length > 0 && password1 !== password2 ) && (
        <Form.Text className="text-danger bg-light fade-in">
          Passwords do not match.
        </Form.Text>
      )}
    </Form.Group>

    <Form.Group className='margin-auto width-25em pad-p5' controlId="formBasicCheckbox">
    <Form.Check type="checkbox" value={logoutAll} label="Log out of all devices" onChange={() => setLogoutAll(!logoutAll)} />
  </Form.Group>

    {isErr && <Alert variant="danger">There was an error submitting your form. Please try again later.</Alert>}
    <div className="width-100 pad-p5 justify-right">
      <StdButton
        width="12em"
        bgColor="rgba(255,255,255,0.15)"
         className='login-submit-button
        font-weight-bold'
        disabled={isLoading || !meetsConditions || password1 !== password2}
        type="submit" > {isLoading ? <Spinner animation='border' /> : 'Submit'}</StdButton>
      <StdButton className='login-submit-button
      font-weight-bold'
        width="12em"
       onClick={() =>{setShowForm(false)}} >Cancel</StdButton>
       </div>
    </Form>

    </div>
  );
}

export default ChangePassword;