import { Button } from 'react-bootstrap';
import React from 'react';


const StdButton = function({children, borderColor, bgColor, fontColor, fontSize, width, height, onClick, disabled}) {
  return(
              <Button className='font-weight-bold'
              onClick={onClick}
              disabled = {disabled}
          style={{
            backgroundColor: bgColor || '#26C1DB',
            boxShadow: '0 1px 3px 0 rgba(0,0,0,0.06)',
            borderRadius: '4px',
            border: '1px solid ' + ( borderColor || '#444444'),
            color:  fontColor || 'white',
            fontSize: fontSize ? fontSize : '1em' ,
            fontWeight: 'bold',
            minWidth: width || '85%',
            height: height || '3em',
            padding: '2px 4px',
            margin: '.5em .25em .5em',}}
          type="submit" >
            {children}
          </Button>

  )
}

export default StdButton;