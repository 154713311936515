import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import logout from '../../../auth/jwtLogout';
import { getMetrics } from '../../common/utils'
import { Spinner, Card } from 'react-bootstrap';
import Chart from './Chart';

const ChartCard = function({resource, metric, index, completed, updateComplete}) {

  const [metricData, setMetricData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(index < 4 ? false : true)
  const [noData, setNoData] = useState(true);
  const [selected, setSelected] = useState(false)
  const history = useHistory();


  useEffect(() => {
    if (!waiting) return;
    let notLoaded = 0;
    let loaded = 0;
    let prior = index - 1;
    while (prior >= 0) {
      if (completed[prior] === -1) {
        notLoaded ++;
      } else {
        loaded += completed[prior];
        if (loaded > 15 ) setWaiting(false);
      }
      prior --;
    }
    if (notLoaded < 5) setWaiting(false);
  }, [completed, index, waiting])

  useEffect(() => {

    if (waiting) return;
    if (!metric || metric.MetricId === '') {
      updateComplete({type: 'addZero', index: index});
      return;
    }
      setLoading(true)
      setNoData(false)
      getMetrics(metric.MetricId)
        .then(resp => {
          if (!resp || !Object.entries(resp).length) throw new Error('No Data for ' + metric.Metric)
          setMetricData([...Object.entries(resp)]);
          updateComplete({type: 'add', index: index});
        })
        .catch(err => {
          if (err.status) {
            console.error(err, err.status, err.statusText);
          } else {
            console.log(err)
          }
          setNoData(true);
          updateComplete({type: 'addZero', index: index});
          if (err.status === 401) {
            logout();
            history.push('/login?expired')
          }
        }).finally(() => setLoading(false))
  },[waiting, history, index, metric, updateComplete])



  let width = selected ? '90%' : '22em';
  let height = selected ? '30rem' : '20em';
  let position = selected ? 'absolute' : 'static';
  let zIndex = selected ? '99' : '';
  let fontSize = selected ? '1rem' : '.6rem';

  if (isLoading) return (
    <Card body className="bg-dark text-white" style={{ width: '22em', height: '20em', margin: '.3rem'}} >
      <Card.Title as="h6">
        {/* {metric.Metric} */}
      </Card.Title>
      <Card.Text as="div" style={{display: 'grid', minHeight: '175px', placeItems: 'center'}}>
          <Spinner animation="border" variant="primary" size="100%" />
      </Card.Text>

    </Card>
  )

  if (noData) return null;

  if (!noData)
  return (
    <Card  className="bg-dark text-white" body as="div"
      style={{ width: width, height: height, margin: '.3rem', transition: 'all .3s',  zIndex: zIndex, position: position}} onClick={() => setSelected(!selected)}>
        <div style={{position: 'absolute', opacity: '0', width: 'span', height:'span'}}></div>
      <Card.Title as="div" style={{fontSize: fontSize}}>
        {metric.Metric}
      </Card.Title>
        <Chart metric={metric} resource={resource} metricData={metricData}/>

    </Card>
  )
}

export default ChartCard;