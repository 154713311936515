import React from 'react';
import { Button } from 'react-bootstrap';

var SmButton = function(props) {
  return (
    <Button
      {...props}
        style = {{
          color: 'white',
          minWidth: '4em'
      }}
      className='margin5'
      size='sm' />

  )
}

export default SmButton;