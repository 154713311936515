import React from 'react';
import { Route, Switch, Link, useLocation, useHistory } from 'react-router-dom';
import {Container, Col, Row} from 'react-bootstrap'
import Login from './login.js';
import Recover from './recover';
import RecoverPassword from './recoverPassword';
import SignUp from './signup.js';
import Start from './start.js';
import SuperCard from '../SuperCard';
import Verify from '../Verify';

function Home() {

  const history = useHistory();
  if (localStorage.getItem('name') !== null) history.push('/home');
  const location = useLocation();
  let isWide = ['/verify'].includes(location.pathname)
  let rowXS, rowMD, rowLG, rowXL, colXS, colMD, colLG, colXL;
  if (isWide ) {
    rowXS = 20
    rowMD = 12
    rowLG = 11
    rowXL = 10
    colXS = 18
    colMD = 10
    colLG = 9
    colXL = 8
  } else {
    rowXS = 14
    rowMD = 12
    rowLG = 9
    rowXL = 8
    colXS = 12
    colMD = 10
    colLG = 7
    colXL = 6
  }

  return (
    <Container className={isWide ? 'home-container-lg' : 'home-container'}>
      <Row xs={rowXS} md={rowMD} lg={rowLG} xl={rowXL}>
      <Col sm={true} />
      <Col xs={colXS} md={colMD} lg={colLG} xl={colXL}>
             <Link to='/'><img src='stackboss_logo_wb.png' alt='StackBoss' width="250vw"/></Link>
        <SuperCard className={isWide ? "wide-form" : ""}>
          <Switch>
            <Route exact path='/'>
              <Start />
            </Route>
            <Route path={`/login`}>
              <Login />
            </Route>
            <Route path={`/recover`}>
              <Recover />
            </Route>
            <Route path={`/ResetPassword`}>
              <RecoverPassword />
            </Route>
            <Route path={`/signup`}>
              <SignUp />
            </Route>
            <Route path={`/verify`}>
              <Verify />
            </Route>
          </Switch>
        </SuperCard>
      </Col>
      <Col sm={true} />
      </Row>
    </Container>
  );
}

export default Home;
