import React, {useState} from 'react';
import { StartButton } from './start';
import { Form, Spinner, Alert } from 'react-bootstrap';

var Recover = function() {

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(<div/>);

  const [success, setSuccess] = useState(false);

  const testResponses = function(emaddr) {
    switch (emaddr) {
      case 'sbtest@test.com':
        setSuccess(true);
        break;
      case 'sbtest400@test.com':
        setAlert(<Alert400 />);
        break;
      default:
        setAlert(<AlertMisc/>);
    }
    setIsLoading(false);
  }

  const submitHandler = function(e) {
    e.preventDefault();

    setAlert(<div/>);
    setIsLoading(true);

    if (email.substring(0,6) === 'sbtest') {
      testResponses(email);
      return;
    }

    fetch(`/api/v1/Account/SendReset`, { //url taken from jira stub
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json',
        'Connection': 'keep-alive'
      },
      body: JSON.stringify({Email: email})
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        throw response;
      })
      .then(data => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch(err => {
        console.log('fetch error', err.status, err.statusText);
        if (err.status === 400) {
          setAlert(<Alert400 />);
        } else {
          setAlert(<AlertMisc />);
        }
        setIsLoading(false);
      })
  }

  if (success) return (
    <div className='fade-in max350'>
      <div className='signup-header'>Thank you!</div>
      <br/>
      <h5>If your email is associated with a StackBoss account you should receive an email shortly with a link to reset your password.</h5>
      <br/>
      <h5>Please use the link to reset the password for your account.</h5>
    </div>
  )

  return (
    <div  className='max350 fade-in'>
      <div className='signup-header'>Enter your email below to reset your password.</div>
      <Form onSubmit={submitHandler} style={{margin: '2em 0 0 0'}}>
        <Form.Group controlId='formBasicEmail'  style={{width: '85%', margin: 'auto'}} >
          <Form.Label className='justify-left'>Email Address</Form.Label>
          <Form.Control
            className='dark-input'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type='email'
            placeholder='Enter your work email here'
            required
            autoComplete='username' />
        </Form.Group>

        {alert}

        <StartButton
          disabled={isLoading}
          type="submit" > {isLoading ? <Spinner animation='border' /> : 'Submit'}
        </StartButton>
      </Form>
      <br/>
    </div>
  );
}


function AlertMisc() {
  return (
    <Alert variant='danger' >
      We encountered an error, please try again later.
    </Alert>
  )
}

function Alert400() {
  return (
    <Alert variant='danger' >
       We encountered an error, please try again later.
    </Alert>
  )
}


export default Recover;