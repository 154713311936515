import React, { useState, useEffect } from 'react';
import { StartButton } from './start';
import { Form, Spinner, Alert, OverlayTrigger, Popover} from 'react-bootstrap';
import { useHistory, useLocation, Link} from 'react-router-dom';
import PwPopover from '../common/pwPopover';
import logout from '../../auth/jwtLogout'

var PasswordForm = function(props) {
  if (localStorage.getItem('name')) logout()
  const [display, setDisplay] = useState('loading');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [meetsConditions, setMeetsConditions] = useState(false);

  const [isErr, setIsErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const setValues = {
    password1: setPassword1,
    password2: setPassword2,
  }

  const location = useLocation();
  const params = {};
  location.search.substring(1).split('&').forEach(a => {
    let b = a.split('=');
    if (b[1]) params[b[0]] = b[1].replace('%40','@');
  });

  useEffect(() => {
    if (!params.code || !params.email)  {
      setDisplay('noQuery');
      return;
    };
    if (params.code.substring(0,4) === 'test') {
      const testStatus = params.code.substring(4)
      switch (testStatus) {
        case '200':
          setDisplay('passwordForm');
          break;
        case '409':
          setDisplay('alreadyComplete');
          break;
        case '202':
          setDisplay('resendEmail');
          break;
        case '401':
          setDisplay('wrongAddress');
          break;
        default:
          setDisplay('generalError');
      }
      return
    }
    let status, statusText;
    fetch(`/api/v1/Account/ValidateCode?email=${params.email}&code=${params.code}`, {
      method: 'GET',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Connection': 'keep-alive'
      },
    })
      .then(response => {
        status = response.status;
        statusText = response.statusText;
        return response.json()
      })
      .then(data => {
        if (status >= 400) throw data;
        if (data.Validated) {
          setDisplay('passwordForm')
        } else {
          setDisplay('invalid')
        }
        setIsLoading(false)
      })
      .catch(err => {
        console.error('fetch error', status, statusText, err.error);
        setDisplay('invalid')
      })
  }, [params.code, params.email])





    const submitHandler = function(e) {
      e.preventDefault();
      setIsErr(false)
      setIsLoading(true);

      if (params.code.substring(0,4) === 'test') {
        console.log('test submitted', {
          "code": params.code,
          "email": params.email,
        "password": password1,

      })
        return;
      }
      let status, statusText;
      fetch(`/api/v1/Account/UpdatePassword`, {
        method: 'PUT',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Content-Type': 'application/json',
          'Connection': 'keep-alive'
        },
        body: JSON.stringify({
          "Code": params.code,
          "Email": params.email,
          "Password": password1,
      })
      })
        .then(response => response.json())
        .then(data => {
          if (status >= 400) throw data;
          return data;
        })
        .then(data => {
          setIsLoading(false);
          history.push('/login?pucomplete');
        })
        .catch(err => {
          console.log('fetch error', err, status, statusText);
          setIsErr(true);
          setIsLoading(false);
        })
    }

  const handleChange = function(e) {
      const {name, value} = e.target;
      setValues[name](value)
   }

  return (
    <div>
      {display === 'loading' && <Spinner animation="border" role="status" variant="light"/>}

      {display === 'noQuery' && <h4>Nothing to See Here.</h4>}

      {display === 'invalid' && <div className='signup-header'>The link you clicked on was expired or invalid. Please check the link or <Link style={{color:'red'}} to='/recover'>click here</Link> to request a new password reset email.</div>}

      {display === 'passwordForm' && (
        <>
        <div className='signup-header'>Please enter your new password below to access your account.</div>

    <Form onSubmit={submitHandler} style={{margin: '2em 0 0 0'}} >
      <input
        type='text'
        hidden
        readOnly
        name='username'
        autoComplete='username'
        value={params.email}
        default={params.email}
      />
        <OverlayTrigger trigger="focus" delay={{ show: 250, hide: 400 }} placement="top" overlay={
              <Popover id="password-popover">
                <Popover.Title>Your password should:</Popover.Title>
                <Popover.Content>
                  <PwPopover password1={password1} setMeetsConditions={setMeetsConditions} />
              </Popover.Content>
            </Popover>
        } >
          <Form.Group  controlId='formBasicPassword'  style={{width: '85%', margin: '1em auto'}}>
            <Form.Label className='justify-left'>Password (Required)</Form.Label>

            <Form.Control onChange={handleChange}
              className='dark-input'
              autoComplete='new-password'
              name='password1'
              value={password1}
              type='password'
              placeholder='Password'
              required />
          </Form.Group>
        </OverlayTrigger>

      <Form.Group controlId='formBasicPassword2'  style={{width: '85%', margin: '1em auto'}}>
        <Form.Label  className='justify-left'>Confirm Password (Required)</Form.Label>
        <Form.Control onChange={handleChange}
          className='dark-input'
          autoComplete='new-password'
          name='password2'
          value={password2}
          type='password'
          placeholder='Confirm Password'
          required />
        {(password2.length > 0 && password1 !== password2 ) && (
          <Form.Text className="text-danger bg-light fade-in">
            Passwords do not match.
          </Form.Text>
        )}
      </Form.Group>


      {isErr && <Alert variant="danger">There was an error submitting your form. Please request a new password reset link.</Alert>}
      <StartButton
      disabled={isLoading || !meetsConditions || password1 !== password2}
      type="submit" > {isLoading ? <Spinner animation='border' /> : 'Submit'}</StartButton>
      </Form>

          </>
      )}

    </div>

);
}

export default PasswordForm;