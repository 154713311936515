import React from 'react';
import { Container } from 'react-bootstrap';
import { VictoryLine, VictoryAxis, VictoryLabel} from 'victory';
import sampleData from './sampleData';

const Sandbox = function() {

  const styles = getStyles();
    const dataSetOne = getDataSetOne();
    const dataSetTwo = getDataSetTwo();
    const tickValues = getTickValues();

    const xDomain = [new Date(sampleData[0][0]), new Date(sampleData[sampleData.length -1][0])]

    return (
      <Container style={{maxHeight: '400px'}}>
      <svg style={styles.parent} viewBox="0 0 450 350">

        {/* Create stylistic elements */}
        <rect x="0" y="0" width="10" height="30" fill="#f01616"/>
        <rect x="420" y="10" width="20" height="20" fill="#458ca8"/>

        {/* Define labels */}
        <VictoryLabel x={25} y={24} style={styles.title}
          text="StackBoss Chart Example"
        />
        <VictoryLabel x={430} y={20} style={styles.labelNumber}
          text="SB"
        />
        <VictoryLabel x={25} y={55} style={styles.labelOne}
          text={"OS Disk Read IOPs/sec"}
        />
        <VictoryLabel x={425} y={55} style={styles.labelTwo}
          text={"OS Disk Write IOPs/sec"}
        />

        <g transform={"translate(0, 40)"}>
          {/* Add shared independent axis */}
          <VictoryAxis
            scale="time"
            standalone={false}
            style={styles.axisYears}
            tickValues={tickValues}
            tickFormat={
              (x) => {
                if (x.getFullYear() !== 0) {
                  return x.toLocaleString('en-US', {timeStyle: 'short', hour12: 'false'});
                }
                // if (x.getFullYear() % 5 === 0) {
                //   return x.getFullYear().toString().slice(2);
                // }
              }
            }
          />

          {/*
            Add the dependent axis for the first data set.
            Note that all components plotted against this axis will have the same y domain
          */}
          <VictoryAxis dependentAxis
            domain={[0, 400000]}
            offsetX={50}
            orientation="left"
            standalone={false}
            style={styles.axisOne}
          />

          {/* Red annotation line */}
          <VictoryLine
            data={[
              {x: 0, y: 0},
              {x: 0, y: 1200000}
            ]}
            domain={{
              x: xDomain,
              y: [0, 400000]
            }}
            scale={{x: "time", y: "linear"}}
            standalone={false}
            style={styles.lineThree}
          />

          {/* dataset one */}
          <VictoryLine
            data={dataSetOne}
            domain={{
              x: xDomain,
              y: [0, 400000]
            }}
            interpolation="monotoneX"
            scale={{x: "time", y: "linear"}}
            standalone={false}
            style={styles.lineOne}
          />

          {/*
            Add the dependent axis for the second data set.
            Note that all components plotted against this axis will have the same y domain
          */}
          <VictoryAxis dependentAxis
            domain={[0, 1200000]}
            orientation="right"
            standalone={false}
            style={styles.axisTwo}
          />

          {/* dataset two */}
          <VictoryLine
            data={dataSetTwo}
            domain={{
              x: xDomain,
              y: [0, 1200000]
            }}
            interpolation="monotoneX"
            scale={{x: "time", y: "linear"}}
            standalone={false}
            style={styles.lineTwo}
          />
        </g>
      </svg>
      </Container>
    );
  }

  function getDataSetOne() {
    return sampleData.map(a => {
      return {x: new Date(a[0]), y: a[1]}
    })
  }

  function getDataSetTwo() {
    return sampleData.map(a => {
      return {x: new Date(a[0]), y: a[2]}
    })
  }

  function getTickValues() {
    const ticks = []
    sampleData.forEach((a,i) => {
      if (i % Math.floor(sampleData.length / 6) === 0) {
        ticks.push(new Date (a[0]))
      }
    })
    return ticks;
  }

  function getStyles() {
    const BLUE_COLOR = "#00a3de";
    const RED_COLOR = "#7c270b";

    return {
      parent: {
        background: "#ccdee8",
        boxSizing: "border-box",
        display: "inline",
        padding: '0 20px',
        fontFamily: "'Fira Sans', sans-serif"
      },
      title: {
        textAnchor: "start",
        verticalAnchor: "end",
        fill: "#000000",
        fontFamily: "inherit",
        fontSize: "18px",
        fontWeight: "bold"
      },
      labelNumber: {
        textAnchor: "middle",
        fill: "#ffffff",
        fontFamily: "inherit",
        fontSize: "14px"
      },

      // INDEPENDENT AXIS
      axisYears: {
        axis: { stroke: "black", strokeWidth: 1},
        ticks: {
          size: ({ tick }) => {
            const tickSize =
              tick.getMinutes() % 9 === 0 ? 5 : 5;
            return tickSize;
          },
          stroke: "black",
          strokeWidth: 1
        },
        tickLabels: {
          fill: "black",
          fontFamily: "inherit",
          fontSize: 12,
        }
      },

      // DATA SET ONE
      axisOne: {
        grid: {
          stroke: ({ tick }) =>
            tick === -10 ? "transparent" : "#ffffff",
          strokeWidth: 2
        },
        axis: { stroke: BLUE_COLOR, strokeWidth: 0 },
        ticks: { strokeWidth: 0 },
        tickLabels: {
          fill: BLUE_COLOR,
          fontFamily: "inherit",
          fontSize: 10
        }
      },
      labelOne: {
        fill: BLUE_COLOR,
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic"
      },
      lineOne: {
        data: { stroke: BLUE_COLOR, strokeWidth: 3 }
      },
      axisOneCustomLabel: {
        fill: BLUE_COLOR,
        fontFamily: "inherit",
        fontWeight: 300,
        fontSize: 21
      },

      // DATA SET TWO
      axisTwo: {
        axis: { stroke: RED_COLOR, strokeWidth: 0 },
        tickLabels: {
          fill: RED_COLOR,
          fontFamily: "inherit",
          fontSize: 10
        }
      },
      labelTwo: {
        textAnchor: "end",
        fill: RED_COLOR,
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic"
      },
      lineTwo: {
        data: { stroke: RED_COLOR, strokeWidth: 3 }
      },

      // VERTICAL LINE
      lineThree: {
        data: { stroke: "#e95f46", strokeWidth: 2 }
      }
    };

}

export default Sandbox;