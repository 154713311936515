import React, {useState} from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Form, Spinner, Alert } from 'react-bootstrap';
import { StartButton } from './start';
import jwtLogin from '../../auth/jwtLogin.js'

var Login = function() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const history = useHistory();
  const path  = useLocation();

  const submitHandler = function(e) {
    const data = JSON.stringify({emailAddress: email, password: password})
    e.preventDefault();
    setIsLoading(true);
    fetch(`/api/v1/token`, {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'application/json',
        'Connection': 'keep-alive'
      },
      body: data
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        setErrMessage('The email address and password you entered did not match our records. Please try again.');
        throw new Error('loginError');
      })
      .then(data => {
        setIsLoading(false);
        let token = data.slice(1,-1)
        jwtLogin(token);
        history.push(path?.state?.returnTo || '/home');
      })
      .catch(err => {
        console.log(err)
        setErrMessage('The email address and password you entered did not match our records. Please try again.');
        setIsLoading(false);
      })
  }

  let headerMessage = {
    '?sucomplete': 'Your account has been created, please login to continue.',
    '?expired': 'Your session has expired, please login to continue.',
    '?pucomplete': 'Your password has been updated, please login to continue.',
  }

  const headerMessageDefault = 'Enter your email and password to login.'

  const darkInputStyle = (
    <style type="text/css">
    {`
    .form-control-dark {
          border: 1px solid #595959 !important;
    border-radius: 5px !important;
    backgroun-color: rgba(255,255,255,0.07) !important;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.06) !important;
    color: rgb(255,255,255,.5) !important;
    }
    `}
  </style>)

  return (
    <div className='max350 fade-in'>

      <div className='signup-header'>{headerMessage[path.search] || headerMessageDefault}</div>
      {darkInputStyle}
      <Form onSubmit={submitHandler} style={{margin: '1em 0 0 0'}}>

        <Form.Group controlId='formBasicEmail' style={{width: '85%', margin: '1em auto'}} >
          <Form.Label className='justify-left'>Email Address</Form.Label>
          <Form.Control
            className='dark-input'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type='email'
            placeholder='Enter email'
            required
            autoComplete='username' />
        </Form.Group>

        <Form.Group controlId='formBasicPassword' style={{width: '85%', margin: 'auto'}} >
          <Form.Label className='justify-left'>Password</Form.Label>
          <Form.Control onChange={(e) => setPassword(e.target.value)}
              className='dark-input'
              autoComplete='current-password'
              name='password1'
              value={password}
              type='password'
              placeholder='Password'
            required />
            <Form.Text

              style={{width:'100%', display: 'flex', justifyContent: 'space-between'}}>
                <Link className="white-link" to="./recover">
                  <b>Forgot Password?</b>
                </Link>
                <Link className="white-link" to="./signup">
                  <b>Sign Up</b>
                </Link>
            </Form.Text>
        </Form.Group>
        {errMessage.length > 0 && <Alert variant='danger' >{errMessage}</Alert> }
        <StartButton
        disabled={isLoading}
        type="submit" > {isLoading ? <Spinner animation='border' /> : 'Submit'}</StartButton>

      </Form>
    </div>
  );
}

export default Login;