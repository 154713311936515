import React from 'react'


const DashCard = function ({title, main, subtext, subStyle}) {
  return(
      <div className='dashcard-bg'>
        <div className='dashcard-title'>{title}</div>
        <div className='ruler-div' />
        <div className='dashcard-main '>{main}</div>
        <div className={subStyle}>{subtext}</div>
    </div>
  )
}

export default DashCard;