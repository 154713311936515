import React, {useState, useEffect} from 'react';
import  {Spinner, Container } from 'react-bootstrap';
import ChartCard from './ChartCard.js';
import { useHistory } from 'react-router-dom'
import { getMetricsList} from '../../common/utils'
import logout from '../../../auth/jwtLogout'

var ChartPanel =  function({ resource, closePanel }) {
  const [metrics, setMetrics] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const placeContent = isLoading ? 'center' : 'start center'
  const history = useHistory();

  const updateComplete = function (state, action) {
    let newArray;
    switch (action.type) {
      case 'add':
        newArray = [...state.completed]
        newArray[action.index] = 1;
        return {completed: newArray}
      case 'addZero':
        newArray = [...state.completed]
        newArray[action.index] = 0;
        return {completed: newArray}
      case 'set':
        console.log(new Array(action.length).fill(-1))
        return {completed: new Array(action.length).fill(-1)}

      case 'reset':
        return {completed: []}
      default:
        throw new Error();
    }

  }
  const [state, setCompleted] = React.useReducer(updateComplete, {completed: []});

  useEffect(() => {
    if (resource) {
      getMetricsList(`?azureResource=${resource.metricQuery}`)
      .then(data => {
        const chartSet = new Set();
        const newData = [];
        data.forEach(metric => {
          if (!chartSet.has(metric.MetricId)) newData.push(metric);
          chartSet.add(metric.MetricId);
        })
        return newData;

      })
      .then(data => {
        setMetrics(data)
        setCompleted({type: 'set', length: data.length});
        setLoading(false);
      })
      .catch(err => {
        console.error(err.status, err.statusText, err)
        if (err.status === 401) {
          logout();
          history.push('/login?expired');
        }
      })
    }

  }, [resource, history])

  return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div className='break-word'> Metrics for {resource ? resource.name : 'Resource'} </div>
          <div onClick={closePanel}>CLOSE &#x2612;</div>
        </div>


            <Container className="scrollable-metrics" style={{display: 'flex', flexDirection: 'row', placeContent: placeContent, flexWrap: 'wrap'}}>
                  {isLoading && <Spinner animation="border" variant="primary" size="100%" />}
                  {!!state.completed.length && metrics.map((metric, index) => {
                    return <ChartCard index={index}  updateComplete={setCompleted} completed={state.completed} resource={resource} metric={metric} key={metric.MetricId} />
})}
                  {(!isLoading && ((metrics.length === state.completed.length  && Number(state.completed.join('')) === 0) || metrics.length === 0)) && (
                  <div className='center-items'>
                    <div>
                      <h5>This resource contains no metrics currently.</h5>
                      </div>
                  </div>)}
            </Container>
      </div>
  )
}

export default ChartPanel;
