import React, {useState} from 'react';
import { Container, Form, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { editProfile } from '../common/utils';
import lists from '../Verify/dropDownData';
import StdButton from '../common/stdButton'

var EditProfile = function(props) {

  const [isErr, setIsErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = function(e) {
    e.preventDefault();
    setIsErr(false)
    setIsLoading(true);
    const payload = {
      FirstName: props.firstName,
      LastName: props.lastName,
      Address1: props.address1,
      Address2: props.address2,
      City: props.city,
      State: props.state,
      Zipcode: props.zipcode,
      Country: props.country,
      CompanySize: props.companySize,
      AnnualRevenue: props.annualRevenue,
      Industry: props.industry,
    }
    editProfile(JSON.stringify(payload))
      .then(data => {
        props.updateProfile();
        props.setAlert('Your profile has been updated.')
        props.setShowForm(false)
        setIsLoading(false);
      })
      .catch(err => {
        console.log('fetch error', err, err.status, err.statusText);
        if(err.status === 401) {
          props.logout();
        }
        setIsErr(true);
        setIsLoading(false);
      })
  }


  return (

  <Container className="fade-in fontsize-p85" fluid>
    <div className="justify-left pad-p5">
      <h6><b>Edit Your Profile</b></h6>
    </div>

    <Form onSubmit={submitHandler} style={{margin: '3em 0 0 0'}}>
      <Row md={12}>
        <Col md={4} >

    <Form.Group controlId='formBasicFName'>
      <Form.Label className='justify-left'><b>First Name (Required)</b></Form.Label>
      <Form.Control onChange={(e) => props.setFirstName(e.target.value)}
        className='dark-input'
        autoComplete='given-name'
        name='firstName'
        value={props.firstName}
        type='given-name'
        placeholder='First Name'
        required />
    </Form.Group>

    <Form.Group controlId='formBasicLName'>
      <Form.Label className='justify-left'><b>Last Name  (Required)</b></Form.Label>
      <Form.Control onChange={(e) => props.setLastName(e.target.value)}
        className='dark-input'
        autoComplete='family-name'
        name='lastName'
        value={props.lastName}
        type='family-name'
        placeholder='Last Name'
        required />
    </Form.Group>

    <Form.Group controlId='formBasicStreet1'>
      <Form.Label className='justify-left'><b>Address</b></Form.Label>
      <Form.Control onChange={(e) => props.setAddress1(e.target.value)}
        className='dark-input'
        autoComplete='address-line1'
        name='address1'
        value={props.address1}
        type='address-line1'
        placeholder='Address'/>
    </Form.Group>

    <Form.Group controlId='formBasicStreet2'>
      <Form.Label className='justify-left'><b>Address (2nd line)</b></Form.Label>
      <Form.Control onChange={(e) => props.setAddress2(e.target.value)}
        className='dark-input'
        autoComplete='address-line2'
        name='address2'
        value={props.address2}
        type='address-line2'
        placeholder='Address (2nd line)' />
    </Form.Group>

    </Col>
    <Col md={4} >

    <Form.Group controlId='formBasicCity'>
      <Form.Label className='justify-left'><b>City</b></Form.Label>
      <Form.Control onChange={(e) => props.setCity(e.target.value)}
        className='dark-input'
        autoComplete='address-level2'
        name='city'
        value={props.city}
        type='address-level2'
        placeholder='City' />
    </Form.Group>

    <Form.Group controlId='formBasicState'>
      <Form.Label className='justify-left'><b>State / Province</b></Form.Label>
      <Form.Control onChange={(e) => props.setState(e.target.value)} as='select'
        custom
        className='dark-input'
        autoComplete='address=level1'
        name='state'
        value={props.state}
        type='address-level1' >
          <option value =''>--</option>
        {lists.states.map(state => (
          <option key={state.code} value={state.code}>{state.desc}</option>
        ))}
      </Form.Control>
    </Form.Group>

    <Form.Group controlId='formBasicZip'>
      <Form.Label className='justify-left'><b>Zip / Postal Code</b></Form.Label>
      <Form.Control onChange={(e) => props.setZipcode(e.target.value)}
        className='dark-input'
        autoComplete='postal-code'
        name='zip'
        value={props.zip}
        type='postal-code'
        placeholder='Zip / Postal Code' />
    </Form.Group>

    <Form.Group controlId='formBasicCountry'>
      <Form.Label className='justify-left'><b>Country</b></Form.Label>
      <Form.Control onChange={(e) => props.setCountry(e.target.value)} as='select'
        custom
        className='dark-input'
        autoComplete='country'
        name='country'
        value={props.country}
        type='address-level1' >
          <option value =''>--</option>
        {lists.countries.map(country => (
          <option key={country.code} value={country.code}>{country.desc}</option>
        ))}
      </Form.Control>
    </Form.Group>

    </Col>
    <Col md={4}>

    <Form.Group controlId='formBasicCompanySize'>
      <Form.Label className='justify-left'><b>Company Size (# of employees)</b></Form.Label>
      <Form.Control onChange={(e) => props.setCompanySize(e.target.value)} as='select'
        custom
        className='dark-input'
        name='companySize'
        value={props.companySize}
        type='text' >
          <option value =''>--</option>
        {lists.companySize.map(size => (
          <option key={size.code} value={size.desc}>{size.desc}</option>
        ))}
      </Form.Control>
    </Form.Group>

    <Form.Group controlId='formBasicRevenue'>
      <Form.Label className='justify-left'><b>Annual Revenue</b></Form.Label>
      <Form.Control onChange={(e) => props.setAnnualRevenue(e.target.value)} as='select'
        custom
        className='dark-input'
        name='annualRevenue'
        value={props.annualRevenue}
        type='text' >
          <option value =''>--</option>
        {lists.revenue.map(rev => (
          <option key={rev.code} value={rev.desc}>{rev.desc}</option>
        ))}
      </Form.Control>
    </Form.Group>

    <Form.Group controlId='formBasicIndustry'>
      <Form.Label className='justify-left'><b>Industry</b></Form.Label>
      <Form.Control  onChange={(e) => props.setIndustry(e.target.value)} as='select'
        custom
        className='dark-input'
        name='industry'
        value={props.industry}
        type='text' >
          <option value =''>--</option>
        {lists.industry.map(rev => (
          <option key={rev.code} value={rev.desc}>{rev.desc}</option>
        ))}
      </Form.Control>
    </Form.Group>


      </Col>
    </Row>
    {isErr && <Alert>There was an error submitting your form. Please Try again later.</Alert>}
    <div className="width-100 pad-p5 justify-right fontsize-p100">
      <StdButton className='login-submit-button
        font-weight-bold'
        width="12em"
        bgColor="rgba(255,255,255,0.15)"
        disabled={isLoading}
        type="submit" > {isLoading ? <Spinner animation='border' /> : 'Submit'}</StdButton>
      <StdButton className='login-submit-button
          font-weight-bold'
          width="12em"
          onClick={() => props.setShowForm(false)}
      > Cancel</StdButton>
    </div>

    </Form>

  </Container>

);
}

export default EditProfile;