import React, {useState} from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { debounce } from '../common/utils'
import { ReactComponent as SvgLoader } from '../common/svg/stackboss-loader.svg';
import ResourceTable from '../Dashboard/resourceTable';
import {useLogout} from '../../auth/jwtLogout';

var SearchModal = function({showModal, setModal}) {

  const [err, setErr] =  useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerms, setSearchTerms] = useState('');
  const [results, setResults] = useState([]);
  const searchCount = React.useRef(null);
  const inputRef = React.useRef(null);
  const logout = useLogout();

  const fetchSearch = function(searchString) {
    if (searchString === '') {
      setResults([]);
      setIsLoading(false);
      return;
    }
    searchCount.current ++;
    setIsLoading(true);
    fetch(`/api/v1/resource/find?partialResource=${searchString}`, {
      method: 'GET',
      headers: {
        Connection: 'keep-alive',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(data => {
        setResults(data?.Data || [])
        setIsLoading(false)
        //setModal(false);
      })
      .catch(err => {
        console.log('fetch error', err.status, err.statusText);
        setErr(err.statusText)
        setIsLoading(false);
        if (err.status === 401) {
              logout()
            }
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = React.useCallback(
		debounce(nextValue => fetchSearch(nextValue), 600),
		[], // will be created only once initially
	);

  const clearSearch = () => {
    setResults([]);
    setSearchTerms('')
    searchCount.current = 0;
    if(inputRef.current) inputRef.current.focus();
  }

  const search = function(e) {
    e.preventDefault();
    setSearchTerms(e.target.value);
    setIsLoading(true);
    setErr('');
    debouncedSearch(e.target.value)
  }

  return (
      <Modal centered show={showModal} onHide={() => setModal(false)} className='dark-modal' dialogClassName="fullscreen-modal" onShow={clearSearch}>
        <Modal.Header closeButton className="dark-modal-header">
          <input className='borderless-input' autofocus='true' value={searchTerms} placeholder='Search for resources by keyword' ref={inputRef} onChange={(e)=> {search(e)}} />
        </Modal.Header>
        <Modal.Body style={{color: 'white', justifyContent: 'center'}} className='search-modal-body'>
          {isLoading && (<div className="App-header"><div className="ic-Spin-cycle--classic" ><SvgLoader /></div></div>)}
          {(!isLoading && !!results.length) && <div className='your-profile-bg'>
            <ResourceTable resources={results} isSearchResult updateResources={()=> fetchSearch(searchTerms)} />
            </div>
          }
          {(!isLoading && !results.length && !!searchCount.current && !!searchTerms.length) && <Alert>No search results found for '{searchTerms}'</Alert>
          }
          {err !== '' && <Alert variant="warning">{err}</Alert> }
        </Modal.Body>
      </Modal>
  )
}

export default SearchModal;
