import React, {useState, useEffect} from 'react';
import {Nav} from "react-bootstrap";
import { Link, withRouter, useLocation } from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';

function SideBar({buttons}) {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname.substring(1) || 'home')


  useEffect(() => {
  setSelected(location.pathname.substring(1))
  }, [location.pathname])

  const externalButtons = [];
  buttons.forEach(button => {
    if (button.external) externalButtons.push(button.eventKey)
  });

  return (
    <React.Fragment>

      <Nav className="col-md-12 d-none d-md-block sidebar"
        activeKey="home"
        onSelect={selectedKey => {if(!externalButtons.includes(selectedKey)) setSelected(selectedKey)}}
      >
        <Link className='sidebar-logo' to='/'>
          <img src='stackboss_logo_wt.png' alt='StackBoss' width="200px"/>
        </Link>
        <div className="sidebar-sticky"></div>
        <div className="sidebar-subhead">MAIN PAGES</div>
        {buttons.map( button => {
          if (button.divider) {
            return <div className='divider-holder' key={button.name + '1'}>
              <div className='divider' />
            </div>
          }
          if (button.type === 'side') return (
          <Nav.Item key={button.name + '1'}>
              {!button.external && <LinkContainer to={button.link}>
                          <Nav.Link
                disabled={button.disabled}
                className={button.eventKey === selected ? 'selected' : ''}
                eventKey={button.eventKey}>
                <div className="sidebar-svg" style={{paddingLeft: (button.depth || 0) * 20 + 'px'}}>{button.svg}</div>
                {button.name}
              </Nav.Link>
              </LinkContainer>}
              {button.external && <Nav.Link href={button.link}
                disabled={button.disabled}
                rel='noopener noreferrer' target="_blank"
                className={button.eventKey === selected ? 'selected' : ''}
                eventKey={button.eventKey}>
                <div className="sidebar-svg" style={{paddingLeft: (button.depth || 0) * 20 + 'px'}}>{button.svg}</div>
                {button.name}
              </Nav.Link> }
          </Nav.Item>
          )
          return null;
        })}
      </Nav>
    </React.Fragment>

  )
}

export default withRouter(SideBar);