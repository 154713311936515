const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

const addToLocal = (payload) => {
  localStorage.setItem("email", payload.email);
  localStorage.setItem("name", payload.given_name)
  localStorage.setItem("exp", payload.exp)

}

const jwtLogin = (token) => {
  let payload = parseJwt(token);
  addToLocal(payload);
  localStorage.setItem('token',token);
}

export default jwtLogin;