import React from "react";
import { Container } from "react-bootstrap";
import { withRouter, Switch, Route } from "react-router-dom";
import SideBar from "./sideBar.js";
import NavBar from "./index.js";
import Profile from "../Profile";
import ManageTenants from "../Dashboard/manageTenants";
import AddTenant from "../Dashboard/addTenant";
import Dashboard from "../Dashboard";
import ManageUsers from "../ManageUsers";
import Recommendations from '../Recommendations';
import Pending from '../Pending';
import AutomationLogs from '../AutomationLogs';
import {useFetch, useScrollToTop} from '../common/utils';
import buttons from './buttons';
import { ReactComponent as SvgLoader } from '../common/svg/stackboss-loader.svg';


const TopAndSide = props => {

    const tenants = useFetch('api/v1/tenant');

    if (tenants.isLoading) return <Loading />

    return (
        <>
         <Container fluid className='page-main-container maxw100'>
            <NavBar buttons={buttons} />
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div id="sidebar-wrapper">
                    <SideBar buttons={buttons}/>
                </div>

                <div id="page-content-wrapper">
                    <Switch>
                        <Route exact path='/resources'>
                        <Manage tenants={tenants.response} refresh={tenants.fetchData} />
                        </Route>
                        <Route exact path='/home'>
                            <Dashboard tenants={tenants.response}/>
                        </Route>
                        <Route exact path='/automation'>
                            <ComingSoon/>
                        </Route>
                        <Route exact path='/analysis'>
                            <ComingSoon/>
                        </Route>
                        <Route exact path='/team'>
                            <ManageUsers/>
                        </Route>
                        <Route exact path='/security'>
                            <ComingSoon/>
                        </Route>
                        <Route exact path='/recommendations'>
                            <Recommendations/>
                        </Route>
                        <Route exact path='/pending'>
                            <Pending/>
                        </Route>
                        <Route exact path='/logs'>
                            <AutomationLogs/>
                        </Route>
                        <Route exact path='/pending'>
                            <ComingSoon/>
                        </Route>
                        <Route path={`/profile`}>
                        <Profile />
                        </Route>
                    </Switch>
                </div>
            </div>
            </Container>
        </>
        );
  };
  export default withRouter(TopAndSide)

  const Manage = function({tenants, refresh}) {
      useScrollToTop();
      return <Container fluid className='fade-in'>
        <AddTenant  refresh={refresh}/>
        <ManageTenants tenants={tenants} refresh={refresh}/>
      </Container>
  }

  const ComingSoon = function () {
    useScrollToTop();

      return (
          <div className="App-header fade-in">
            <h1><b>COMING SOON</b></h1>
          </div>
      )
  }

    const Loading = function () {
        //useScrollToTop();
      return (
          <div className="App-header">
            <h1><span className="ic-Spin-cycle--classic" style={{paddingTop: '50px'}}><SvgLoader/></span><span><b>Loading</b></span></h1>
          </div>
      )
  }

  export { Loading };