import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Start = function() {

  return (
    <div  className='max350 fade-in'>
      <div className='start-card-top'>
        <p></p>
        <h3><b>Unify.&nbsp;&nbsp; Automate.&nbsp;&nbsp; Optimize.</b></h3>
      </div>
      <div className='start-card-bottom'>
        <Link to='/login' style={{textDecoration: 'none', color: 'white'}}>
            <StartButton bgColor="#555555">LOG IN</StartButton>
        </Link>
        <Link to="/signup">
          <StartButton >GET STARTED</StartButton>
        </Link>

      </div>

    </div>

  )
}

const StartButton = function({children, borderColor, bgColor, fontColor, fontSize, width, disabled}) {
  return(
              <Button className='font-weight-bold'
          style={{
            backgroundColor: bgColor ? bgColor : '#26C1DB',
            boxShadow: '0 1px 3px 0 rgba(0,0,0,0.06)',
            borderRadius: '4px',
            border: '1px solid ' + (borderColor ? borderColor : '#444444'),
            color: fontColor ? fontColor : 'white',
            fontSize: fontSize ? fontSize : '1em' ,
            fontWeight: 'bold',
            width: '85%',
            height: '3em',
            margin: '.5em 0 .5em',}}
            disabled = {disabled}
          type="submit" >
            {children}
          </Button>

  )
}

export {StartButton};
export default Start;