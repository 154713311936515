import React, { useEffect, useState } from 'react';
import {useLocation, Link} from 'react-router-dom';
import PasswordForm from './passwordForm';
import { Spinner } from 'react-bootstrap';
import logout from '../../auth/jwtLogout'


var Verify = function() {
  if (localStorage.getItem('name')) logout()
  const [display, setDisplay] = useState('loading');
  const [username, setUsername] = useState('');

  const location = useLocation();
  const guid = location.search[0] === '?' ? location.search.substring(1) : '';

  useEffect(() => {
    if (!guid.length) {
      setDisplay('noQuery')
      return
    };
    if (guid.slice(-7,-3) === 'test') {
      const test = guid.slice(-3);
      switch (test) {
        case '200':
          setDisplay('passwordForm');
          setUsername('best@test.com');
          break;
        case '409':
          setDisplay('alreadyComplete');
          break;
        case '202':
          setDisplay('resendEmail');
          break;
        case '401':
          setDisplay('wrongAddress');
          break;
        default:
          setDisplay('generalError');
      }
      return
    }
    setDisplay('loading');
    fetch(`api/v1/emailaddress?guid=${guid}`, {
    method: 'GET',
    headers: {
      'Accept': '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
      'Connection': 'keep-alive'
    }
    })
    .then(response => {
      // took out separate 202 handling for resend email, currently 202 is returned on success
      if (response.ok /* && response.status !== 202 */) {
        return response.json();
      }
      throw response;
    })
    .then(data => {
      setDisplay('passwordForm');
      setUsername(data.EmailAddress)
    })
    .catch(err => {
      console.log('fetch error', err.status, err.statusText);
      if (err.status === 409) {
        setDisplay('resendEmail');
      } else if (err.status === 401) {
        setDisplay('wrongAddress');
      } else {
        setDisplay('generalError');
      }
    })
  },[guid])


  return (
    <div>
      <div className='max350'>
        {display === 'loading' && <Spinner animation="border" role="status" variant="light"/>}
        {display === 'noQuery' && <h2>Nothing to See Here.</h2>}
      {display === 'resendEmail' &&
        <h2>This email verification link has expired. A new email has been sent to your account, please use the link in that email to finish signing up.
        </h2>}
      {display === 'wrongAddress' &&
        <h2>There was an error with your verification email. <Link to="/signup" style={{color: 'red'}}> CLICK HERE to start again.</Link>
        </h2>}
      {display === 'generalError' &&
        <h2>There was an error with your verification email. <Link to="/signup" style={{color: 'red'}}> CLICK HERE to start again.</Link>
        </h2>}
      </div>

      {display === 'passwordForm' && <PasswordForm username={username} guid={guid}/>}
    </div>
  )
}

export default Verify;