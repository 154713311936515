import React, {useState} from 'react';
import { Modal, Alert, Spinner } from 'react-bootstrap';
import SmButton from './smButton'

var DeleteModal = function({tenant, refresh, showModal, setModal}) {

  const [err, setErr] =  useState('');
  const [isLoading, setIsLoading] = useState(false)

  const deleteTenant = function(e) {
    e.preventDefault();
    setIsLoading(true);
    setErr('');
    fetch(`/api/v1/tenant/${tenant.Id}`, {
      method: 'DELETE',
      headers: {
        Connection: 'keep-alive',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        throw response;
      })
      .then(data => {
        refresh();
        setIsLoading(false)
        //setModal(false);
      })
      .catch(err => {
        console.log('fetch error', err.status, err.statusText);
        setIsLoading(false);
        if (err.status === 404) setErr(`You don't have authorization to delete this tenant.`);
        if (err.status === 500) setErr('There was a server error, please try again.');

      })

  }
  return (
      <Modal centered show={showModal} onHide={() => setModal(false)} size='sm' className='dark-modal'>
        <Modal.Header closeButton className="dark-modal-header"> Delete Tenant</Modal.Header>
        <Modal.Body>
          <p>Delete {tenant.FriendlyName === '' ? tenant.ApplicationId : tenant.FriendlyName}?</p>
          <SmButton onClick={deleteTenant}  disabled={isLoading}>
            {isLoading ? <Spinner animation="border" size="sm"/> : 'Delete'}
          </SmButton>
          <SmButton onClick={() => setModal(false)} disabled={isLoading}>
            Cancel
          </SmButton>
          {err !== '' && <Alert variant="warning">{err}</Alert> }
        </Modal.Body>
      </Modal>
  )
}

export default DeleteModal;
