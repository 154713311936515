import React, {useState, useEffect} from 'react';
import { LinkContainer } from "react-router-bootstrap";
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { useHistory, useLocation, Link } from 'react-router-dom';
import jwtLogout, {useLogout} from '../../auth/jwtLogout.js';
import {ReactComponent as SvgHead} from '../common/svg/blank_profile.svg';
import SearchModal from './searchModal'

var NavBar = function({buttons}) {

  const [expanded, setExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(buttons[0] || {});
  const [userName, setUserName] = useState(localStorage.getItem('name'));
  const [showSearch, setShowSearch] = useState(false)
  const history = useHistory();
  const location = useLocation();
  const logout = useLogout();
  //const minWidth992  = useMediaQuery("(min-width: 992px)");

  useEffect(() => {
    let button = buttons.find(a => a.link === location.pathname)
    if (button) setCurrentPage(button)
  }, [location, buttons])

 useEffect( () => {
    const interval = setInterval( async () => {
      let name = await window.localStorage.getItem('name');
      if (name) {
        setUserName(() => name);
      } else {
        logout();
      }
    }, 500);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = () => {
    jwtLogout();
    history.push('/')
  }

  const notLoggedInMenu = (
    <React.Fragment>
      <LinkContainer className="ml-auto" to='/login'>
      <Nav.Link>Login</Nav.Link>
      </LinkContainer>
      <LinkContainer className="ml-auto" to='/signup'>
      <Nav.Link>Sign Up</Nav.Link>
      </LinkContainer>
    </React.Fragment>
  )
  const loggedInMenu = (
    <React.Fragment>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        {buttons.map( button => {
          if (button.divider && button.type === 'top') {
            return <div className='divider-holder' key={button.name + '1'}>
              <div className='divider' />
            </div>
          }
          if (button.name === 'Search') return (
            <div className="navbar-svg nav-icons" key={button.name + '1'} onClick={() => { setShowSearch(true) }} >{button.svg}</div>
          )
          if (button.type === 'top' && button.name !== 'Search') return (

          <Nav.Item key={button.name + '-navbar'}>
              <Nav.Link
                href={button.link}
                disabled={button.disabled}
                eventKey={button.eventKey}>
                <div className="navbar-svg">{button.svg}</div>
              </Nav.Link>
          </Nav.Item>
          )
          return null;
        })}
      </div>


      <div className='navbar-fullmenu'>
         <div className="navbar-subhead">MAIN PAGES</div>
      {buttons.map( button => {
        if (button.divider) {
          return <div className='divider-holder' key={button.name + '1'}>
            <div className='divider' />
          </div>
        }
        if (button.type === 'side') return (
        <Nav.Item key={button.name + '1'}>
          {!button.external && <LinkContainer to={button.link}>
                        <Nav.Link
              disabled={button.disabled}
              eventKey={button.eventKey}>

              <div className="navbar-svg" >{button.svg}</div>
              {button.name}
            </Nav.Link>
            </LinkContainer>}
          {button.external &&  <Nav.Link
              href={button.link}
              rel='noopener noreferrer' target="_blank"
              disabled={button.disabled}
              eventKey={button.eventKey}>

              <div className="navbar-svg" >{button.svg}</div>
              {button.name}
            </Nav.Link>}
        </Nav.Item>
        )
        return null;
      })}
            <Nav.Item>
            <LinkContainer to='/profile'>
              <Nav.Link>View/Edit Profile
                </Nav.Link>
            </LinkContainer>
            </Nav.Item>
          <Nav.Item>
            <Nav.Link>
              <div onClick={signOut} style={{display:'inline-flex', cursor:'pointer'}}>Log Out</div>
            </Nav.Link>
          </Nav.Item>
      </div>

      <div className='profile-dropdown'>
        <NavDropdown title={(
          <div className="flex-inline">
            <div className="navbar-profile-background">
              <SvgHead  viewBox="0 0 94 90" width="50px" style={{ paddingTop: '55px' }} />
            </div>
            <div className='nav-profile-txt'>Hi, {userName}</div>
          </div>)} id="basic-nav-dropdown" className="ml-auto nav-dropdown-top" >
          <NavDropdown.Item href='/profile'>Profile</NavDropdown.Item>
          <NavDropdown.Item>
              <div onClick={signOut} style={{display:'inline-flex', cursor:'pointer'}}>Log Out</div>
          </NavDropdown.Item>
        </NavDropdown>
      </div>


    </React.Fragment>
  )

  if (!userName) return null;

  return (
    <div>
      <SearchModal showModal={showSearch} setModal={setShowSearch} />

      <Navbar variant="dark" expand="md" expanded={expanded} collapseOnSelect onToggle={() => setExpanded(!expanded)} className="stickyheader sb-navbar">
        <div className="navbar-wrapper">
          <Link className='navbar-logo' to='/'>
            <img src='stackboss_logo_wt.png' alt='StackBoss' width="200px"/>
          </Link>
          <div style={{display: 'flex', width: '100%', flexDirection: 'row',  justifyContent: 'space-between', alignItems: 'baseline'}}>
            <div >
            <Navbar.Brand className="flex-inline">
              <div className="nav-current-box selected">
                {currentPage.svg || ''}
              </div>
              <div className="nav-current-name">
                {currentPage.name || ''}
              </div>
            </Navbar.Brand>
            </div>
            <div>
              <Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" />

            </div>

          </div>

        </div>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto" onSelect={()=> setExpanded(false)}>
                  {userName === null ? notLoggedInMenu : loggedInMenu}
                </Nav>
              </Navbar.Collapse>
      </Navbar>
    </div>
  )
}


export default NavBar;