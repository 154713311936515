  const sampleData = [
    //['time', 'OS Disk Write IOPs/sec', 'OS Disk Read IOPs/sec'],
    ['2020-12-02 18:00',0,16458],
    ['2020-12-02 18:01',69872,135047],
    ['2020-12-02 18:02',110339,158990],
    ['2020-12-02 18:03',0,23337],
    ['2020-12-02 18:04',3823,28469],
    ['2020-12-02 18:05',2184,29620],
    ['2020-12-02 18:06',2185,44383],
    ['2020-12-02 18:07',204402,125215],
    ['2020-12-02 18:08',0,11745],
    ['2020-12-02 18:09',0,36866],
    ['2020-12-02 18:10',0,14543],
    ['2020-12-02 18:11',546,51249],
    ['2020-12-02 18:12',107119,110948],
    ['2020-12-02 18:13',0,13997],
    ['2020-12-02 18:14',0,14269],
    ['2020-12-02 18:15',546,17882],
    ['2020-12-02 18:16',0,31955],
    ['2020-12-02 18:17',107620,107219],
    ['2020-12-02 18:18',0,15908],
    ['2020-12-02 18:19',546,15498],
    ['2020-12-02 18:20',0,16864],
    ['2020-12-02 18:21',0,52510],
    ['2020-12-02 18:22',125098,93251],
    ['2020-12-02 18:23',0,21848],
    ['2020-12-02 18:24',0,34891],
    ['2020-12-02 18:25',0,15420],
    ['2020-12-02 18:26',0,30646],
    ['2020-12-02 18:27',107131,91241],
    ['2020-12-02 18:28',0,15635],
    ['2020-12-02 18:29',0,13587],
    ['2020-12-02 18:30',0,15021],
    ['2020-12-02 18:31',0,48619],
    ['2020-12-02 18:32',107154,95354],
    ['2020-12-02 18:33',0,17273],
    ['2020-12-02 18:34',0,17478],
    ['2020-12-02 18:35',68,15908],
    ['2020-12-02 18:36',0,89939],
    ['2020-12-02 18:37',213171,165276],
    ['2020-12-02 18:38',0,16787],
    ['2020-12-02 18:39',0,19522],
    ['2020-12-02 18:40',68,1138359],
    ['2020-12-02 18:41',0,14202],
    ['2020-12-02 18:42',160162,128012],
    ['2020-12-02 18:43',0,14270],
    ['2020-12-02 18:44',0,16522],
    ['2020-12-02 18:45',0,16523],
    ['2020-12-02 18:46',0,14336],
    ['2020-12-02 18:47',107155,126853],
    ['2020-12-02 18:48',0,11405],
    ['2020-12-02 18:49',0,17066],
    ['2020-12-02 18:50',0,14955],
    ['2020-12-02 18:51',0,16042],
    ['2020-12-02 18:52',107733,128844],
    ['2020-12-02 18:53',0,16522],
    ['2020-12-02 18:54',0,15567],
    ['2020-12-02 18:55',0,34542],
    ['2020-12-02 18:56',0,15155],
    ['2020-12-02 18:57',107187,109936],
    ['2020-12-02 18:58',0,15566],
    ['2020-12-02 18:59',0,13790],
    ['2020-12-02 19:00',0,14067],
    ['2020-12-02 19:01',0,17547],
    ['2020-12-02 19:02',107634,131536],
    ['2020-12-02 19:03',0,15501],
    ['2020-12-02 19:04',0,12904],
    ['2020-12-02 19:05',0,16588],
    ['2020-12-02 19:06',0,13109],
    ['2020-12-02 19:07',120840,132124],
    ['2020-12-02 19:08',0,16727],
    ['2020-12-02 19:09',0,10446],
    ['2020-12-02 19:10',0,36795],
    ['2020-12-02 19:11',0,13998],
    ['2020-12-02 19:12',125132,127532],
    ['2020-12-02 19:13',0,18289],
    ['2020-12-02 19:14',0,13788],
    ['2020-12-02 19:15',0,16044],
    ['2020-12-02 19:16',0,14948],
    ['2020-12-02 19:17',107609,109744],
    ['2020-12-02 19:18',0,13716],
    ['2020-12-02 19:19',0,14812],
    ['2020-12-02 19:20',0,16113],
    ['2020-12-02 19:21',0,13314],
    ['2020-12-02 19:22',89711,125895],
    ['2020-12-02 19:23',0,40280],
    ['2020-12-02 19:24',0,16044],
    ['2020-12-02 19:25',0,35842],
    ['2020-12-02 19:26',0,14675],
    ['2020-12-02 19:27',142618,142367],
    ['2020-12-02 19:28',0,15225],
    ['2020-12-02 19:29',0,14134],
    ['2020-12-02 19:30',0,14472],
    ['2020-12-02 19:31',0,16525],
    ['2020-12-02 19:32',124117,131289],
    ['2020-12-02 19:33',0,14611],
    ['2020-12-02 19:34',0,15223],
    ['2020-12-02 19:35',0,15023],
    ['2020-12-02 19:36',0,14813],
    ['2020-12-02 19:37',107157,127178],
    ['2020-12-02 19:38',0,16388],
    ['2020-12-02 19:39',0,15362],
    ['2020-12-02 19:40',0,1119586],
    ['2020-12-02 19:41',0,15294],
    ['2020-12-02 19:42',142686,129719],
    ['2020-12-02 19:43',0,15223],
    ['2020-12-02 19:44',0,14204],
    ['2020-12-02 19:45',0,14065],
    ['2020-12-02 19:46',0,16387],
    ['2020-12-02 19:47',107132,126345],
    ['2020-12-02 19:48',0,15222],
    ['2020-12-02 19:49',68,19185],
    ['2020-12-02 19:50',0,17543],
    ['2020-12-02 19:51',0,16524],
    ['2020-12-02 19:52',90189,129514],
    ['2020-12-02 19:53',0,15974],
    ['2020-12-02 19:54',0,15706],
    ['2020-12-02 19:55',68,38024],
    ['2020-12-02 19:56',0,13995],
    ['2020-12-02 19:57',125141,93824],
    ['2020-12-02 19:58',0,17066],
    ['2020-12-02 19:59',0,14066],
    ['2020-12-02 20:00',0,17345],
    ['2020-12-02 20:01',27381,35368],
    ['2020-12-02 20:02',104388,157434],
    ['2020-12-02 20:03',0,13040],
    ['2020-12-02 20:04',0,15567],
    ['2020-12-02 20:05',0,16317],
    ['2020-12-02 20:06',0,13787],
    ['2020-12-02 20:07',106586,112686],
    ['2020-12-02 20:08',0,13989],
    ['2020-12-02 20:09',0,14744],
    ['2020-12-02 20:10',0,35978],
    ['2020-12-02 20:11',0,14680],
    ['2020-12-02 20:12',107665,92990],
    ['2020-12-02 20:13',0,18775],
    ['2020-12-02 20:14',0,14063],
    ['2020-12-02 20:15',0,15569],
    ['2020-12-02 20:16',0,15153],
    ['2020-12-02 20:17',72235,108492],
    ['2020-12-02 20:18',0,13655],
    ['2020-12-02 20:19',0,15565],
    ['2020-12-02 20:20',0,18778],
    ['2020-12-02 20:21',0,12427],
    ['2020-12-02 20:22',90189,126441],
    ['2020-12-02 20:23',0,37754],
    ['2020-12-02 20:24',0,19117],
    ['2020-12-02 20:25',0,35432],
    ['2020-12-02 20:26',0,16176],
    ['2020-12-02 20:27',143164,96831],
    ['2020-12-02 20:28',0,15905],
    ['2020-12-02 20:29',0,13589],
    ['2020-12-02 20:30',0,14201],
    ['2020-12-02 20:31',0,15908],
    ['2020-12-02 20:32',72235,113675],
    ['2020-12-02 20:33',0,15838],
    ['2020-12-02 20:34',0,15910],
    ['2020-12-02 20:35',0,16042],
    ['2020-12-02 20:36',0,14197],
    ['2020-12-02 20:37',89165,109587],
    ['2020-12-02 20:38',0,12699],
    ['2020-12-02 20:39',0,16452],
    ['2020-12-02 20:40',0,1132459],
    ['2020-12-02 20:41',0,14747],
    ['2020-12-02 20:42',124663,117635],
    ['2020-12-02 20:43',0,13382],
    ['2020-12-02 20:44',0,14065],
    ['2020-12-02 20:45',0,14201],
    ['2020-12-02 20:46',0,15014],
    ['2020-12-02 20:47',90189,128513],
    ['2020-12-02 20:48',0,11536],
    ['2020-12-02 20:49',0,16662],
    ['2020-12-02 20:50',0,14404],
    ['2020-12-02 20:51',0,17891],
    ['2020-12-02 20:52',107665,128080],
    ['2020-12-02 20:53',0,15225],
    ['2020-12-02 20:54',0,13246],
    ['2020-12-02 20:55',0,34468],
    ['2020-12-02 20:56',0,17075],
    ['2020-12-02 20:57',125076,128704],
    ['2020-12-02 20:58',0,15904],
    ['2020-12-02 20:59',0,14474],
    ['2020-12-02 21:00',0,13791],
    ['2020-12-02 21:01',13653,31060],
    ['2020-12-02 21:02',113638,135840],
    ['2020-12-02 21:03',9832,54891],
    ['2020-12-02 21:04',4916,45811],
    ['2020-12-02 21:05',9285,47996],
    ['2020-12-02 21:06',8192,42188],
    ['2020-12-02 21:07',101222,145483],
    ['2020-12-02 21:08',7647,34753],
    ['2020-12-02 21:09',12017,46020],
    ['2020-12-02 21:10',10373,71247],
    ['2020-12-02 21:11',13654,43424],
    ['2020-12-02 21:12',128928,140953],
    ['2020-12-02 21:13',2799,35637],
    ['2020-12-02 21:14',31678,58514],
    ['2020-12-02 21:15',8738,38846],
    ['2020-12-02 21:16',18634,52282],
    ['2020-12-02 21:17',102236,157681],
    ['2020-12-02 21:18',10925,40694],
    ['2020-12-02 21:19',37752,67040],
    ['2020-12-02 21:20',21982,50451],
    ['2020-12-02 21:21',12561,54620],
    ['2020-12-02 21:22',111010,143850],
    ['2020-12-02 21:23',9830,57687],
    ['2020-12-02 21:24',19115,70386],
    ['2020-12-02 21:25',8738,65538],
    ['2020-12-02 21:26',18020,48396],
    ['2020-12-02 21:27',140913,157566],
    ['2020-12-02 21:28',9839,36476],
    ['2020-12-02 21:29',6551,33858],
    ['2020-12-02 21:30',10925,37966],
    ['2020-12-02 21:31',8737,35766],
    ['2020-12-02 21:32',128245,157334],
    ['2020-12-02 21:33',2184,29629],
    ['2020-12-02 21:34',24029,65336],
    ['2020-12-02 21:35',17000,45405],
    ['2020-12-02 21:36',33309,62933],
    ['2020-12-02 21:37',127430,149065],
    ['2020-12-02 21:38',1093,33183],
    ['2020-12-02 21:39',15835,48540],
    ['2020-12-02 21:40',14338,1159354],
    ['2020-12-02 21:41',0,34551],
    ['2020-12-02 21:42',128418,148764],
    ['2020-12-02 21:43',10921,50650],
    ['2020-12-02 21:44',27858,57625],
    ['2020-12-02 21:45',15840,54009],
    ['2020-12-02 21:46',14199,35501],
    ['2020-12-02 21:47',107725,91939],
    ['2020-12-02 21:48',0,19454],
    ['2020-12-02 21:49',0,19598],
    ['2020-12-02 21:50',9284,34477],
    ['2020-12-02 21:51',13107,47652],
    ['2020-12-02 21:52',74351,138798],
    ['2020-12-02 21:53',546,21437],
    ['2020-12-02 21:54',0,14814],
    ['2020-12-02 21:55',0,36186],
    ['2020-12-02 21:56',6006,27440],
    ['2020-12-02 21:57',264896,277406],
    ['2020-12-02 21:58',5458,33981],
    ['2020-12-02 21:59',0,16173],
    ['2020-12-02 22:00',0,15567],
    ['2020-12-02 22:01',221184,129982],
    ['2020-12-02 22:02',115311,139617],
    ['2020-12-02 22:03',10377,48471],
    ['2020-12-02 22:04',6554,41167],
    ['2020-12-02 22:05',0,16181],
    ['2020-12-02 22:06',0,18364],
    ['2020-12-02 22:07',107693,144496],
    ['2020-12-02 22:08',0,16936],
    ['2020-12-02 22:09',0,14816],
    ['2020-12-02 22:10',9830,60077],
    ['2020-12-02 22:11',4915,47857],
    ['2020-12-02 22:12',142617,150403],
    ['2020-12-02 22:13',546,18773],
    ['2020-12-02 22:14',0,16662],
    ['2020-12-02 22:15',0,14133],
    ['2020-12-02 22:16',1638,22940],
    ['2020-12-02 22:17',132326,134824],
    ['2020-12-02 22:18',1638,26885],
    ['2020-12-02 22:19',546,15225],
    ['2020-12-02 22:20',0,17134],
    ['2020-12-02 22:21',0,19254],
    ['2020-12-02 22:22',90159,145446],
    ['2020-12-02 22:23',0,16116],
    ['2020-12-02 22:24',0,45192],
    ['2020-12-02 22:25',0,43697],
    ['2020-12-02 22:26',0,22393],
    ['2020-12-02 22:27',372046,266598],
    ['2020-12-02 22:28',0,14952],
    ['2020-12-02 22:29',0,50588],
    ['2020-12-02 22:30',0,16590],
    ['2020-12-02 22:31',0,14952],
    ['2020-12-02 22:32',142618,131903],
    ['2020-12-02 22:33',0,15291],
    ['2020-12-02 22:34',0,18027],
    ['2020-12-02 22:35',0,18570],
    ['2020-12-02 22:36',0,16045],
    ['2020-12-02 22:37',120228,154970],
    ['2020-12-02 22:38',4372,22057],
    ['2020-12-02 22:39',68,17879],
    ['2020-12-02 22:40',0,1127229],
    ['2020-12-02 22:41',0,17410],
    ['2020-12-02 22:42',159616,128012],
    ['2020-12-02 22:43',0,18093],
    ['2020-12-02 22:44',68,15498],
    ['2020-12-02 22:45',0,14953],
    ['2020-12-02 22:46',0,16317],
    ['2020-12-02 22:47',107727,94538],
    ['2020-12-02 22:48',0,41508],
    ['2020-12-02 22:49',0,15565],
    ['2020-12-02 22:50',0,16456],
    ['2020-12-02 22:51',0,15294],
    ['2020-12-02 22:52',89643,108692],
    ['2020-12-02 22:53',0,31131],
    ['2020-12-02 22:54',0,13587],
    ['2020-12-02 22:55',0,41031],
    ['2020-12-02 22:56',0,13655],
    ['2020-12-02 22:57',124742,107313],
    ['2020-12-02 22:58',0,36301],
    ['2020-12-02 22:59',0,15498],
    ['2020-12-02 23:00',0,13792],
    ['2020-12-02 23:01',1092,16864],
    ['2020-12-02 23:02',107665,110194],
    ['2020-12-02 23:03',0,33041],
    ['2020-12-02 23:04',0,15091],
    ['2020-12-02 23:05',0,17069],
    ['2020-12-02 23:06',0,11265],
    ['2020-12-02 23:07',214668,201956],
    ['2020-12-02 23:08',0,31958],
    ['2020-12-02 23:09',0,15977],
    ['2020-12-02 23:10',0,35159],
    ['2020-12-02 23:11',0,12425],
    ['2020-12-02 23:12',125105,92900],
    ['2020-12-02 23:13',0,34690],
    ['2020-12-02 23:14',0,16045],
    ['2020-12-02 23:15',0,15428],
    ['2020-12-02 23:16',0,15432],
    ['2020-12-02 23:17',89696,128624],
    ['2020-12-02 23:18',0,30788],
    ['2020-12-02 23:19',0,16593],
    ['2020-12-02 23:20',0,15840],
    ['2020-12-02 23:21',0,15020],
    ['2020-12-02 23:22',125688,92308],
    ['2020-12-02 23:23',0,30517],
    ['2020-12-02 23:24',0,42601],
    ['2020-12-02 23:25',0,34749],
    ['2020-12-02 23:26',0,16247],
    ['2020-12-02 23:27',125223,124872],
    ['2020-12-02 23:28',0,35072],
    ['2020-12-02 23:29',0,14944],
    ['2020-12-02 23:30',0,14474],
    ['2020-12-02 23:31',0,15089],
    ['2020-12-02 23:32',124595,112310],
    ['2020-12-02 23:33',0,33657],
]

// const chartDataStructure = {
//   name: '<Metric Name>',
//   id: '<Metric Id>',
//   fields: [
//     '<Field Name1>', // First field name should be utcTime for all time series
//     '<Field NameN>'
//   ],
//   data: [
//     [
//       '<Field1 Value>',// First field value should be utcTime for all time series
//       '<FieldN Value>'
//     ],
//     [
//       '<Field1 Value>',// First field value should be utcTime for all time series
//       '<FieldN Value>'
//     ]
//   ],
//   markers: [ //Optional
//     {
//       label: '<name>',
//       xLocation: '<value>'
//     }
//   ],
//   config: { //config is optional, defaults would be used in not present. Config could depend on a combination of metric type or user preferences. And I would expect these to evolve as we develop the charts.
//     chartType: '<line, area, scatter, bar, pie, etc>',
//     xRange: ['<min X axis value>', '<min X axis value>'],
//     yRange: ['<min Y axis value>', '<min Y axis value>'],
//     colorset: ['color1', 'color2']
//   }
// }

export default sampleData;