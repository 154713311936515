import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { VictoryVoronoiContainer, VictoryChart, VictoryLine, VictoryAxis, VictoryLegend, VictoryScatter } from 'victory';
import useWindowSize from '../../common/useWindowSize';

const DashChart = function({ metrics, resources }) {

  const styles = getStyles();
  let tickValues
  let maxDomainOne;
  // let maxDomainTwo;
  // let xDomain;
  let dataSetOne = [];
  let dataSetTwo = [];
  let chartWidth = 0;

  const windowWidth = useWindowSize().width || 200
    console.log(metrics, resources)
    if (metrics && Object.keys(metrics).length) {

      dataSetOne = getDataSet(metrics);
      dataSetTwo = getDataSet(resources);
      tickValues = getTickValues([...dataSetOne, ...dataSetTwo]);
      maxDomainOne = Math.ceil(Math.max(dataSetOne.reduce((a, b) => Math.max(a, b.y),0),
        dataSetTwo.reduce((a, b) => Math.max(a, b.y),0))/100)*100;
      // maxDomainTwo = 500;
      // xDomain = [dataSetOne[0].x, dataSetOne[dataSetOne.length -1].x]
    }

    if (!metrics) return (
      <Container style={{display: 'grid', placeItems: 'center', height: '250px'}}>
        <Spinner animation="border" variant="primary" size="100%" />
      </Container>

    )

    return  (
        <div style={{maxWidth:'100%', maxHeight:'100%'}} >
        <VictoryChart
          height={25000/(2*windowWidth**.55)}
          width={30*windowWidth**.45}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.x.toLocaleString('en-US', {month: 'short', day: 'numeric'})}, ${Math.round(datum.y, 2)}`}
            />
          }>
            <VictoryLegend x={30*windowWidth**.45/3} y={10}
              centerTitle
              orientation="horizontal"
              gutter={20}
              style={{ border: { stroke: "white" }, labels: {fill: 'white'}}}
              data={[
                { name: "New Metrics", symbol: { fill: "rgba(125,112,241)" } },
                { name: "New Resources", symbol: { fill:"rgba(238,116,77)" } },

              ]}
          />
          {dataSetOne.length > 1 && <VictoryLine data={dataSetOne} style={styles.lineOne} />}
          {dataSetOne.length === 1 && <VictoryScatter data={dataSetOne} style={styles.lineOne} />}
          {dataSetTwo.length === 1 && <VictoryScatter data={dataSetTwo} style={styles.lineTwo} />}
          {dataSetTwo.length > 1 && <VictoryLine data={dataSetTwo} style={styles.lineTwo} />}
          <VictoryAxis
              scale="time"
              width={chartWidth}
              standalone={false}
              style={styles.axisYears}
              tickValues={tickValues}
              tickFormat={
                (x) => {
                    return x.toLocaleString('en-US', {month: 'short', day: 'numeric'});
                  }}
            />

            <VictoryAxis dependentAxis
              domain={[0, maxDomainOne]}
              width={chartWidth}
              offsetX={50}
              orientation="left"
              standalone={false}
              style={styles.axisOne}
            />

        </VictoryChart>
        </div>

    )




  //   return (
  //     <div className="fade-in">
  //       <svg style={styles.parent} viewBox="0 0 1000 300">
  // {/* Define labels */}
  //         <VictoryLabel x={100} y={245} style={styles.labelTwo}
  //           text={'New Resources'}
  //         />

  //         <VictoryLabel x={650} y={245} style={styles.labelOne}
  //           text={'New Metrics'}
  //         />

  //         <g transform={"translate(-100, -40)"}>
  //           {/* Add shared independent axis */}
  //           <VictoryAxis
  //             scale="time"
  //             width={chartWidth}
  //             standalone={false}
  //             style={styles.axisYears}
  //             tickValues={tickValues}
  //             tickFormat={
  //               (x) => {
  //                 if (x.getFullYear() !== 0) {
  //                   return x.toLocaleString('en-US', {month: 'short', day: 'numeric'});
  //                 }
  //                 // if (x.getFullYear() % 5 === 0) {
  //                 //   return x.getFullYear().toString().slice(2);
  //                 // }
  //               }
  //             }
  //           />

  //           {/*
  //             Add the dependent axis for the first data set.
  //             Note that all components plotted against this axis will have the same y domain
  //           */}
  //           <VictoryAxis dependentAxis
  //             domain={[0, maxDomain]}
  //             width={chartWidth}
  //             offsetX={50}
  //             orientation="left"
  //             standalone={false}
  //             style={styles.axisOne}
  //           />

  //           {/* Red annotation line */}
  //           {/* <VictoryLine
  //             data={[
  //               {x: 0, y: 0},
  //               {x: 0, y: 1200000}
  //             ]}
  //             domain={{
  //               x: xDomain,
  //               y: [0, 400000]
  //             }}
  //             scale={{x: "time", y: "linear"}}
  //             standalone={false}
  //             style={styles.lineThree}
  //           /> */}

  //           {/* dataset one */}
  //           <VictoryLine
  //             width={chartWidth}
  //             data={dataSetOne}
  //             domain={{
  //               x: xDomain,
  //               y: [0, maxDomain]
  //             }}
  //             interpolation="monotoneX"
  //             scale={{x: "date", y: "linear"}}
  //             standalone={false}
  //             style={styles.lineOne}
  //           />

  //           <VictoryLine
  //             data={dataSetTwo}
  //             width={chartWidth}
  //             domain={{
  //               x: xDomain,
  //               y: [0, maxDomain]
  //             }}
  //             interpolation="monotoneX"
  //             scale={{x: "date", y: "linear"}}
  //             standalone={false}
  //             style={styles.lineTwo}
  //           />

  //           {/*
  //             Add the dependent axis for the second data set.
  //             Note that all components plotted against this axis will have the same y domain
  //           */}



  //         </g>
  //       </svg>
  //     </div>
  //   );
  }

  function getDataSet(data) {
    let dataArray = [];
    for (let key in data) {
      dataArray.push({
        x: new Date(key),
        y: Number(data[key])
      })
    }
    return dataArray.sort((a,b) => a.x - b.x)
  }

  function getTickValues(data) {

    data.sort((a,b) => a.x - b.x)
    const ticks = [];
    let current = data[0].x;
    const last = data[data.length - 1].x;
    const interval = (last.getTime() - current.getTime()) / 5
    while (current <= last) {
      ticks.push(current);
      current = new Date(current.getTime() + (interval || 1))
    }
    return ticks;
  }

  function getStyles() {
    const PURPLE_COLOR = "rgba(125,112,241)";
    const ORANGE_COLOR = "rgba(238,116,77)";


    return {
      parent: {
        background: "none",
        boxSizing: "border-box",
        display: "inline",
        padding: '20px',
        maxHeight: '320px'
      },
      title: {
        textAnchor: "start",
        verticalAnchor: "end",
        fill: "#000000",
        fontFamily: "inherit",
        fontSize: "18px",
        fontWeight: "bold"
      },
      labelNumber: {
        textAnchor: "middle",
        fill: "#ffffff",
        fontFamily: "inherit",
        fontSize: "14px"
      },

      // INDEPENDENT AXIS
      axisYears: {
        axis: { stroke: "white", strokeWidth: 2},
        ticks: {
          size: 5,
          stroke: "white",
          strokeWidth: 1
        },
        tickLabels: {
          fill: "white",
          fontFamily: "inherit",
          fontSize: 12,
        }
      },

      // DATA SET ONE
      axisOne: {
        grid: {
          stroke: ({ tick }) =>
            tick === -10 ? "transparent" : "#ffffff",
          strokeWidth: 1
        },
        axis: { stroke: 'white', strokeWidth: 2 },
        ticks: { strokeWidth: 1 },
        tickLabels: {
          fill: "white",
          fontFamily: "inherit",
        }
      },
      labelOne: {
        fill: PURPLE_COLOR,
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic"
      },
      lineOne: {
        data: { stroke: PURPLE_COLOR, strokeWidth: 8 }
      },
      axisOneCustomLabel: {
        fill: PURPLE_COLOR,
        fontFamily: "inherit",
        fontWeight: 300,
        fontSize: 21
      },

      // DATA SET TWO
      axisTwo: {
        axis: { stroke: ORANGE_COLOR, strokeWidth: 0 },
        tickLabels: {
          fill: ORANGE_COLOR,
          fontFamily: "inherit",
          fontSize: 10
        }
      },
      labelTwo: {
        textAnchor: "end",
        fill: ORANGE_COLOR,
        fontFamily: "inherit",
        fontSize: 12,
        fontStyle: "italic"
      },
      lineTwo: {
        data: { stroke: ORANGE_COLOR, strokeWidth: 8 }
      },

    };

}

export default DashChart;