import { useHistory, useLocation }  from 'react-router-dom';

const logout = (payload) => {

  const token = localStorage.getItem("token");
  localStorage.removeItem("email");
  localStorage.removeItem("name");
  localStorage.removeItem("exp");
  localStorage.removeItem("token");
  fetch(`/api/v1/token`, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  }).then(response => {
      console.log('Signout Status ' + response.status)
  })
  .catch(err => {
    console.log(err)
  })
}

export const useLogout = function() {

  const history = useHistory();
  const location = useLocation();

  return () => {
    logout();
    history.push({
      pathname: '/login',
      search: '?expired',
      state: {returnTo: location.pathname + location.search}
    })
    }

}

export default logout;