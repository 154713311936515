import React, { useState } from 'react';
import { StartButton } from '../Home/start';
import { Form, Spinner, Col, Row, Container, Alert, OverlayTrigger, Popover} from 'react-bootstrap';
import { useHistory} from 'react-router-dom';
import lists from './dropDownData';

var PasswordForm = function(props) {

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [annualRevenue, setAnnualRevenue] = useState('');
  const [industry, setIndustry] = useState('');

  const [isErr, setIsErr] =useState(false);
  const [isLoading, setIsLoading] =useState(false);
  const [hasCapital, setHasCapital] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNum, setHasNum] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const history = useHistory();

  const setValues = {
    password1: setPassword1,
    password2: setPassword2,
    firstName: setFirstName,
    lastName: setLastName,
    address1: setAddress1,
    address2: setAddress2,
    city: setCity,
    zip: setZip,
    state: setState,
    country: setCountry,
    companyName: setCompanyName,
    companySize: setCompanySize,
    annualRevenue: setAnnualRevenue,
    industry: setIndustry,
  }

  const has3 = (hasCapital + hasLowerCase + hasNum + hasSpecialChar) > 2
  const pwLengthCheck = password1.length > 9 && password1.length < 37
  const pwLeadingTrailing = password1[0] !== ' ' && password1[0] !== ' '

  const pwPopover = <Popover id="password-popover" >
  <Popover.Title>Your password should:</Popover.Title>
  <Popover.Content>
    <ul className="pwList">
      <li className={pwLengthCheck ? 'green-check' : 'red-x'}>Have a length of 10-36 characters;</li>
      <li className={pwLeadingTrailing ? 'green-check' : 'red-x'}>Not begin or end with a space;</li>
      <li className={has3 ? 'green-check' : 'red-x'}>Have at least 3 of the following:</li>
      <ul className="pwList">
        <li className={hasCapital ? 'green-check' : 'grey-check'}>Contain an upper case letter;</li>
        <li className={hasLowerCase ? 'green-check' : 'grey-check'}>Contain a lower case letter;</li>
        <li className={hasNum ? 'green-check' : 'grey-check'}>Contain a number;</li>
        <li className={hasSpecialChar ? 'green-check' : 'grey-check'}>Contain a special character (!@#$%^&* _-+=?/;:.,).</li>
      </ul>
    </ul>
  </Popover.Content>
</Popover>

    const submitHandler = function(e) {
      e.preventDefault();
      setIsErr(false)
      setIsLoading(true);

      if (props.username.substring(0,6) === 'sbtest') {
        console.log('test submitted', { "guid": props.guid,
        "emailAddress": props.username,
        "password": password1,
        "firstName": firstName,
        "lastName": lastName,
        "company": companyName,
        "address1": address1,
        "address2": address2,
        "city": city,
        "state": state,
        "zipcode": zip,
        "country": country,
        "companySize": companySize,
        "annualRevenue": annualRevenue,
        "industry": industry
      })
        return;
      }

      fetch(`/api/v1/emailaddress`, {
        method: 'PUT',
        headers: {
          'Accept': '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          'Content-Type': 'application/json',
          'Connection': 'keep-alive'
        },
        body: JSON.stringify({ "guid": props.guid,
        "emailAddress": props.username,
        "password": password1,
        "firstName": firstName,
        "lastName": lastName,
        "company": companyName,
        "address1": address1,
        "address2": address2,
        "city": city,
        "state": state,
        "zipcode": zip,
        "country": country,
        "companySize": companySize,
        "annualRevenue": annualRevenue,
        "industry": industry
      })
      })
        .then(response => {
          if (response.ok) {
            return response.text();
          }
          throw response;
        })
        .then(data => {
          setIsLoading(false);
          history.push('/login?sucomplete');
        })
        .catch(err => {
          console.log('fetch error', err.status, err.statusText);
          setIsErr(true);
          setIsLoading(false);
        })
    }

  const handleChange = function(e) {
      const {name, value} = e.target;
      setValues[name](value)
      if (name === 'password1') {
        setHasCapital((/.*[A-Z].*/).test(e.target.value))
        setHasLowerCase((/.*[a-z].*/).test(e.target.value))
        setHasNum((/.*[0-9].*/).test(e.target.value))
        setHasSpecialChar((/.*[^A-Za-z0-9].*/).test(e.target.value))
      }
   }

  const meetsConditions = (hasLowerCase + hasCapital + hasSpecialChar + hasNum) > 2
    && password1 === password2
    && password1.length > 9
    && password1[0] !== ' '
    && password1[password1.length -1] !== ' ';

  return (

        <Container className='wider-form'>
        <div className='signup-header'>Thank you, your email address is confirmed. Please finish creating your account below:</div>
      <Form onSubmit={submitHandler} style={{margin: '3em 0 0 0'}}>
          <Row>
            <Col md={6} lg={6}>
            <input
              type='text'
              hidden
              readOnly
              name='username'
              autoComplete='username'
              value={props.username}
              default={props.username}
              />

          <OverlayTrigger trigger="focus" placement="top" overlay={pwPopover}>
          <Form.Group controlId='formBasicPassword'>
            <Form.Label className="justify-left">Password (Required)</Form.Label>

            <Form.Control onChange={handleChange}
              className='dark-input'
              autoComplete='new-password'
              name='password1'
              value={password1}
              type='password'
              placeholder='Password'
              required />

          </Form.Group>
          </OverlayTrigger>



        <Form.Group controlId='formBasicPassword2'>
          <Form.Label className="justify-left">Confirm Password (Required)</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='new-password'
            name='password2'
            value={password2}
            type='password'
            placeholder='Confirm Password'
            required />
          {(password2.length > 0 && password1 !== password2 ) && (
            <Form.Text className="text-danger bg-light fade-in">
              Passwords do not match.
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId='formBasicFName'>
          <Form.Label className="justify-left">First Name (Required)</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='given-name'
            name='firstName'
            value={firstName}
            type='given-name'
            placeholder='First Name'
            required />
        </Form.Group>

        <Form.Group controlId='formBasicLName'>
          <Form.Label className="justify-left">Last Name (Required)</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='family-name'
            name='lastName'
            value={lastName}
            type='family-name'
            placeholder='Last Name'
            required />
        </Form.Group>

        <Form.Group controlId='formBasicCompany'>
          <Form.Label className="justify-left">Company</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='organization-name'
            name='companyName'
            value={companyName}
            type='organization-name'
            placeholder='Company Name'/>
        </Form.Group>


        <Form.Group controlId='formBasicStreet1'>
          <Form.Label className="justify-left">Address</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='address-line1'
            name='address1'
            value={address1}
            type='address-line1'
            placeholder='Address'/>
        </Form.Group>

        <Form.Group controlId='formBasicStreet2'>
          <Form.Label className="justify-left">Address (2nd line)</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='address-line2'
            name='address2'
            value={address2}
            type='address-line2'
            placeholder='Address (2nd line)' />
        </Form.Group>

        </Col>
        <Col md={6} lg={6}>

        <Form.Group controlId='formBasicCity'>
          <Form.Label className="justify-left">City</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='address-level2'
            name='city'
            value={city}
            type='address-level2'
            placeholder='City' />
        </Form.Group>

        <Form.Group controlId='formBasicState'>
          <Form.Label  className="justify-left">State / Province</Form.Label>
          <Form.Control onChange={handleChange} as='select'
          className='dark-input'
            custom
            autoComplete='address=level1'
            name='state'
            value={state}
            type='address-level1' >
              <option value =''>--</option>
            {lists.states.map(state => (
              <option key={state.code} value={state.code}>{state.desc}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='formBasicZip'>
          <Form.Label className="justify-left">Zip / Postal Code</Form.Label>
          <Form.Control onChange={handleChange}
          className='dark-input'
            autoComplete='postal-code'
            name='zip'
            value={zip}
            type='postal-code'
            placeholder='Zip / Postal Code' />
        </Form.Group>

        <Form.Group controlId='formBasicCountry'>
          <Form.Label className="justify-left">Country</Form.Label>
          <Form.Control onChange={handleChange} as='select'
          className='dark-input'
            custom
            autoComplete='country'
            name='country'
            value={country}
            type='address-level1' >
              <option value =''>--</option>
            {lists.countries.map(country => (
              <option key={country.code} value={country.code}>{country.desc}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='formBasicCompanySize'>
          <Form.Label className="justify-left">Company Size (# of employees)</Form.Label>
          <Form.Control onChange={handleChange} as='select'
          className='dark-input'
            custom
            name='companySize'
            value={companySize}
            type='text' >
              <option value =''>--</option>
            {lists.companySize.map(size => (
              <option key={size.code} value={size.desc}>{size.desc}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='formBasicRevenue'>
          <Form.Label className="justify-left">Annual Revenue</Form.Label>
          <Form.Control onChange={handleChange} as='select'
          className='dark-input'
            custom
            name='annualRevenue'
            value={annualRevenue}
            type='text' >
              <option value =''>--</option>
            {lists.revenue.map(rev => (
              <option key={rev.code} value={rev.desc}>{rev.desc}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId='formBasicIndustry'>
          <Form.Label className="justify-left">Industry</Form.Label>
          <Form.Control onChange={handleChange} as='select'
          className='dark-input'
            custom
            name='industry'
            value={industry}
            type='text' >
              <option value =''>--</option>
            {lists.industry.map(rev => (
              <option key={rev.code} value={rev.desc}>{rev.desc}</option>
            ))}
          </Form.Control>
        </Form.Group>


          </Col>
        </Row>
        {isErr && <Alert>There was an error submitting your form. Please Try again later.</Alert>}
        <StartButton
        disabled={isLoading || !meetsConditions}
        type="submit" > {isLoading ? <Spinner animation='border' /> : 'Submit'}</StartButton>
        </Form>

      </Container>

);
}

export default PasswordForm;