import React from 'react';

var SuperCard = function(props) {
  return (
    <div className={'supercard ' + props.className}>
      {props.children}
    </div>
  );
}

export default SuperCard;