import React, { useState } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import Recommendations from '../Recommendations';
import AutomationLogs from  '../AutomationLogs'
//import Pending from '../Pending'

export default function LogModal({resource, show, closeModal, query }) {
  const [key, setKey] = useState(resource.Automated === 1 ? 'AutomationLogs' : 'Recommendations' );

  if (!resource || !Object.entries(resource).length) return null;

  return (
    <Modal
      show={show}
      onHide={closeModal}
      className='dark-modal'
      centered
      size='lg'
      >
      <Modal.Header closeButton className="dark-modal-header">
        Logs & Recommendations for {resource.SubscriptionName || resource.ResourceName || resource.textName || resource.ResourceGroup}
      </Modal.Header>
      <Modal.Body>
          <Tabs className='log-tabs'
            // style={{padding:'5px 0 10px 0'}}
            activeKey={key} id="controlled-tabs"
                onSelect={(k) => setKey(k)}>
            <Tab className='log-tab' style={{height: '500px', overflowY: 'auto'}} eventKey="Recommendations" title="Recommendations">
                <Recommendations query={query} view='modal'/>

            </Tab>
            <Tab className='log-tab' style={{height: '500px', overflowY: 'auto'}} eventKey="AutomationLogs" title="Automation Logs">
              <AutomationLogs query={query} view='modal' />
            </Tab>
            {/* <Tab style={{height: '500px', overflowY: 'scroll'}} eventKey="Pending" title="Pending Automation" >
              <Pending query={query} />
            </Tab> */}
          </Tabs>
      </Modal.Body>
    </Modal>
  )
}