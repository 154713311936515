import React from 'react';
import { Container, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom';

var NoMatch = function() {
  return (
    <Container className='home-container-lg'>
      <Row >
        <Col sm={true} />
        <Col >
          <Link to='/'><img src='/stackboss_logo_wb.png' alt='StackBoss' width="250vw"/></Link>
          <h5 style={{marginTop: '4em'}}>
            This url is not valid. <br/>To return to our homepage <Link to='/'> click here</Link>.
          </h5>
        </Col>
        <Col sm={true} />
      </Row>
    </Container>

  )
}

export default NoMatch;