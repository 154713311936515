import React, {useState, useEffect} from 'react';
import StdButton from '../common/stdButton'
import { Card, Accordion } from 'react-bootstrap';
import AutomationModal from './automationModal'
import ResourceTable from './resourceTable';
import { automationState, automationColors } from '../common/enums';
import { getResources } from '../common/utils';
import { useLogout } from '../../auth/jwtLogout';
import LogModal from './logModal';
import { ReactComponent as SvgLoader } from '../common/svg/stackboss-loader.svg';

const SubscriptionAccordion = function({resources, updateResources, tenant}) {
   const [childrenToggle, setChildrenToggle] = useState(false);

  return (
      <TenantAccordion tenant={tenant} updateResources={updateResources} setChildrenToggle={setChildrenToggle} childrenToggle={childrenToggle}>
      <>
      {resources && resources.map(resource => (
        <Accordion key={resource.SubscriptionId}>
          <Subscription resource={resource} query={tenant.TenantId} logQuery={'/subscriptions/' + resource.SubscriptionId} updateSubscriptions={updateResources} childrenToggle={childrenToggle} setChildrenToggle={setChildrenToggle} />
        </Accordion >
        ))
      }
      </>
      </TenantAccordion>
  )
}

const TenantAccordion = function({tenant, updateResources, children, childrenToggle, setChildrenToggle}) {
  const [showAutoModal, setShowAutoModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [toggle, setToggle] = useState(true);


  const updateChildren = () => {
    setChildrenToggle(!childrenToggle)
  }

  const handleToggle = () => {
    setToggle(!toggle)
  }

  const openAutoModal = (e) => {
    e.stopPropagation();
    setShowAutoModal(true)
  };

    const openLogModal = (e) => {
    e.stopPropagation();
    setShowLogModal(true)
  };

  const closeAutoModal = () => {setShowAutoModal(false)};

  const closeLogModal = () => {setShowLogModal(false)};
  return (
    <Accordion defaultActiveKey="0">
      <Card className='resource-accordion'>
        <AutomationModal resource={tenant} show={showAutoModal} closeModal={closeAutoModal} query={tenant.TenantId} updateResources={updateResources} updateChildren={updateChildren}/>
        <LogModal resource={tenant} show={showLogModal} closeModal={closeLogModal} query={'?tenantId=' + tenant.TenantId} />
        <Card.Header>
          <Accordion.Toggle as="div" eventKey="0" onClick={handleToggle}>
              <span>
                <Toggle className={toggle ? 'toggle-down' : 'toggle-up'}/>
              </span> &nbsp;
              <AutomationButton openModal={openAutoModal} automated={tenant.Automated}/>
              &nbsp;Tenant: <span style={{color: '#26C1DB'}}>{tenant.FriendlyName} </span>
              <span style={{float: 'right'}} >
                <LogButton  onClick={openLogModal} >LOGS</LogButton>
              </span>
          </Accordion.Toggle >
        </Card.Header>
        <Accordion.Collapse eventKey="0"  >
            {children}
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

const ResGroupAccordion = function({resources, updateResources, query, logQuery, groupToggle}) {

  return (
    <div className='fade-in'>
      {resources.length && resources.map(resource => (
        <Accordion key={resource.ResourceGroup}>
          <ResGroup resource={resource} query={query} logQuery={logQuery} updateSubscriptions={updateResources} groupToggle={groupToggle} />
        </Accordion>
        ))

      }
    </div>
  )
}

const ResGroup = ({resource, query, logQuery, updateSubscriptions, groupToggle}) => {

  const [showAutoModal, setShowAutoModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [resources, setResources] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [childrenToggle, setChildrenToggle] = useState(false);
  const logout = useLogout();
  const newQuery = query + '/' + resource.ResourceGroup
  const newLogQuery = logQuery + '/resourceGroups/' + resource.ResourceGroup

  const loadResources = () => {
    getResources(newQuery)
      .then(data => {
        setResources(data)
      })
      .catch(err => {
        console.error(err);
        if (err.status === 401) {
          logout();
        }
      })
  }

  useEffect(()=> {
    if (resources.length) {
      loadResources()
      setChildrenToggle(!childrenToggle)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[groupToggle])

  const updateResources = () => {
    updateSubscriptions();
    loadResources();
  }

  const handleToggle = () => {
    setToggle(!toggle)
    loadResources()
  }

  const openAutoModal = (e) => {
    e.stopPropagation();
    setShowAutoModal(true)
  };

    const openLogModal = (e) => {
    e.stopPropagation();
    setShowLogModal(true)
  };

  const closeAutoModal = () => {setShowAutoModal(false)};

  const closeLogModal = () => {setShowLogModal(false)};

  return (
    <Card className='resource-accordion'>
      <AutomationModal resource={resource} show={showAutoModal} closeModal={closeAutoModal} query={newQuery} updateResources={updateResources} toggleChildren={() => {setChildrenToggle(!childrenToggle)}}/>
      <LogModal resource={resource} show={showLogModal} closeModal={closeLogModal} query={newLogQuery} />
      <Card.Header>
        <Accordion.Toggle as="div" eventKey={resource.ResourceGroup} onClick={handleToggle} className='pl30'>
            <span>
              <Toggle className={toggle ? 'toggle-down' : 'toggle-up'}/>
            </span> &nbsp;
            <AutomationButton openModal={openAutoModal} automated={resource.Automated}/>
            &nbsp;Resource Group: <span style={{color: '#26C1DB'}}>{resource.ResourceGroup} </span>
            <span style={{float: 'right'}} >
              <LogButton  onClick={openLogModal} >LOGS</LogButton>
            </span>
        </Accordion.Toggle >
      </Card.Header>
      <Accordion.Collapse eventKey={resource.ResourceGroup} >
          <Card.Body className='zero-mp justify-center' >
             {!resources.length  && <div className="ic-Spin-cycle--classic pad-p5"><SvgLoader/></div>}
            {!!resources.length && <ResourceTable resources={resources} updateResources={updateResources} query={newQuery} logQuery={newLogQuery} resourceToggle={childrenToggle} /> }
          </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}


const Subscription = ({resource, query, logQuery, updateSubscriptions, childrenToggle, setChildrenToggle}) => {

  const [showAutoModal, setShowAutoModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [resources, setResources] = useState([]);
  const [toggle, setToggle] = useState(false);
  const logout = useLogout();
  const newQuery = query + '/' + resource.SubscriptionId

  const loadResources = () => {
    getResources(newQuery)
      .then(data => {
        setResources(data)
      })
      .catch(err => {
        console.error(err);
        if (err.status === 401) {
          logout();
        }
      })
  }

  const updateResources = () => {
    loadResources()
    updateSubscriptions()
  }


  useEffect(()=> {
    if (resources.length) {
      loadResources()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[childrenToggle])

  const handleToggle = () => {
    setToggle(!toggle)
    loadResources()
  }

  const updateChildren = () => {
    setChildrenToggle(!childrenToggle)
  }

  const openAutoModal = (e) => {
    e.stopPropagation();
    setShowAutoModal(true)
  };

    const openLogModal = (e) => {
    e.stopPropagation();
    setShowLogModal(true)
  };

  const closeAutoModal = () => {setShowAutoModal(false)};

  const closeLogModal = () => {setShowLogModal(false)};

  return (
    <Card className='resource-accordion'>
      <AutomationModal resource={resource} show={showAutoModal} closeModal={closeAutoModal} query={newQuery} updateResources={updateResources} updateChildren={updateChildren}/>
      <LogModal resource={resource} show={showLogModal} closeModal={closeLogModal} query={logQuery} />
      <Card.Header>
        <Accordion.Toggle as="div" eventKey={resource.SubscriptionId} onClick={handleToggle} className='pl15'>
            <span><Toggle className={toggle ? 'toggle-down' : 'toggle-up'}/></span> &nbsp;<AutomationButton openModal={openAutoModal} automated={resource.Automated}/> &nbsp;Subscription: <span style={{color: '#26C1DB'}}>{resource.SubscriptionName} ({resource.SubscriptionId})</span>
            <span style={{float: 'right'}} >
              <LogButton  onClick={openLogModal} >LOGS</LogButton>
            </span>

        </Accordion.Toggle >
      </Card.Header>
      <Accordion.Collapse eventKey={resource.SubscriptionId}>
          <Card.Body className='zero-mp '>
            {!!resources.length && (
              <ResGroupAccordion resources={resources} updateResources={updateResources} query={newQuery} logQuery={logQuery} groupToggle={childrenToggle} />
            )}
            {!resources.length && <div className="ic-Spin-cycle--classic pad-p5 justify-center width-100"><SvgLoader/></div>}
          </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}


const Toggle = ({className}) => {
  return (
              <svg className={className} height={16} width={16} viewBox="0 0 24 24">
                <g id="resourceArrow" stroke="#26C1DB" strokeWidth="2" fill="#26C1DB" fillRule="evenodd" >
                  <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/>
                </g>
              </svg>
  );
};

const AutomationButton = function({openModal, automated}) {
  return (
    <StdButton
      onClick={openModal}
      bgColor="rgba(255,255,255,0.15)"
      width="6rem" height="1.8em" fontSize=".8em"
      fontColor={automationColors[automated]}
    >
        { automationState[automated] }
    </StdButton>
  )
}

const LogButton = function(props) {
  return (
    <StdButton
      onClick={props.onClick}
      bgColor="#26C1DB"
      width="6rem" height="1.8em" fontSize=".8em"

    >
      {props.children}
    </StdButton>
  )
}

export { SubscriptionAccordion };