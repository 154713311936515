export const automationState = {
  0: 'Recommend',
  1: 'Automated',
  2: 'Mixed'
}

export const automationColors = {
  0: 'white',
  1: '#5AC891',
  2: 'rgba(255,231,0,.7)'
}